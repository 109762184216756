import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RefreshIcon } from './Icons';
import Layout from './Layout';

/**
 * This is a legacy component that was used for OAuth callbacks.
 * The application now uses a custom OTP-based authentication flow.
 * This component is kept for backward compatibility, but redirects
 * users to the main login page.
 */
const LoginCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Simply redirect to the login page after a brief delay
    const timer = setTimeout(() => {
      navigate('/login');
    }, 1500);
    
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center p-10">
        <div className="text-center">
          <RefreshIcon className="animate-spin text-hyc-gold text-4xl mx-auto mb-4" />
          <h2 className="text-2xl font-mono text-hyc-light mb-2">Redirigiendo...</h2>
          <p className="text-hyc-light/70">Por favor espere mientras lo redirigimos a la página de inicio de sesión.</p>
        </div>
      </div>
    </Layout>
  );
};

export default LoginCallback;