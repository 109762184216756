import React from 'react';
import { Link } from 'react-router-dom';
import Layout from './Layout';
import { 
  MapIcon, 
  SearchIcon, 
  ShieldIcon, 
  InfoCircleIcon, 
  WarningIcon,
  CheckIcon 
} from './Icons';

const HomePage = () => {
  // Types of complaints that can be reported with descriptions for tooltips
  const denunciaTypes = [
    { 
      type: 'Corrupción', 
      icon: <WarningIcon className="text-hyc-accent text-2xl" />,
      description: 'Uso del cargo para beneficio propio. Incluye coimas, favores o desvío de recursos.'
    },
    { 
      type: 'Abuso de Autoridad', 
      icon: <ShieldIcon className="text-hyc-accent text-2xl" />,
      description: 'Cuando un policía se pasa de la raya. Detenciones injustas, amenazas o uso indebido del poder.'
    },
    { 
      type: 'Extorsión', 
      icon: <WarningIcon className="text-hyc-accent text-2xl" />,
      description: 'Pedir dinero o favores bajo presión o amenaza. También incluye chantajes.'
    },
    { 
      type: 'Negligencia', 
      icon: <InfoCircleIcon className="text-hyc-accent text-2xl" />,
      description: 'No hacer su trabajo cuando debe. Ignorar denuncias, no actuar ante emergencias, etc.'
    },
    { 
      type: 'Discriminación', 
      icon: <WarningIcon className="text-hyc-accent text-2xl" />,
      description: 'Trato injusto por raza, género, origen, orientación o condición social.'
    },
    { 
      type: 'Violencia/Acoso', 
      icon: <WarningIcon className="text-hyc-accent text-2xl" />,
      description: 'Golpes, insultos, tocamientos, acoso verbal o físico, amenazas.'
    }
  ];

  // Benefits of using the platform
  const benefits = [
    { title: 'Denuncias anónimas', description: 'Tu identidad siempre está protegida' },
    { title: 'Visualización en mapa', description: 'Encuentra denuncias cerca de tu ubicación' },
    { title: 'Almacenamiento seguro', description: 'Toda la información se guarda de forma segura' },
    { title: 'Presión social', description: 'Las denuncias públicas generan conciencia y cambio' }
  ];

  return (
    <Layout hideHeader={false}>
      {/* Hero Section */}
      <section className="bg-gradient-to-br from-hyc-dark to-hyc-secondary py-20 px-4 text-center">
        <div className="max-w-4xl mx-auto">
          <h1 className="font-mono text-3xl sm:text-4xl md:text-5xl lg:text-6xl mb-6 leading-tight px-3 sm:px-8 md:px-4 overflow-visible text-center">
            <span className="text-hyc-gold inline-block mr-[2px] sm:mr-2">Honor</span>
            <span className="text-hyc-light inline-block mr-[2px] sm:mr-2">y</span>
            <span className="text-hyc-accent inline-block mr-[2px] sm:mr-2">Coima</span>
            <span className="text-hyc-light inline-block">.org</span>
          </h1>
          <h2 className="text-hyc-light text-xl md:text-2xl mb-10">
            Plataforma ciudadana para denunciar actos de corrupción <br/> y alzar la voz contra la impunidad
          </h2>
          <div className="flex flex-col sm:flex-row justify-center gap-4 mt-8">
            <Link to="/explorar" className="bg-hyc-gold hover:bg-hyc-gold/80 text-hyc-dark font-bold py-3 px-6 rounded-lg text-center transition-colors">
              Explorar Denuncias
            </Link>
            <Link to="/denunciaform" className="bg-hyc-accent hover:bg-hyc-accent/80 text-white font-bold py-3 px-6 rounded-lg text-center transition-colors">
              Enviar Denuncia
            </Link>
          </div>
                  </div>
      </section>

      {/* About Section */}
      <section className="py-16 px-4">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-hyc-gold text-3xl font-bold mb-6 text-center">Nuestro Objetivo</h2>
          <div className="bg-hyc-secondary p-6 rounded-lg mb-10 shadow-lg">
            <p className="text-hyc-light text-lg leading-relaxed">
              <span className="font-mono text-xl">
                <span className="text-hyc-gold">Honor</span>
                <span className="text-hyc-light">y</span>
                <span className="text-hyc-accent">Coima</span>
                <span className="text-hyc-light">.org</span>
              </span> es un espacio donde todos podremos <span className="underline">denunciar</span> de manera anónima los malos actos de la Policía Nacional del Perú (PNP). 
              <br/><br/>
              <strong>Sabemos que tú también estás harto de la situación de impunidad en que vivimos.</strong>
            </p>
            <div className="mt-6">
              <p className="text-hyc-light text-lg leading-relaxed">
                Crear conciencia ciudadana, centralizando y difundiendo denuncias sobre la PNP y exigir una reforma de la institución.
              </p>
            </div>
          </div>
          
          <h3 className="text-hyc-accent text-2xl font-bold mb-6 text-center">¿Qué podrás denunciar?</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mb-10">
            {denunciaTypes.map((item, index) => (
              <div key={index} className="bg-hyc-secondary p-6 rounded-lg flex items-center space-x-4 shadow-md hover:shadow-lg transition-shadow group relative">
                {item.icon}
                <span className="text-hyc-light text-lg">{item.type}</span>
                
                {/* Tooltip */}
                <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity duration-200 bottom-full left-0 right-0 mb-2 p-3 bg-hyc-dark border border-hyc-light/20 rounded shadow-lg z-10 text-hyc-light text-xs sm:text-sm w-full">
                  {item.description}
                  <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 border-l-8 border-r-8 border-l-transparent border-r-transparent border-t-8 border-t-hyc-dark"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* How it Works Section */}
      <section className="py-16 px-4 bg-hyc-secondary">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-hyc-gold text-3xl font-bold mb-12 text-center">¿Por qué denunciar en HonoryCoima.org?</h2>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
            {benefits.map((benefit, index) => (
              <div key={index} className="flex space-x-4">
                <div className="flex-shrink-0">
                  <CheckIcon className="text-hyc-gold text-2xl" />
                </div>
                <div>
                  <h3 className="text-hyc-gold text-xl font-bold mb-2">{benefit.title}</h3>
                  <p className="text-hyc-light">{benefit.description}</p>
                </div>
              </div>
            ))}
          </div>
          
          <div className="mt-16 text-center">
            <p className="text-hyc-light text-lg leading-relaxed mb-8">
              Los miembros de la PNP son <span className="underline">servidores públicos</span>. Las entidades que fiscalizan denuncias no están haciendo su trabajo, ya que pertenecen a la misma institución. Muchas veces, malos policías retornan a trabajar impunemente.
              <br/><br/>
              <strong>Queremos darte una plataforma sencilla y segura para enviar tus denuncias, compartirlas y que <span className="underline">nunca sean olvidadas</span>.</strong>
            </p>
            
            <div className="flex flex-col sm:flex-row justify-center gap-4 mt-8">
              <Link to="/cercademi" className="bg-hyc-secondary border border-hyc-light text-hyc-light hover:bg-hyc-light/10 font-bold py-3 px-6 rounded-lg text-center transition-colors flex items-center justify-center">
                <MapIcon className="mr-2" />
                <span>Ver Denuncias Cercanas</span>
              </Link>
              <Link to="/explorar" className="bg-hyc-secondary border border-hyc-light text-hyc-light hover:bg-hyc-light/10 font-bold py-3 px-6 rounded-lg text-center transition-colors flex items-center justify-center">
                <SearchIcon className="mr-2" />
                <span>Buscar Denuncias</span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 px-4 bg-gradient-to-br from-hyc-accent/20 to-hyc-dark">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-hyc-gold text-3xl font-bold mb-6">Alza tu voz ahora</h2>
          <p className="text-hyc-light text-lg mb-10">
            Tu denuncia puede marcar la diferencia. Juntos podemos crear un sistema más justo y transparente.
          </p>
          <Link to="/denunciaform" className="bg-hyc-accent hover:bg-hyc-accent/80 text-white font-bold py-4 px-8 rounded-lg text-xl transition-colors inline-block">
            Enviar una Denuncia
          </Link>
          <p className="mt-8 text-hyc-light/70">
            ¿Preguntas? Escríbenos a <a href="mailto:hola@honorycoima.org" className="text-hyc-accent hover:text-hyc-gold transition-colors">hola@honorycoima.org</a>
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default HomePage;