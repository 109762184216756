import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';

// Fix Leaflet's icon path issues
// This is necessary because Leaflet expects images in a different path
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34]
});

L.Marker.prototype.options.icon = DefaultIcon;

// Generate a unique ID for each map instance
const generateUniqueId = () => `map-${Math.random().toString(36).substr(2, 9)}`;

// Simple map component using direct Leaflet API (not react-leaflet)
const SimpleMap = ({ position, denuncias = [], onMarkerClick, zoomToMarker = false }) => {
  const mapRef = useRef(null);
  const markersRef = useRef({});
  const containerRef = useRef(null);
  const mapId = useRef(generateUniqueId());
  const initialPositionRef = useRef(position); // Store initial position
  const [isInitialized, setIsInitialized] = useState(false);
  
  // Initialize the map (creates the Leaflet instance)
  const initializeMap = () => {
    if (mapRef.current || !containerRef.current) return;
    
    console.log("Initializing Leaflet map instance");
    
    try {
      // Clean up previous elements
      while (containerRef.current.firstChild) {
        containerRef.current.removeChild(containerRef.current.firstChild);
      }
      
      // Set container dimensions
      containerRef.current.style.width = '100%';
      containerRef.current.style.height = '100%';
      
      // Determine initial zoom level
      const isPeru = position.lat === -9.189967 && position.lng === -75.015152;
      const isSingleDenuncia = denuncias.length === 1;
      const zoomLevel = isPeru ? 6 : (isSingleDenuncia ? 15 : 13);
      
      // Store initial position for reference
      initialPositionRef.current = position;
      
      // Create the map directly on the container element
      const map = L.map(containerRef.current, {
        center: [position.lat, position.lng],
        zoom: zoomLevel,
        zoomControl: true,
        scrollWheelZoom: true,
        doubleClickZoom: true,
        touchZoom: true,
        dragging: true,
        keyboard: true,
        tap: true
      });
      
      // Add standard tile layer
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        maxZoom: 19
      }).addTo(map);
      
      // Store map reference
      mapRef.current = map;
      
      // Force resize after small delay to ensure proper rendering
      setTimeout(() => {
        if (mapRef.current) {
          mapRef.current.invalidateSize();
          
          // Add a flag to the map instance to track if it's been positioned initially
          // This will prevent repositioning on subsequent marker updates
          mapRef.current._initialPositionComplete = false;
        }
      }, 100);
      
      // Track last sent bounds to avoid duplicate events
      const lastBoundsRef = {
        north: 0, south: 0, east: 0, west: 0
      };
      
      // Add move end handler with debounce
      let moveEndTimeout = null;
      let userInitiatedMove = false;
      
      // Track user interaction
      map.on('dragstart', function() {
        userInitiatedMove = true;
      });
      
      map.on('zoomstart', function() {
        userInitiatedMove = true;
      });
      
      map.on('moveend', function() {
        if (moveEndTimeout) clearTimeout(moveEndTimeout);
        
        moveEndTimeout = setTimeout(() => {
          if (!mapRef.current) return;
          
          // Only trigger bounds changed event if this was a user interaction
          // or significant bounds change
          const bounds = map.getBounds();
          const newBounds = {
            north: bounds.getNorth(),
            south: bounds.getSouth(),
            east: bounds.getEast(),
            west: bounds.getWest()
          };
          
          // Check if bounds changed significantly or user initiated the move
          const boundsChanged = 
            Math.abs(newBounds.north - lastBoundsRef.north) > 0.001 ||
            Math.abs(newBounds.south - lastBoundsRef.south) > 0.001 ||
            Math.abs(newBounds.east - lastBoundsRef.east) > 0.001 ||
            Math.abs(newBounds.west - lastBoundsRef.west) > 0.001;
          
          if (userInitiatedMove || boundsChanged) {
            console.log("User moved map, zoom:", map.getZoom());
            
            // Update last bounds
            lastBoundsRef.north = newBounds.north;
            lastBoundsRef.south = newBounds.south;
            lastBoundsRef.east = newBounds.east;
            lastBoundsRef.west = newBounds.west;
            
            // Mark map as having completed its initial positioning
            // This prevents future addMarkers calls from modifying the position
            mapRef.current._initialPositionComplete = true;
            
            // Dispatch event
            const event = new CustomEvent('map-view-changed', {
              detail: { bounds: newBounds }
            });
            document.dispatchEvent(event);
            
            // Reset user interaction flag
            userInitiatedMove = false;
          }
        }, 300);
      });
      
      // Add zoom handler
      map.on('zoomend', function() {
        console.log("Map zoomed to:", map.getZoom());
      });
      
      // Add event handlers for user interaction
      containerRef.current.addEventListener('highlight-marker', handleHighlightMarker);
      
      // Force a resize to ensure the map fills its container
      setTimeout(() => {
        if (mapRef.current) {
          mapRef.current.invalidateSize();
        }
      }, 300);
      
      setIsInitialized(true);
      console.log("Map initialized successfully");
    } catch (error) {
      console.error("Error initializing map:", error);
    }
  };
  
  // Handle marker highlight from table
  const handleHighlightMarker = (event) => {
    if (!mapRef.current) return;
    
    try {
      const id = event.detail?.id;
      const marker = markersRef.current[id];
      
      if (marker) {
        // Open the popup
        marker.openPopup();
        
        // Pan to marker
        mapRef.current.setView(marker.getLatLng(), 15, {
          animate: true,
          duration: 0.5
        });
        
        // Highlight this marker and dim others
        Object.values(markersRef.current).forEach(m => {
          m.setOpacity(m === marker ? 1.0 : 0.6);
        });
      }
    } catch (err) {
      console.error("Error highlighting marker:", err);
    }
  };
  
  // Initial map positioning - only called once during setup
  const positionMapInitially = () => {
    if (!mapRef.current) return;
    
    // Skip if map has already been positioned by user interaction
    if (mapRef.current._initialPositionComplete === true) {
      console.log("Skipping initial positioning as map has already been positioned by user");
      return;
    }
    
    try {
      console.log("Performing initial map positioning");
      
      // Only do initial positioning
      const initialPosition = initialPositionRef.current;
      const isPeru = initialPosition.lat === -9.189967 && initialPosition.lng === -75.015152;
      const isSingleDenuncia = denuncias.length === 1;
      
      if (denuncias.length === 1 && denuncias[0].ubicacion) {
        // Detail view - zoom to single marker
        const denuncia = denuncias[0];
        mapRef.current.setView(
          [denuncia.ubicacion.lat, denuncia.ubicacion.lng], 
          15, 
          { animate: false }
        );
      } else if (denuncias.length > 1 && !isPeru) {
        // List view with user location - fit bounds to show all markers
        try {
          const points = denuncias
            .filter(d => d.ubicacion && d.ubicacion.lat && d.ubicacion.lng)
            .map(d => [d.ubicacion.lat, d.ubicacion.lng]);
          
          if (points.length > 0) {
            const bounds = L.latLngBounds(points);
            mapRef.current.fitBounds(bounds, { 
              padding: [50, 50],
              maxZoom: 12
            });
          }
        } catch (error) {
          console.error("Error fitting bounds:", error);
        }
      }
      // For Peru view, we already centered during map initialization
      
      // Mark that initial positioning is complete
      mapRef.current._initialPositionComplete = true;
      
    } catch (err) {
      console.error("Error positioning map initially:", err);
    }
  };
  
  // Add/update markers without changing map view
  const addMarkers = () => {
    if (!mapRef.current) return;
    
    try {
      // Flag to track if this is first time adding markers
      const isFirstRun = Object.keys(markersRef.current).length === 0;
      
      // Clear existing markers but preserve user marker
      const userMarker = markersRef.current['user-location'];
      
      // Remove only denuncia markers
      Object.entries(markersRef.current).forEach(([id, marker]) => {
        if (id !== 'user-location') {
          mapRef.current.removeLayer(marker);
          delete markersRef.current[id];
        }
      });
      
      // Use current position for user location marker
      const currentPosition = position;
      
      // Only handle user marker if not on detail view
      const isSingleDenuncia = denuncias.length === 1;
      
      if (!isSingleDenuncia) {
        // User location marker
        if (userMarker) {
          // Just update position if marker exists
          if (currentPosition && currentPosition.lat && currentPosition.lng) {
            userMarker.setLatLng([currentPosition.lat, currentPosition.lng]);
          }
        } else {
          // Create new marker if doesn't exist and we have a position
          if (currentPosition && currentPosition.lat && currentPosition.lng && 
              !(currentPosition.lat === -9.189967 && currentPosition.lng === -75.015152)) {
            
            const userLocationIcon = L.icon({
              iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png',
              shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
              iconSize: [30, 45],
              iconAnchor: [15, 45],
              popupAnchor: [1, -34],
              shadowSize: [41, 41]
            });
            
            const newUserMarker = L.marker([currentPosition.lat, currentPosition.lng], { 
              icon: userLocationIcon,
              zIndexOffset: 1000 // Always on top
            })
              .addTo(mapRef.current)
              .bindPopup('<div style="text-align: center;"><strong>Tu ubicación</strong></div>');
              
            // Store user marker with special key
            markersRef.current['user-location'] = newUserMarker;
          }
        }
      }
      
      // Add denuncia markers
      const denunciaIcon = L.icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
      
      denuncias.forEach(denuncia => {
        if (!denuncia.ubicacion || !denuncia.ubicacion.lat || !denuncia.ubicacion.lng) return;
        
        const marker = L.marker([denuncia.ubicacion.lat, denuncia.ubicacion.lng], { 
          icon: denunciaIcon
        });
        
        // Add popup for list view mode
        if (denuncias.length > 1) {
          marker.bindPopup(`
            <div style="text-align: center;">
              <h3 style="margin: 5px 0; font-weight: 500;">${denuncia.tipo || 'Sin categoría'}</h3>
              <p style="margin: 5px 0; font-size: 0.8em;">
                ${denuncia.location_detalle || denuncia.distrito_ocurrencia || 'Ubicación no específica'}
              </p>
              <p style="margin: 5px 0; font-size: 0.8em; max-width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                ${denuncia.descripcion ? denuncia.descripcion.substring(0, 60) + '...' : 'Sin descripción'}
              </p>
              <button 
                onclick="window.location.href='/denuncia/${denuncia._id}'"
                style="background: #DC5A0B; color: white; border: none; padding: 5px 10px; border-radius: 4px; margin-top: 5px; cursor: pointer; font-size: 0.8em;"
              >
                Ver detalles
              </button>
            </div>
          `);
          
          // Add click handler for list items
          marker.on('click', () => {
            // Dispatch custom event to highlight table row
            const event = new CustomEvent('marker-clicked', { 
              detail: { id: denuncia._id }
            });
            document.dispatchEvent(event);
            
            // Highlight this marker
            Object.values(markersRef.current).forEach(m => {
              m.setOpacity(m === marker ? 1.0 : 0.6);
            });
          });
        }
        
        // Add to map and store reference
        marker.addTo(mapRef.current);
        markersRef.current[denuncia._id] = marker;
      });
      
      // Only set view on first run
      if (isFirstRun) {
        positionMapInitially();
      }
    } catch (err) {
      console.error("Error updating markers:", err);
    }
  };

  // Initialize map after component mounts (use position for initial setup only)
  useEffect(() => {
    // Only initialize if not already done
    if (!isInitialized) {
      const timer = setTimeout(() => {
        initializeMap();
      }, 100);
      
      return () => clearTimeout(timer);
    }
  // Include position in dependency array for initial position only
  }, [position, isInitialized]);

  // Update markers when denuncias change (but not when position changes)
  // This prevents map from resetting when position changes
  useEffect(() => {
    if (isInitialized) {
      addMarkers();
    }
  }, [isInitialized, denuncias]);
  
  // Handle position changes separately to avoid map resets
  useEffect(() => {
    if (isInitialized && mapRef.current) {
      const userMarker = markersRef.current['user-location'];
      
      // Only update the user marker position if it exists
      if (userMarker && position && position.lat && position.lng) {
        // Update the marker position without resetting the map view
        userMarker.setLatLng([position.lat, position.lng]);
        console.log("Updated user marker position without resetting map view");
      }
    }
  }, [position, isInitialized]);

  // Handle map resize when container size changes
  useEffect(() => {
    if (!mapRef.current) return;
    
    const resizeMap = () => {
      mapRef.current.invalidateSize();
    };
    
    // Handle window resize event
    window.addEventListener('resize', resizeMap);
    
    // Force map resize after a delay
    const timer = setTimeout(resizeMap, 300);
    
    return () => {
      window.removeEventListener('resize', resizeMap);
      clearTimeout(timer);
    };
  }, [isInitialized]);

  // Listen for center-on-user events
  useEffect(() => {
    if (!mapRef.current) return;
    
    const handleCenterOnUser = (event) => {
      if (!event.detail || !event.detail.center) return;
      
      try {
        const { center, preserveZoom = false, zoomLevel = 15 } = event.detail;
        
        if (preserveZoom) {
          mapRef.current.panTo([center.lat, center.lng], {
            animate: true,
            duration: 0.5
          });
        } else {
          mapRef.current.setView([center.lat, center.lng], zoomLevel, {
            animate: true,
            duration: 0.5
          });
        }
        
        console.log("Centered map on user location:", center);
      } catch (err) {
        console.error("Error centering on user:", err);
      }
    };
    
    // Add event listener
    document.addEventListener('center-on-user', handleCenterOnUser);
    
    // Clean up
    return () => document.removeEventListener('center-on-user', handleCenterOnUser);
  }, [isInitialized]);

  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('highlight-marker', handleHighlightMarker);
      }
      
      if (mapRef.current) {
        console.log("Removing map instance");
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  return (
    <div 
      ref={containerRef}
      className="w-full h-full bg-hyc-secondary rounded-lg overflow-hidden"
      style={{ height: '100%' }}
    ></div>
  );
};

export default SimpleMap;