import React from 'react';

/**
 * Reusable form textarea component with consistent styling
 * @param {Object} props - Component props
 * @param {string} props.name - Textarea name
 * @param {string} props.label - Textarea label
 * @param {string} [props.placeholder=''] - Textarea placeholder
 * @param {string} [props.value=''] - Textarea value
 * @param {Function} [props.onChange=() => {}] - Change handler
 * @param {Function} [props.onBlur=() => {}] - Blur handler
 * @param {string} [props.error=''] - Error message
 * @param {boolean} [props.required=false] - Whether the textarea is required
 * @param {string} [props.className=''] - Additional class names
 * @param {number} [props.rows=4] - Number of rows
 */
const FormTextarea = ({
  name,
  label,
  placeholder = '',
  value = '',
  onChange = () => {},
  onBlur = () => {},
  error = '',
  required = false,
  className = '',
  rows = 4,
  ...rest
}) => {
  // Base styles for all textareas
  const baseTextareaStyles = 
    "w-full p-2 border rounded bg-hyc-dark text-hyc-light border-gray-500 " +
    "focus:border-hyc-highlight focus:outline-none focus:ring-1 focus:ring-hyc-highlight";
  
  // Apply error styles if there's an error
  const textareaStyles = error 
    ? `${baseTextareaStyles} border-red-500` 
    : baseTextareaStyles;

  return (
    <div className={`mb-4 ${className}`}>
      {label && (
        <label htmlFor={name} className="block mb-2 text-hyc-light">
          {label}
          {required && <span className="text-hyc-accent ml-1">*</span>}
        </label>
      )}
      
      <textarea
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className={textareaStyles}
        rows={rows}
        {...rest}
      />
      
      {error && (
        <p className="mt-1 text-hyc-accent text-sm">{error}</p>
      )}
    </div>
  );
};

export default FormTextarea;