import React, { useState } from 'react';
import './Lightbox.css';

// Icons
import { ChevronLeftIcon, ChevronRightIcon, XIcon } from './Icons';

const Lightbox = ({ media = [], startIndex = 0, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(startIndex);
  
  if (!media || media.length === 0) {
    return null;
  }
  
  const currentItem = media[currentIndex];
  
  const goToPrevious = (e) => {
    e.stopPropagation();
    setCurrentIndex((prev) => (prev === 0 ? media.length - 1 : prev - 1));
  };
  
  const goToNext = (e) => {
    e.stopPropagation();
    setCurrentIndex((prev) => (prev === media.length - 1 ? 0 : prev + 1));
  };
  
  // Render current media item
  const renderMedia = () => {
    if (!currentItem) return null;
    
    if (currentItem.type === 'image') {
      return (
        <div className="lightbox-image-container">
          <img 
            src={currentItem.url} 
            alt={currentItem.title || 'Media item'} 
            className="lightbox-image"
          />
        </div>
      );
    } else if (currentItem.type === 'video') {
      // Handle YouTube embed URLs differently
      if (currentItem.url.includes('youtube.com/embed')) {
        return (
          <div className="lightbox-video-container">
            <iframe
              src={currentItem.url}
              title={currentItem.title || 'Video'}
              className="lightbox-video"
              allowFullScreen
            ></iframe>
          </div>
        );
      } else {
        return (
          <div className="lightbox-video-container">
            <video 
              src={currentItem.url} 
              className="lightbox-video" 
              controls
              autoPlay
            ></video>
          </div>
        );
      }
    } else if (currentItem.type === 'audio') {
      return (
        <div className="lightbox-audio-container">
          <div className="lightbox-audio-title">{currentItem.title || 'Audio'}</div>
          <audio 
            src={currentItem.url} 
            className="lightbox-audio" 
            controls
            autoPlay
          ></audio>
        </div>
      );
    }
    
    return <div>Contenido no soportado</div>;
  };
  
  return (
    <div className="lightbox-overlay" onClick={onClose}>
      <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
        <button className="lightbox-close" onClick={onClose}>
          <XIcon />
        </button>
        
        {/* Media item title */}
        {currentItem.title && (
          <div className="lightbox-title">{currentItem.title}</div>
        )}
        
        {/* Media content */}
        <div className="lightbox-media-container">
          {renderMedia()}
        </div>
        
        {/* Navigation controls */}
        {media.length > 1 && (
          <div className="lightbox-controls">
            <button className="lightbox-nav-button prev" onClick={goToPrevious}>
              <ChevronLeftIcon />
            </button>
            <div className="lightbox-counter">
              {currentIndex + 1} / {media.length}
            </div>
            <button className="lightbox-nav-button next" onClick={goToNext}>
              <ChevronRightIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Lightbox;
