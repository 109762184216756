import React, { useState, useEffect } from 'react';
import Button from './Button';
import Card from './Card';

const AuthStatus = () => {
  const [authStatus, setAuthStatus] = useState({});
  
  const checkAuth = () => {
    const authItems = [
      'authToken',
      'authTokens',
      'cognitoTokens',
      'user',
      'authUsername',
      'pendingVerification',
      'pendingRegistration'
    ];
    
    const status = {};
    
    // Collect all auth-related items
    authItems.forEach(key => {
      const value = localStorage.getItem(key);
      status[key] = value ? (key === 'user' || key.includes('Tokens') ? 'Present (object)' : value) : 'Not found';
      
      // For objects, try to parse and extract info
      if (value && (key === 'user' || key.includes('Tokens'))) {
        try {
          const parsed = JSON.parse(value);
          if (key === 'authTokens' || key === 'cognitoTokens') {
            // Check expiration
            if (parsed.expiresAt) {
              const now = Date.now();
              const expiresAt = parsed.expiresAt;
              const isValid = now < expiresAt;
              const timeLeft = Math.round((expiresAt - now) / 1000 / 60); // minutes
              
              status[`${key}Valid`] = isValid ? `Yes (${timeLeft} minutes left)` : 'No (expired)';
            } else {
              status[`${key}Valid`] = 'Unknown (no expiration)';
            }
          }
        } catch (e) {
          status[`${key}Error`] = e.message;
        }
      }
    });
    
    // Check if events are working
    status.eventsWorking = 'Testing...';
    
    setAuthStatus(status);
    
    // Dispatch an event to test if it's working
    window.dispatchEvent(new Event('auth-status-changed'));
    
    // After a short delay, update the events status
    setTimeout(() => {
      setAuthStatus(prev => ({
        ...prev,
        eventsWorking: 'Yes (if this changed)'
      }));
    }, 500);
  };
  
  useEffect(() => {
    checkAuth();
  }, []);
  
  const clearAuth = () => {
    const authKeys = [
      'authToken', 
      'authTokens',
      'cognitoTokens', 
      'user', 
      'contactMethod',
      'pendingVerification',
      'registrationUserId',
      'userEmail',
      'userTelefono',
      'pendingRegistration', 
      'authUsername'
    ];
    
    // Remove all auth-related keys
    authKeys.forEach(key => localStorage.removeItem(key));
    
    // Dispatch event
    window.dispatchEvent(new Event('auth-status-changed'));
    
    // Update status
    checkAuth();
  };
  
  const testDispatchEvent = () => {
    window.dispatchEvent(new Event('auth-status-changed'));
    
    // Update UI after a short delay
    setTimeout(checkAuth, 500);
  };
  
  return (
    <div className="max-w-3xl mx-auto p-6">
      <Card className="border border-hyc-light/10 rounded-xl shadow-xl overflow-hidden">
        <div className="bg-hyc-secondary p-4 border-b border-hyc-light/10">
          <h2 className="text-xl font-mono text-hyc-light">Authentication Diagnostics</h2>
        </div>
        
        <div className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div>
              <h3 className="text-hyc-gold font-mono mb-2">LocalStorage Auth Keys</h3>
              <div className="bg-hyc-dark rounded-md p-4 overflow-auto max-h-80">
                {Object.entries(authStatus).map(([key, value]) => (
                  <div key={key} className="mb-2 last:mb-0">
                    <span className="font-mono text-hyc-gold">{key}: </span>
                    <span className="font-mono text-hyc-light">{value}</span>
                  </div>
                ))}
              </div>
            </div>
            
            <div>
              <h3 className="text-hyc-gold font-mono mb-2">Actions</h3>
              <div className="space-y-4">
                <Button onClick={checkAuth} fullWidth>
                  Refresh Status
                </Button>
                <Button onClick={testDispatchEvent} fullWidth variant="outline">
                  Test Event Dispatch
                </Button>
                <Button onClick={clearAuth} fullWidth variant="danger">
                  Clear All Auth Data
                </Button>
              </div>
            </div>
          </div>
          
          <div className="mt-4 p-4 bg-hyc-dark/50 border border-hyc-light/10 rounded-lg">
            <h3 className="text-hyc-gold font-mono mb-2">Debug Notes</h3>
            <ul className="list-disc pl-5 text-hyc-light space-y-2">
              <li>The application uses localStorage to store authentication state</li>
              <li>Authentication changes are broadcast using the 'auth-status-changed' custom event</li>
              <li>Tokens may have expiration times that should be checked</li>
              <li>Multiple storage keys are used for backward compatibility</li>
            </ul>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AuthStatus;