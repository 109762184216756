import React, { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate, Link } from 'react-router-dom';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

// Import components directly to avoid circular dependencies
import Button from './Button';
import Card from './Card';
import FormTextarea from './FormTextarea';
import UbigeoDropdowns from './UbigeoDropdowns';
import DenunciadoSelect from './DenunciadoSelect';
import MapModal from './MapModal';
import Subtitle from './Subtitle';
import Layout from './Layout';
import config from '../config';

// Import icons
import { 
  CalendarIcon, 
  TagIcon, 
  LinkIcon, 
  TextIcon, 
  AddIcon, 
  DeleteIcon, 
  MapIcon,
  WarningIcon,
  CheckIcon,
  InfoCircleIcon,
  QuestionCircleIcon,
  FileIcon,
  ImageIcon,
  VideoIcon,
  AudioIcon,
  FileUploadIcon,
  AttachmentIcon
} from './Icons';

// Import Leaflet CSS
import 'leaflet/dist/leaflet.css';

// Updated validation schema with file attachments
const validationSchema = Yup.object({
  tipo: Yup.object().required('Tipo es requerido'),
  fecha_ocurrencia: Yup.date().nullable(),
  departamento_ocurrencia: Yup.string(),
  provincia_ocurrencia: Yup.string(),
  distrito_ocurrencia: Yup.string(),
  denunciados: Yup.array()
    .min(1, 'Se requiere al menos un denunciado')
    .max(30, 'Se permite un máximo de 30 denunciados')
    .required('Se requiere al menos un denunciado'),
  descripcion: Yup.string().required('Descripción es requerida'),
  url_evidencia: Yup.array().of(
    Yup.string().nullable().transform(value => value === '' ? null : value)
      .url('Debe ser una URL válida')
  ),
  archivos: Yup.array().of(
    Yup.mixed().test(
      'fileSize',
      'El archivo es demasiado grande. Máximo 10MB permitido.',
      (value) => !value || value.size <= 10 * 1024 * 1024
    ).test(
      'fileType',
      'Solo se permiten imágenes, videos y audios.',
      (value) => !value || ['image/', 'video/', 'audio/'].some(type => value.type.startsWith(type))
    )
  ),
});

// Tooltip component for form fields
const Tooltip = ({ text }) => {
  const [isVisible, setIsVisible] = useState(false);
  
  return (
    <div className="relative inline-block ml-2">
      <QuestionCircleIcon 
        className="text-hyc-light/60 hover:text-hyc-gold cursor-help transition-colors" 
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        onClick={() => setIsVisible(!isVisible)}
      />
      {isVisible && (
        <div className="absolute z-10 p-2 mt-1 text-sm bg-hyc-dark border border-hyc-light/20 rounded shadow-lg left-0 w-64 text-hyc-light">
          {text}
        </div>
      )}
    </div>
  );
};

const tipoOptions = [
  { 
    value: 'Corrupción', 
    label: 'Corrupción',
    description: 'Uso del cargo para beneficio propio. Incluye coimas, favores o desvío de recursos.'
  },
  { 
    value: 'Abuso de Autoridad', 
    label: 'Abuso de autoridad',
    description: 'Cuando un policía se pasa de la raya. Detenciones injustas, amenazas o uso indebido del poder.'
  },
  { 
    value: 'Extorsión', 
    label: 'Extorsión',
    description: 'Pedir dinero o favores bajo presión o amenaza. También incluye chantajes.'
  },
  { 
    value: 'Negligencia', 
    label: 'Negligencia',
    description: 'No hacer su trabajo cuando debe. Ignorar denuncias, no actuar ante emergencias, etc.'
  },
  { 
    value: 'Discriminación', 
    label: 'Discriminación',
    description: 'Trato injusto por raza, género, origen, orientación o condición social.'
  },
  { 
    value: 'Violencia/Acoso', 
    label: 'Violencia/Acoso',
    description: 'Golpes, insultos, tocamientos, acoso verbal o físico, amenazas.'
  },
];


const DenunciaForm = () => {
  const navigate = useNavigate(); // Add missing navigate hook
  const [links, setLinks] = useState(['']);
  const [newDenunciados, setNewDenunciados] = useState([]);
  const [newDenunciado, setNewDenunciado] = useState(''); // Add missing state variable
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationCenter, setLocationCenter] = useState({ lat: -9.189967, lng: -75.015152 }); // Default to the center of Peru
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [submissionError, setSubmissionError] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  // Check authentication status
  useEffect(() => {
    const checkAuth = () => {
      const authToken = localStorage.getItem('authToken');
      const cognitoTokensStr = localStorage.getItem('cognitoTokens');
      let isLoggedIn = false;
      
      if (authToken) {
        isLoggedIn = true;
      } else if (cognitoTokensStr) {
        try {
          const cognitoTokens = JSON.parse(cognitoTokensStr);
          if (cognitoTokens && cognitoTokens.accessToken) {
            isLoggedIn = true;
          }
        } catch (e) {
          // JSON parse error, token invalid
          console.error('Error parsing cognitoTokens:', e);
        }
      }
      
      setIsAuthenticated(isLoggedIn);
    };
    
    // Initial check
    checkAuth();
    
    // Listen for auth changes
    const handleAuthChange = () => {
      checkAuth();
    };
    
    window.addEventListener('auth-status-changed', handleAuthChange);
    
    return () => {
      window.removeEventListener('auth-status-changed', handleAuthChange);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      tipo: null,
      fecha_ocurrencia: null,
      departamento_ocurrencia: '',
      provincia_ocurrencia: '',
      distrito_ocurrencia: '',
      location_detalle: '',
      denunciados: [], // Array for multiple denunciados
      descripcion: '',
      url_evidencia: [''],
      archivos: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      setSubmissionError('');

      try {
        
        // Always pre-create denunciado if needed before submitting the form
        if (values.denunciado && 
            ((typeof values.denunciado === 'object' && values.denunciado.value === 'new') || 
             values.denunciado === 'new') && 
            newDenunciado) {
          
          // Setup auth headers
          const authToken = localStorage.getItem('authToken');
          const cognitoTokensStr = localStorage.getItem('cognitoTokens');
          const headers = {
            'Content-Type': 'application/json'
          };
          
          // Add auth token
          if (authToken) {
            headers.Authorization = `Bearer ${authToken}`;
          } else if (cognitoTokensStr) {
            try {
              const cognitoTokens = JSON.parse(cognitoTokensStr);
              if (cognitoTokens.accessToken) {
                headers.Authorization = `Bearer ${cognitoTokens.accessToken}`;
              }
            } catch (e) {
              // Silent failure for production
            }
          }
          
          try {
            // Ensure we have a clean, valid denunciado name
            const denunciadoName = newDenunciado.trim();
            if (!denunciadoName) {
              throw new Error('El nombre del denunciado no puede estar vacío');
            }
            
            // Create denunciado first - be super explicit about the name
            
            const payload = { 
              nombre: denunciadoName
            };
            
            
            
            const denunciadoResponse = await axios.post(
              `${config.apiUrl}/denunciados`,
              payload,
              { headers }
            );
            
            
            
            // Replace the 'new' value with the actual ID
            if (denunciadoResponse.data._id) {
              if (typeof values.denunciado === 'object') {
                values.denunciado.value = denunciadoResponse.data._id;
              } else {
                values.denunciado = denunciadoResponse.data._id;
              }
              
              
              // Add the denunciado name to the form values explicitly as well
              // This ensures it's included even if the backend can't extract it
              values.newDenunciado = denunciadoName;
              
            }
          } catch (denunciadoError) {
            
            setSubmissionError(`Error al crear el denunciado: ${denunciadoError.message}`);
            setIsSubmitting(false);
            return; // Stop form submission if denunciado creation failed
          }
        } else if (values.denunciado && 
                  ((typeof values.denunciado === 'object' && values.denunciado.value === 'new') || 
                  values.denunciado === 'new') && 
                  !newDenunciado) {
          // If denunciado is 'new' but no name provided, show error and stop submission
          setSubmissionError('El nombre del denunciado es requerido');
          setIsSubmitting(false);
          return;
        }
        
        // Get user ID - we'll need multiple approaches because of different auth methods
        let userId = '';
        
        // 1. First check if we have a userID in the form itself
        if (!userId && formik.values.id_usuario) {
          userId = formik.values.id_usuario;
          
        }
        
        // 2. Try to get from the user object (standard location)
        if (!userId) {
          const userJson = localStorage.getItem('user');
          if (userJson) {
            try {
              const user = JSON.parse(userJson);
              
              
              if (user && user._id) {
                userId = user._id;
                
              } else if (user && user.id) {
                userId = user.id;
                
              } else if (user && user.sub) {
                userId = user.sub;
                
              }
            } catch (e) {
              
            }
          }
        }
        
        // 3. Try the direct userId in localStorage
        if (!userId) {
          const directUserId = localStorage.getItem('userId');
          if (directUserId) {
            userId = directUserId;
            
          }
        }
        
        // 4. Try registrationUserId which might contain the ID during registration
        if (!userId) {
          const registrationId = localStorage.getItem('registrationUserId');
          if (registrationId) {
            userId = registrationId;
            
          }
        }
        
        // 5. Try to extract from auth tokens
        if (!userId) {
          const authTokensStr = localStorage.getItem('authTokens');
          if (authTokensStr) {
            try {
              const authTokens = JSON.parse(authTokensStr);
              
              
              if (authTokens && authTokens.accessToken) {
                // Try to decode the JWT token
                const token = authTokens.accessToken;
                const tokenParts = token.split('.');
                if (tokenParts.length === 3) {
                  try {
                    // JWT has 3 parts: header, payload, signature
                    const payloadBase64 = tokenParts[1].replace(/-/g, '+').replace(/_/g, '/');
                    
                    // Decode the base64 payload
                    let decodedString = '';
                    try {
                      // Standard atob method
                      decodedString = atob(payloadBase64);
                    } catch (e) {
                      // Alternative decoding if atob fails
                      
                      decodedString = Buffer.from(payloadBase64, 'base64').toString('binary');
                    }
                    
                    const payload = JSON.parse(decodedString);
                    
                    
                    if (payload.sub) {
                      userId = payload.sub;
                      
                    } else if (payload.user_id) {
                      userId = payload.user_id;
                      
                    } else if (payload._id) {
                      userId = payload._id;
                      
                    }
                  } catch (decodeError) {
                    
                  }
                }
              }
            } catch (e) {
              
            }
          }
        }
        
        // FALLBACK APPROACH - If still no user ID, create a temporary one
        if (!userId) {
          // If this is just a development/test environment, use a placeholder
          // This should be removed in production
          userId = '65f4cd28ebfe6d3c3b52af3e'; // Development test ID
          
        }
                
        // If we still don't have a user ID, we can't proceed
        if (!userId) {
          
          
          // Check if the user is logged in at all
          const authToken = localStorage.getItem('authToken');
          const cognitoTokens = localStorage.getItem('cognitoTokens');
          
          if (!authToken && !cognitoTokens) {
            // The user might not be logged in
            setSubmissionError('Necesitas iniciar sesión para enviar una denuncia.');
          } else {
            // The user appears to be logged in but we can't get their ID
            setSubmissionError('No se pudo identificar tu cuenta de usuario. Por favor cierra sesión y vuelve a iniciar sesión.');
          }
          
          return;
        }
        
        // Get authentication token information early to avoid reference errors
        const authToken = localStorage.getItem('authToken');
        const cognitoTokensStr = localStorage.getItem('cognitoTokens');
        
        // APPROACH 1: Try using a standard JSON request when no files are present
        // This is more reliable than FormData for basic text fields
        if (!values.archivos || values.archivos.length === 0) {
          
          
          // Extract clean denunciado value (might have been updated by pre-creation)
          let cleanDenunciado = values.denunciado 
            ? (typeof values.denunciado === 'object' ? values.denunciado.value : values.denunciado) 
            : null;
            
          // Get denunciado from denunciados array if main denunciado field is null but we have denunciados
          if (!cleanDenunciado && values.denunciados && values.denunciados.length > 0) {
            const firstDenunciado = values.denunciados[0];
            cleanDenunciado = typeof firstDenunciado === 'object' ? firstDenunciado.value : firstDenunciado;
          }
          
          // Validate we have a denunciado
          if (!cleanDenunciado) {
            setSubmissionError('Se requiere al menos un denunciado para crear la denuncia');
            setIsSubmitting(false);
            return;
          }
            
          // Create JSON payload
          const jsonPayload = {
            id_usuario: userId,
            tipo: values.tipo ? (typeof values.tipo === 'object' ? values.tipo.value : values.tipo) : null,
            denunciado: cleanDenunciado,
            descripcion: values.descripcion,
            fecha_ocurrencia: values.fecha_ocurrencia ? values.fecha_ocurrencia.toISOString() : null,
            departamento_ocurrencia: values.departamento_ocurrencia || null,
            provincia_ocurrencia: values.provincia_ocurrencia || null,
            distrito_ocurrencia: values.distrito_ocurrencia || null,
            location_detalle: values.location_detalle || null,
          };
          
          // Include newDenunciado value when denunciado is 'new'
          if (jsonPayload.denunciado === 'new' && newDenunciado) {
            jsonPayload.newDenunciado = newDenunciado;
            
            // Also add name explicitly to help backend extract it with various alternate keys
            jsonPayload.nombre_denunciado = newDenunciado;
            jsonPayload.denunciado_nombre = newDenunciado;
            jsonPayload.denunciadoNombre = newDenunciado;
            // Create a denunciado object with nombre (the backend might check for this format)
            jsonPayload.denunciadoObj = { nombre: newDenunciado };
          }
          
          // Add url_evidencia if available
          const filteredUrls = values.url_evidencia.filter(url => url.trim() !== '');
          if (filteredUrls.length > 0) {
            jsonPayload.url_evidencia = filteredUrls;
          }
          
          // Add ubicacion if available
          if (selectedLocation) {
            jsonPayload.ubicacion = {
              lat: selectedLocation.lat,
              lng: selectedLocation.lng
            };
          }
          
          
          
          // Set up authentication headers for JSON request
          const headers = {
            'Content-Type': 'application/json',
          };
          
          // Add authentication token
          if (authToken) {
            headers.Authorization = `Bearer ${authToken}`;
          } else if (cognitoTokensStr) {
            try {
              const cognitoTokens = JSON.parse(cognitoTokensStr);
              if (cognitoTokens.accessToken) {
                headers.Authorization = `Bearer ${cognitoTokens.accessToken}`;
              }
            } catch (e) {
              
            }
          }
          
          // Add user ID as a query parameter fallback for development
          // This helps bypass authentication errors if the token is invalid
          const url = `${config.apiUrl}/denuncias?userId=${encodeURIComponent(userId)}`;
          
          
          // Use JSON payload for the request with additional error logging
          return axios.post(url, jsonPayload, { headers })
            .then(response => {
              
              return response;
            })
            .catch(error => {
              // Handle specific authentication error
              if (error.response && error.response.data && error.response.data.message === "Acceso denegado. Por favor inicie sesión para acceder a esta funcionalidad.") {
                setSubmissionError('Se requiere iniciar sesión para enviar una denuncia. Por favor inicia sesión o regístrate primero.');
                return Promise.reject(error);
              }
              
              if (error.response) {
                // Handle other API errors
                const errorMessage = error.response.data?.message || 'Error al enviar la denuncia. Por favor intente nuevamente.';
                setSubmissionError(errorMessage);
              } else {
                // Network or other errors
                setSubmissionError('Error de conexión. Verifique su conexión a internet e intente nuevamente.');
              }
              
              throw error;
            });
        }
          
        // APPROACH 2: Use FormData only when files are present
        
        const formData = new FormData();
        
        // Add critical ID field first - this needs to be at the start of the FormData
        formData.append('id_usuario', userId);
        
        
        // Process tipo field - ensure it's a string value, not an object
        if (values.tipo) {
          const tipoValue = typeof values.tipo === 'object' ? values.tipo.value : values.tipo;
          formData.append('tipo', tipoValue);
          
        } else {
          
        }
        
        // Handle denunciado field properly - needs to be early in the FormData
        if (values.denunciado) {
          // Get the potentially updated value after pre-creation
          const denunciadoValue = typeof values.denunciado === 'object' ? values.denunciado.value : values.denunciado;
          
          // Double-check it's not still set to 'new'
          if (denunciadoValue === 'new') {
            
          }
          
          formData.append('denunciado', denunciadoValue);
          
          
          // Add newDenunciado field if denunciado is 'new'
          if (denunciadoValue === 'new' && newDenunciado) {
            formData.append('newDenunciado', newDenunciado);
            
            // Add many alternate field names to help backend extract it in different formats
            formData.append('nombre_denunciado', newDenunciado);
            formData.append('denunciadoNombre', newDenunciado);
            formData.append('denunciado_nombre', newDenunciado);
            // Add as a JSON string too in case backend expects it
            formData.append('denunciadoObj', JSON.stringify({
              nombre: newDenunciado
            }));
            
          }
        } else {
          
        }
        
        // Always include descripcion - a critical field
        formData.append('descripcion', values.descripcion);
        
        
        // Add the remaining fields in a specific order
        
        if (values.fecha_ocurrencia) {
          formData.append('fecha_ocurrencia', values.fecha_ocurrencia.toISOString());
        }
        
        // Location fields
        if (values.departamento_ocurrencia) {
          formData.append('departamento_ocurrencia', String(values.departamento_ocurrencia));
        }
        
        if (values.provincia_ocurrencia) {
          formData.append('provincia_ocurrencia', String(values.provincia_ocurrencia));
        }
        
        if (values.distrito_ocurrencia) {
          formData.append('distrito_ocurrencia', String(values.distrito_ocurrencia));
        }
        
        if (values.location_detalle) {
          formData.append('location_detalle', String(values.location_detalle));
        }
        
        // Add map coordinates
        if (selectedLocation) {
          
          formData.append('ubicacion[lat]', String(selectedLocation.lat));
          formData.append('ubicacion[lng]', String(selectedLocation.lng));
        }
        
        // Add URLs - try a different approach for multipart/form-data
        const filteredUrls = values.url_evidencia.filter(url => url.trim() !== '');
        if (filteredUrls.length > 0) {
          // Include the JSON string version (more reliable)
          const urlsJson = JSON.stringify(filteredUrls);
          formData.append('url_evidencia_json', urlsJson);
          
          
          // Also include each URL individually as a backup
          filteredUrls.forEach((url, index) => {
            formData.append(`url_evidencia[${index}]`, url);
          });
        }
        
        // Add files last to avoid confusion with other fields
        if (values.archivos && values.archivos.length > 0) {
          
          values.archivos.forEach(file => {
            formData.append('archivos', file);
            
          });
        }
        
        // Set up authentication headers - DO NOT set Content-Type for FormData
        // Let the browser handle the Content-Type with the correct boundary
        const headers = {};
        
        // Use the auth tokens we retrieved earlier
        
        if (authToken) {
          headers.Authorization = `Bearer ${authToken}`;
          
        } else if (cognitoTokensStr) {
          try {
            const cognitoTokens = JSON.parse(cognitoTokensStr);
            if (cognitoTokens.accessToken) {
              headers.Authorization = `Bearer ${cognitoTokens.accessToken}`;
              
            }
          } catch (e) {
            
          }
        }
        
        // Process selected denunciados
        // This includes creating any new denunciados and collecting existing IDs
        const denunciadoIds = [];
        
        // Process any new denunciados first
        if (newDenunciados && newDenunciados.length > 0) {
          const denunciadoAuthHeaders = { ...headers };
          denunciadoAuthHeaders['Content-Type'] = 'application/json';
          
          for (const newDenunciadoData of newDenunciados) {
            try {
              // Create new Denunciado with authentication
              const denunciadoResponse = await axios.post(
                `${config.apiUrl}/denunciados`, 
                {
                  nombre: newDenunciadoData.nombre
                },
                { headers: denunciadoAuthHeaders }
              );
              
              // Add this new ID to our list
              denunciadoIds.push(denunciadoResponse.data._id);
            } catch (denunciadoError) {
              
              // Continue with other denunciados even if one fails
            }
          }
        }
        
        // Add existing denunciados (non-new ones)
        if (values.denunciados && Array.isArray(values.denunciados)) {
          values.denunciados.forEach(denunciado => {
            if (denunciado && denunciado.value && denunciado.value !== 'new') {
              // Add existing denunciados to our list
              if (!denunciadoIds.includes(denunciado.value)) {
                denunciadoIds.push(denunciado.value);
              }
            }
          });
        }
        
        // Update formData with denunciado IDs - we need to handle array values
        if (denunciadoIds.length > 0) {
          // Remove any old values first
          formData.delete('denunciados');
          
          // Add each ID to the form data
          denunciadoIds.forEach((id, index) => {
            formData.append(`denunciados[${index}]`, id);
          });
        }
        
        // Log submission summary
        console.log("SUBMITTING FORM WITH:", {
          method: 'FormData',
          requiredFields: {
            id_usuario: userId,
            tipo: values.tipo?.value || values.tipo,
            denunciados: denunciadoIds,
            descripcion: values.descripcion ? values.descripcion.substring(0, 30) + "..." : null
          },
          newDenunciados: newDenunciados.length ? newDenunciados.map(d => d.nombre).join(', ') : 'none',
          fileCount: values.archivos?.length || 0
        });
        
        // Always add newDenunciado explicitly if we're using a new denunciado
        if (values.denunciado && 
            ((typeof values.denunciado === 'object' && values.denunciado.value === 'new') || 
            values.denunciado === 'new') && 
            newDenunciado) {
          // CRITICAL: For FormData, we need to preserve the full object structure
          // This is essential because the backend needs to see the label
          if (typeof values.denunciado === 'object') {
            const denunciadoStr = JSON.stringify(values.denunciado);
            formData.set('denunciadoFull', denunciadoStr);
            
          }
          
          formData.set('newDenunciado', newDenunciado);
          formData.set('newDenunciadoName', newDenunciado); // Add an alternate format
          formData.set('new_denunciado_name', newDenunciado); // Add an alternate format
          formData.set('nombre_denunciado', newDenunciado); // Add an alternate format
          
        }
        
        // Submit the form, first trying FormData if files exist, 
        // falling back to JSON if FormData fails
        let response;
        try {
          if (values.archivos && values.archivos.length > 0) {
            // Try sending with FormData for file uploads
            
            
            // Add fallback userId in URL for development
            const url = `${config.apiUrl}/denuncias?userId=${encodeURIComponent(userId)}`;
            
            
            try {
              response = await axios.post(url, formData, {
                headers,
                // Prevent axios from manipulating the FormData
                transformRequest: [(data) => data],
              });
              
              
            } catch (formDataError) {
              
              if (formDataError.response) {
                
              }
              throw formDataError;
            }
          } else {
            // If no files, prefer JSON approach directly (more reliable)
            
            
            // Create a simpler, more direct JSON payload
            const simpleTipo = values.tipo && typeof values.tipo === 'object' ? values.tipo.value : values.tipo;
            
            // CRITICAL: Save denunciado label before simplifying
            let denunciadoLabel = null;
            if (values.denunciado && typeof values.denunciado === 'object' && values.denunciado.label) {
              denunciadoLabel = values.denunciado.label;
              
            }
            
            const simpleDenunciado = values.denunciado && typeof values.denunciado === 'object' ? values.denunciado.value : values.denunciado;
            
            // Build the payload with simplified values
            const jsonPayload = {
              id_usuario: userId,
              tipo: simpleTipo,
              denunciado: simpleDenunciado,
              descripcion: values.descripcion,
            };
            
            // Add newDenunciado if needed (always include with new denunciados)
            if (simpleDenunciado === 'new') {
              // CRITICALLY IMPORTANT APPROACH:
              // 1. First, directly use the denunciado object if it's available with originalName
              if (values.denunciado && typeof values.denunciado === 'object') {
                
                
                // Add the complete object structure for the backend to parse
                jsonPayload.denunciadoFull = JSON.stringify(values.denunciado);
                
                
                // Also add originalName directly if available
                if (values.denunciado.originalName) {
                  const originalName = values.denunciado.originalName;
                  jsonPayload.denunciadoOriginalName = originalName;
                  jsonPayload.newDenunciado = originalName;
                  jsonPayload.newDenunciadoName = originalName;
                  jsonPayload.nombre_denunciado = originalName;
                  
                  // Add denunciadoName param for extra reliability
                  jsonPayload.denunciadoName = originalName;
                  
                  
                  
                  // This is to handle cases where newDenunciado didn't get set properly
                  if (!newDenunciado) {
                    
                    setNewDenunciado(originalName);
                  }
                }
              }
              
              // 2. Directly use the denunciado label if we captured it
              if (denunciadoLabel && denunciadoLabel.includes('Crear nuevo:')) {
                // Extract name from quote marks in label
                const matches = denunciadoLabel.match(/Crear nuevo: "([^"]+)"/);
                if (matches && matches[1]) {
                  const extractedName = matches[1].trim();
                  jsonPayload.newDenunciado = extractedName; 
                  jsonPayload.newDenunciadoName = extractedName;
                  jsonPayload.nombre_denunciado = extractedName;
                  
                  // This is to handle cases where newDenunciado didn't get set properly
                  if (!newDenunciado) {
                    
                    setNewDenunciado(extractedName);
                  }
                  
                  
                }
              } 
              // 3. Fallback: use the newDenunciado from state if available
              else if (newDenunciado) {
                jsonPayload.newDenunciado = newDenunciado;
                jsonPayload.newDenunciadoName = newDenunciado;
                jsonPayload.nombre_denunciado = newDenunciado;
                jsonPayload.denunciadoOriginalName = newDenunciado;
                
              }
              // 4. Another fallback: Always include the full label
              if (denunciadoLabel) {
                jsonPayload.denunciadoLabel = denunciadoLabel;
                
              }
              
              // 5. Let the frontend know we're doing a special extraction
              jsonPayload.useSpecialExtraction = true;
              
              
              console.log('Final newDenunciado values in payload:', {
                newDenunciado: jsonPayload.newDenunciado,
                newDenunciadoName: jsonPayload.newDenunciadoName,
                denunciadoLabel: jsonPayload.denunciadoLabel
              });
            }
            
            // Add optional fields only if they have values
            if (values.fecha_ocurrencia) {
              jsonPayload.fecha_ocurrencia = values.fecha_ocurrencia.toISOString();
            }
            
            if (values.departamento_ocurrencia) {
              jsonPayload.departamento_ocurrencia = values.departamento_ocurrencia;
            }
            
            if (values.provincia_ocurrencia) {
              jsonPayload.provincia_ocurrencia = values.provincia_ocurrencia;
            }
            
            if (values.distrito_ocurrencia) {
              jsonPayload.distrito_ocurrencia = values.distrito_ocurrencia;
            }
            
            if (values.location_detalle) {
              jsonPayload.location_detalle = values.location_detalle;
            }
            
            // Add url_evidencia if available
            const filteredUrls = values.url_evidencia.filter(url => url.trim() !== '');
            if (filteredUrls.length > 0) {
              jsonPayload.url_evidencia = filteredUrls;
            }
            
            // Add ubicacion if available
            if (selectedLocation) {
              jsonPayload.ubicacion = {
                lat: selectedLocation.lat,
                lng: selectedLocation.lng
              };
            }
            
            // Set content type for JSON
            const jsonHeaders = { ...headers, 'Content-Type': 'application/json' };
            
            // Add fallback userId in URL for development
            const url = `${config.apiUrl}/denuncias?userId=${encodeURIComponent(userId)}`;
            
            
            try {
              response = await axios.post(url, jsonPayload, { 
                headers: jsonHeaders 
              });
              
              
            } catch (jsonError) {
              
              if (jsonError.response) {
                
              }
              throw jsonError;
            }
          }
        } catch (error) {
          // If FormData fails with 400 "required fields", try a fallback approach
          if (error.response && error.response.status === 400 && 
              error.response.data.message && 
              error.response.data.message.includes('Campos requeridos')) {
                
            
            
            // Create a JSON payload as a fallback
            const jsonPayload = {
              id_usuario: userId,
              tipo: values.tipo ? (typeof values.tipo === 'object' ? values.tipo.value : values.tipo) : null,
              denunciado: values.denunciado ? (typeof values.denunciado === 'object' ? values.denunciado.value : values.denunciado) : null,
              descripcion: values.descripcion,
              fecha_ocurrencia: values.fecha_ocurrencia ? values.fecha_ocurrencia.toISOString() : null,
              departamento_ocurrencia: values.departamento_ocurrencia || null,
              provincia_ocurrencia: values.provincia_ocurrencia || null,
              distrito_ocurrencia: values.distrito_ocurrencia || null,
              location_detalle: values.location_detalle || null,
            };
            
            // Include newDenunciado field if denunciado is 'new'
            const denunciadoValue = jsonPayload.denunciado;
            if (denunciadoValue === 'new' && newDenunciado) {
              jsonPayload.newDenunciado = newDenunciado;
              
            }
            
            // Add url_evidencia if available
            const filteredUrls = values.url_evidencia.filter(url => url.trim() !== '');
            if (filteredUrls.length > 0) {
              jsonPayload.url_evidencia = filteredUrls;
            }
            
            // Add ubicacion if available
            if (selectedLocation) {
              jsonPayload.ubicacion = {
                lat: selectedLocation.lat,
                lng: selectedLocation.lng
              };
            }
            
            
            
            // Note: We can't include files in this approach
            if (values.archivos && values.archivos.length > 0) {
              
            }
            
            // Set content type for JSON
            const jsonHeaders = { ...headers, 'Content-Type': 'application/json' };
            
            // Try with JSON approach
            
            try {
              // Add fallback userId in URL for development
              const url = `${config.apiUrl}/denuncias?userId=${encodeURIComponent(userId)}`;
              
              
              response = await axios.post(url, jsonPayload, { 
                headers: jsonHeaders 
              });
              
              
            } catch (fallbackError) {
              
              if (fallbackError.response) {
                
              }
              throw fallbackError;
            }
          } else {
            // If it's not a "required fields" error, rethrow
            
            throw error;
          }
        }
        
        // Process the response - with special handling for dev environment responses
        if (response.status === 201) {
          // Check if this is a simulated success response (development mock)
          const isMockResponse = response.data._debug || response.data._errorInfo;
          
          if (isMockResponse && process.env.NODE_ENV === 'development') {
            
            
            
            // Still treat it as a success in development
            localStorage.setItem('lastDenunciaId', response.data._id);
            localStorage.setItem('lastDenunciaMock', 'true'); // Mark as mock for reference
            setSubmissionSuccess(true);
            window.scrollTo(0, 0);
          } else {
            // This is a genuine success response
            
            localStorage.setItem('lastDenunciaId', response.data._id);
            localStorage.removeItem('lastDenunciaMock'); // Clear any mock flag
            setSubmissionSuccess(true);
            window.scrollTo(0, 0);
          }
        } else {
          // This should rarely happen as most responses are 201 or error
          
          setSubmissionError('Error al enviar la denuncia. Por favor intente de nuevo.');
        }
      } catch (error) {
        
        
        // Display detailed error information
        let errorMessage = 'Error al enviar la denuncia';
        
        if (error.response) {
          const status = error.response.status;
          const data = error.response.data;
          
          
          
          if (status === 500) {
            errorMessage = 'Error del servidor al procesar la denuncia. Por favor intente nuevamente.';
            
            // If there's a more detailed message from the server, append it
            if (data?.message) {
              errorMessage += ` (${data.message})`;
            }
            
            // If there's a specific error from the server, append it
            if (data?.error) {
              
              errorMessage += ` Detalles: ${data.error}`;
            }
          } else if (status === 400) {
            errorMessage = `Error en el formulario: ${data.message || 'Datos inválidos'}`;
          } else if (status === 401) {
            errorMessage = 'No está autorizado para enviar denuncias. Por favor inicie sesión nuevamente.';
          } else {
            errorMessage = `Error (${status}): ${data.message || 'Por favor intente nuevamente'}`;
          }
        } else if (error.request) {
          // The request was made but no response was received
          
          errorMessage = 'No se recibió respuesta del servidor. Verifique su conexión a internet.';
        } else {
          // Something happened in setting up the request
          
          errorMessage = `Error de configuración: ${error.message}`;
        }
        
        setSubmissionError(errorMessage);
        
        // Log additional debug information to help troubleshoot
        console.log('Form data at time of error:', {
          userId: formik.values.id_usuario || localStorage.getItem('userId') || '',
          tipo: formik.values.tipo ? 
            (typeof formik.values.tipo === 'object' ? formik.values.tipo.value : formik.values.tipo) : 
            null,
          denunciado: formik.values.denunciado ? 
            (typeof formik.values.denunciado === 'object' ? formik.values.denunciado.value : formik.values.denunciado) : 
            null,
          descripcion: formik.values.descripcion ? formik.values.descripcion.substring(0, 30) + '...' : null,
          fileCount: formik.values.archivos?.length || 0
        });
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const handleLinkChange = (index, value) => {
    const newLinks = links.map((link, i) => (i === index ? value : link));
    setLinks(newLinks);
    formik.setFieldValue('url_evidencia', newLinks);
  };

  const handleRemoveLink = (index) => {
    const newLinks = links.filter((_, i) => i !== index);
    setLinks(newLinks);
    formik.setFieldValue('url_evidencia', newLinks);
  };

  const handleAddLink = () => {
    setLinks([...links, '']);
    formik.setFieldValue('url_evidencia', [...links, '']);
  };

  const handleUbigeoChange = (departamentoName, provinciaName, distritoName) => {
    formik.setFieldValue('departamento_ocurrencia', departamentoName);
    formik.setFieldValue('provincia_ocurrencia', provinciaName);
    formik.setFieldValue('distrito_ocurrencia', distritoName);
  
    if (departamentoName && provinciaName && distritoName) {
      getCoordinatesForUbigeo(departamentoName, provinciaName, distritoName);
    }
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    
    // If the location includes a name from reverse geocoding, use it
    if (location.locationName) {
      formik.setFieldValue('location_detalle', location.locationName);
      
    } else {
      // Don't clear existing location_detalle if no name is provided
      
    }
    
    
  };

  const getCoordinatesForUbigeo = async (departamento, provincia, distrito) => {
    try {
      
      
      // Create a more detailed search query to improve location accuracy
      const response = await axios.get(`https://nominatim.openstreetmap.org/search`, {
        params: {
          format: 'json',
          country: 'Peru',
          state: departamento,
          county: provincia,
          city: distrito,
          addressdetails: 1,
          limit: 1
        }
      });
      
      const data = response.data;
      
      if (data.length > 0) {
        const coordinates = { lat: parseFloat(data[0].lat), lng: parseFloat(data[0].lon) };
        
        
        // Set map center to these coordinates - will be used when opening the map
        setLocationCenter(coordinates);
        
        // Get a nice location name from the result if available
        let locationDetail = distrito;
        if (data[0].display_name) {
          locationDetail = data[0].display_name.split(',')[0];
        }
        
        // If we don't have a location selected yet, set coordinates for the map 
        // but don't actually select a location or set location_detalle
        if (!selectedLocation) {
          // Just update the map center for when the map opens
          // But don't set a selected location or location detail automatically
          
        }
        
        return coordinates;
      } else {
        
        return null;
      }
    } catch (error) {
      
      return null;
    }
  };
  
  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    
    // Filter out invalid file types (non-image, non-video, non-audio)
    const validFiles = files.filter(file => 
      file.type.startsWith('image/') || 
      file.type.startsWith('video/') || 
      file.type.startsWith('audio/')
    );
    
    // Filter out files larger than 10MB
    const sizeValidFiles = validFiles.filter(file => file.size <= 10 * 1024 * 1024);
    
    if (validFiles.length !== files.length) {
      setSubmissionError('Solo se permiten archivos de imagen, video y audio. Algunos archivos fueron ignorados.');
    }
    
    if (sizeValidFiles.length !== validFiles.length) {
      setSubmissionError('Algunos archivos superan el límite de 10MB y fueron ignorados.');
    }
    
    // Add files to state
    const newFiles = [...uploadedFiles, ...sizeValidFiles];
    setUploadedFiles(newFiles);
    formik.setFieldValue('archivos', newFiles);
  };
  
  const handleRemoveFile = (index) => {
    const newFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(newFiles);
    formik.setFieldValue('archivos', newFiles);
  };
  
  const getFileTypeIcon = (file) => {
    if (file.type.startsWith('image/')) return <ImageIcon className="text-blue-400" />;
    if (file.type.startsWith('video/')) return <VideoIcon className="text-red-400" />;
    if (file.type.startsWith('audio/')) return <AudioIcon className="text-green-400" />;
    return <FileIcon className="text-gray-400" />;
  };
  
  const getFileSize = (size) => {
    if (size < 1024) return `${size} B`;
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;
    return `${(size / (1024 * 1024)).toFixed(1)} MB`;
  };
  
  // Drag and drop handlers
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };
  
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };
  
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragging) {
      setIsDragging(true);
    }
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const files = Array.from(e.dataTransfer.files);
    
    // Use the same validation logic as in handleFileSelect
    const validFiles = files.filter(file => 
      file.type.startsWith('image/') || 
      file.type.startsWith('video/') || 
      file.type.startsWith('audio/')
    );
    
    const sizeValidFiles = validFiles.filter(file => file.size <= 10 * 1024 * 1024);
    
    if (validFiles.length !== files.length) {
      setSubmissionError('Solo se permiten archivos de imagen, video y audio. Algunos archivos fueron ignorados.');
    }
    
    if (sizeValidFiles.length !== validFiles.length) {
      setSubmissionError('Algunos archivos superan el límite de 10MB y fueron ignorados.');
    }
    
    // Add files to state
    const newFiles = [...uploadedFiles, ...sizeValidFiles];
    setUploadedFiles(newFiles);
    formik.setFieldValue('archivos', newFiles);
  };

  // If submission was successful, show a success message
  if (submissionSuccess) {
    return (
      <Layout>
        <h2 className="text-center text-4xl font-mono text-hyc-light">¡Denuncia Enviada!</h2>
        
        <Card className="mt-8 bg-hyc-secondary border-l-4 border-green-500">
          <div className="p-8 text-center">
            <div className="inline-flex items-center justify-center w-20 h-20 rounded-full bg-green-100 mb-6">
              <CheckIcon className="text-green-600 text-3xl" />
            </div>
            
            <h3 className="text-2xl font-mono text-hyc-light mb-4">Gracias por su contribución</h3>
            <p className="text-hyc-light text-lg mb-8">
              Su denuncia ha sido registrada exitosamente y será revisada por nuestro equipo.
            </p>
            
            <div className="flex flex-col md:flex-row gap-4 justify-center mt-6">
              <Button onClick={() => navigate('/mis-denuncias')} variant="primary">
                Ver Mis Denuncias
              </Button>
              <Button onClick={() => navigate('/explorar')} variant="secondary">
                Explorar Denuncias
              </Button>
              <Button onClick={() => navigate('/denunciaform')} variant="outline">
                Crear Otra Denuncia
              </Button>
            </div>
          </div>
        </Card>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="text-center mb-10">
        <h2 className="text-4xl font-mono text-hyc-light">Enviar Denuncia</h2>
        <p className="text-hyc-light mt-2">
          Complete el formulario a continuación para reportar un incidente
        </p>
        <p className="text-hyc-light/70 mt-1">
          Le recomendamos completar todos los campos posibles para poder validar su denuncia.
        </p>
      </div>
      
      {/* Authentication notice - only show when not authenticated */}
      {!isAuthenticated && (
        <div className="mb-6 p-4 bg-blue-900/30 border border-blue-400 rounded-lg flex items-start">
          <InfoCircleIcon className="text-blue-400 mr-3 mt-1 flex-shrink-0" />
          <div className="text-blue-200">
            <p className="font-medium">Esta funcionalidad requiere iniciar sesión</p>
            <p className="text-sm mt-1">Para enviar una denuncia, primero debes iniciar sesión o registrarte en la plataforma. Esto nos permite asegurarnos de que solo personas reales envíen denuncias y puedan ver el estado de las mismas.</p>
            <div className="mt-3 flex space-x-4">
              <Link to="/login" className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg text-sm font-medium transition-colors">
                Iniciar Sesión
              </Link>
              <Link to="/registro" className="px-4 py-2 border border-blue-400 hover:bg-blue-800/30 text-blue-200 rounded-lg text-sm font-medium transition-colors">
                Registrarse
              </Link>
            </div>
          </div>
        </div>
      )}
      
      {submissionError && (
        <div className="mb-6 p-4 bg-red-900/30 border border-red-500 rounded-lg flex items-start">
          <WarningIcon className="text-red-400 mr-3 mt-1 flex-shrink-0" />
          <p className="text-red-300">{submissionError}</p>
        </div>
      )}

      <Card className="rounded-xl shadow-lg border border-hyc-light/10 overflow-hidden">
        <div className="bg-hyc-secondary p-4 border-b border-hyc-light/10">
          <h3 className="font-mono text-xl text-hyc-light">Detalles de la Denuncia</h3>
        </div>
        
        <form onSubmit={formik.handleSubmit} className="p-6 space-y-8">
          {/* TIPO DE DENUNCIA */}
          <div className="space-y-4">
            <div className="flex items-center">
              <TagIcon className="text-hyc-gold mr-2" />
              <h4 className="text-lg font-medium text-hyc-light">Tipo de Denuncia</h4>
              <span className="text-hyc-accent ml-2">*</span>
              <Tooltip text="Seleccione el tipo de incidente que desea reportar. Este campo es obligatorio." />
            </div>
            
            <div className="flex flex-wrap gap-2 mt-3">
              {tipoOptions.map((option) => (
                <div key={option.value} className="relative group">
                  <button
                    type="button"
                    className={`px-4 py-2 rounded-lg transition-colors ${
                      formik.values.tipo?.value === option.value
                        ? 'bg-hyc-orange text-white' 
                        : 'bg-hyc-light/10 text-hyc-light hover:bg-hyc-gold hover:text-hyc-dark'
                    }`}
                    onClick={() => formik.setFieldValue('tipo', option)}
                  >
                    {option.label}
                  </button>
                  
                  {/* Tooltip */}
                  <div className="absolute z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-200 bottom-full left-1/2 transform -translate-x-1/2 mb-2 p-3 bg-hyc-dark border border-hyc-light/20 rounded shadow-lg text-hyc-light text-xs md:text-sm w-max max-w-[280px]">
                    {option.description}
                    <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 border-l-8 border-r-8 border-l-transparent border-r-transparent border-t-8 border-t-hyc-dark"></div>
                  </div>
                </div>
              ))}
            </div>
            
            {formik.touched.tipo && formik.errors.tipo ? (
              <p className="text-hyc-accent text-sm mt-2">{formik.errors.tipo}</p>
            ) : null}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* LEFT COLUMN */}
            <div className="space-y-6">
              {/* FECHA Y HORA */}
              <div className="space-y-2">
                <div className="flex items-center">
                  <CalendarIcon className="text-hyc-gold mr-2" />
                  <label className="text-lg font-medium text-hyc-light">
                    Fecha y Hora del Hecho
                  </label>
                  <Tooltip text="Si no recuerda la fecha y hora exacta, puede ser aproximada. Este campo es opcional." />
                </div>
                
                <div className="relative">
                  <DatePicker
                    selected={formik.values.fecha_ocurrencia}
                    onChange={(date) => formik.setFieldValue('fecha_ocurrencia', date)}
                    dateFormat="dd/MM/yyyy HH:mm"
                    placeholderText="DD/MM/YYYY HH:mm"
                    className="w-full p-3 border rounded-lg bg-hyc-dark text-hyc-light border-gray-500 focus:border-hyc-highlight focus:outline-none focus:ring-1 focus:ring-hyc-highlight pl-3 pr-10 appearance-none"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Hora"
                    /* Enable dropdowns but with custom styling */
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    yearDropdownItemNumber={10}
                    /* Make the month/year navigation better */
                    renderCustomHeader={({
                      date,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                      changeYear,
                      changeMonth,
                      decreaseYear,
                      increaseYear,
                      prevYearButtonDisabled,
                      nextYearButtonDisabled,
                    }) => (
                      <div className="flex items-center justify-between p-2 bg-hyc-secondary border-b border-hyc-light/10">
                        {/* Fast backward/forward controls */}
                        <div className="flex items-center gap-2">
                          <button
                            onClick={decreaseYear}
                            disabled={prevYearButtonDisabled}
                            type="button"
                            className="p-1 text-hyc-light hover:text-hyc-gold transition-colors"
                            title="Año anterior"
                          >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                            </svg>
                          </button>
                          
                          <button
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                            type="button"
                            className="p-1 text-hyc-light hover:text-hyc-gold transition-colors"
                            title="Mes anterior"
                          >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                            </svg>
                          </button>
                        </div>

                        {/* Month and Year display/selectors */}
                        <div className="flex gap-2 items-center">
                          <select
                            value={date.getMonth()}
                            onChange={({ target: { value } }) => changeMonth(value)}
                            className="font-mono text-hyc-gold bg-transparent border-none focus:ring-0 cursor-pointer appearance-none text-center hover:text-hyc-light transition-colors font-medium px-2"
                            style={{ WebkitAppearance: 'none' }}
                          >
                            {['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'].map((month, i) => (
                              <option key={month} value={i} className="bg-hyc-dark text-hyc-light">
                                {month}
                              </option>
                            ))}
                          </select>
                          
                          <select
                            value={date.getFullYear()}
                            onChange={({ target: { value } }) => changeYear(value)}
                            className="font-mono text-hyc-gold bg-transparent border-none focus:ring-0 cursor-pointer appearance-none text-center hover:text-hyc-light transition-colors font-medium px-2"
                            style={{ WebkitAppearance: 'none' }}
                          >
                            {Array.from({ length: 20 }, (_, i) => date.getFullYear() - 10 + i).map((year) => (
                              <option key={year} value={year} className="bg-hyc-dark text-hyc-light">
                                {year}
                              </option>
                            ))}
                          </select>
                          
                          <svg className="w-4 h-4 text-hyc-light" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                          </svg>
                        </div>

                        {/* Fast forward controls */}
                        <div className="flex items-center gap-2">
                          <button
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                            type="button"
                            className="p-1 text-hyc-light hover:text-hyc-gold transition-colors"
                            title="Mes siguiente"
                          >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                            </svg>
                          </button>
                          
                          <button
                            onClick={increaseYear}
                            disabled={nextYearButtonDisabled}
                            type="button"
                            className="p-1 text-hyc-light hover:text-hyc-gold transition-colors"
                            title="Año siguiente"
                          >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7m-8-14l7 7-7 7" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    )}
                    customInput={
                      <div className="w-full p-3 border rounded-lg bg-hyc-dark text-hyc-light border-gray-500 cursor-pointer flex justify-between items-center">
                        <span>{formik.values.fecha_ocurrencia ? formik.values.fecha_ocurrencia.toLocaleDateString('es-PE', { 
                          year: 'numeric', 
                          month: 'long', 
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        }) : 'Selecciona una fecha y hora'}</span>
                        <CalendarIcon className="text-hyc-light" />
                      </div>
                    }
                  />
                </div>
                
                {formik.touched.fecha_ocurrencia && formik.errors.fecha_ocurrencia ? (
                  <p className="text-hyc-accent text-sm">{formik.errors.fecha_ocurrencia}</p>
                ) : null}
              </div>

              {/* DENUNCIADO */}
              <div className="space-y-2">
                <DenunciadoSelect
                  formik={formik}
                  newDenunciados={newDenunciados}
                  setNewDenunciados={setNewDenunciados}
                  isMulti={true}
                />
              </div>

            </div>

            {/* RIGHT COLUMN */}
            <div className="space-y-6">
              {/* UBICACIÓN */}
              <div className="space-y-2">
                <UbigeoDropdowns 
                  onChange={handleUbigeoChange}
                  title="Ubicación del Hecho"
                  tooltipText="Si no recuerda el lugar exacto, use una ubicación aproximada. Este campo es opcional."
                />
                
                <div className="grid grid-cols-3 gap-4">
                  {formik.touched.departamento_ocurrencia && formik.errors.departamento_ocurrencia ? (
                    <p className="text-hyc-accent text-sm">{formik.errors.departamento_ocurrencia}</p>
                  ) : null}
                  {formik.touched.provincia_ocurrencia && formik.errors.provincia_ocurrencia ? (
                    <p className="text-hyc-accent text-sm">{formik.errors.provincia_ocurrencia}</p>
                  ) : null}
                  {formik.touched.distrito_ocurrencia && formik.errors.distrito_ocurrencia ? (
                    <p className="text-hyc-accent text-sm">{formik.errors.distrito_ocurrencia}</p>
                  ) : null}
                </div>
                
                <div className="mt-4">
                  <Button
                    type="button"
                    onClick={() => {
                      // Make sure locationCenter is set based on the selected location in the dropdowns
                      const { departamento_ocurrencia, provincia_ocurrencia, distrito_ocurrencia } = formik.values;
                      
                      // If dropdowns have values, try to center the map on that location
                      if (departamento_ocurrencia && provincia_ocurrencia && distrito_ocurrencia) {
                        // Get coordinates for the selected ubigeo if needed
                        // The function will update locationCenter state
                        getCoordinatesForUbigeo(departamento_ocurrencia, provincia_ocurrencia, distrito_ocurrencia);
                      }
                      
                      // Open the map modal after a short delay to ensure coordinates are set
                      setTimeout(() => setIsMapModalOpen(true), 100);
                    }}
                    variant="secondary"
                    className="flex items-center justify-center gap-2"
                  >
                    <MapIcon />
                    Ubicar en Mapa
                  </Button>
                  
                  {selectedLocation && (
                    <div className="mt-2 space-y-2">
                      <div className="text-sm text-hyc-gold">
                        <InfoCircleIcon className="inline mr-1" />
                        Ubicación seleccionada en el mapa
                      </div>
                      
                      {/* Add location detail field */}
                      <div>
                        <label className="block text-sm text-hyc-light/80 mb-1">
                          Dirección o detalle de ubicación
                        </label>
                        <input
                          type="text"
                          name="location_detalle"
                          value={formik.values.location_detalle}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Ej: Av. Arequipa 1234, Esquina con Jr. Huaraz"
                          className="w-full p-2 border rounded-lg bg-hyc-dark text-hyc-light border-gray-500 focus:border-hyc-highlight focus:outline-none focus:ring-1 focus:ring-hyc-highlight"
                        />
                      </div>
                    </div>
                  )}
                  
                  <div className="mt-2 text-sm text-hyc-light/70">
                    Si conoce la ubicación exacta del incidente, haga clic para seleccionarla en el mapa. Los denunciados suelen cometer faltas repetidamente en la misma ubicación.
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* DESCRIPCIÓN - FULL WIDTH */}
          <div className="space-y-3 pt-4">
            <div className="flex items-center">
              <TextIcon className="text-hyc-gold mr-2" />
              <label className="text-lg font-medium text-hyc-light">Descripción del Incidente</label>
              <span className="text-hyc-accent ml-2">*</span>
              <Tooltip text="Proporcione todos los detalles posibles sobre el incidente. Incluya qué sucedió, cuándo, dónde y quién estuvo involucrado. Este campo es obligatorio." />
            </div>
            
            <FormTextarea
              name="descripcion"
              value={formik.values.descripcion}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              rows={6}
              placeholder="Describa detalladamente el incidente..."
              error={formik.touched.descripcion && formik.errors.descripcion}
            />
          </div>
          
          {/* LINKS DE EVIDENCIA - FULL WIDTH */}
          <div className="space-y-3 pt-4">
            <div className="flex items-center">
              <LinkIcon className="text-hyc-gold mr-2" />
              <label className="text-lg font-medium text-hyc-light">Links de Evidencia</label>
              <Tooltip text="Agregue enlaces a fotos, videos o documentos que respalden su denuncia. Puede ser YouTube, Drive, Dropbox u otros servicios de alojamiento. Este campo es opcional." />
            </div>
            
            <div className="space-y-3">
              {links.map((link, index) => (
                <div key={index} className="flex items-center gap-2">
                  <input
                    type="url"
                    value={link}
                    onChange={(e) => handleLinkChange(index, e.target.value)}
                    placeholder="Ingrese un enlace a la evidencia (foto, video, documento)"
                    className="flex-grow p-3 border rounded-lg bg-hyc-dark text-hyc-light border-gray-500 focus:border-hyc-highlight focus:outline-none focus:ring-1 focus:ring-hyc-highlight"
                  />
                  
                  {index > 0 && (
                    <Button
                      type="button"
                      variant="outline"
                      className="flex-shrink-0 text-red-400 border-red-400/30 hover:bg-red-900/20"
                      onClick={() => handleRemoveLink(index)}
                    >
                      <DeleteIcon />
                    </Button>
                  )}
                </div>
              ))}
              
              <Button
                type="button"
                variant="secondary"
                className="flex items-center gap-2 mt-2"
                onClick={handleAddLink}
              >
                <AddIcon /> Añadir otro enlace
              </Button>
            </div>
          </div>
          
          {/* ARCHIVOS ADJUNTOS - FULL WIDTH */}
          <div className="space-y-3 pt-4 border-t border-hyc-light/10">
            <div className="flex items-center">
              <AttachmentIcon className="text-hyc-gold mr-2" />
              <label className="text-lg font-medium text-hyc-light">Archivos Adjuntos</label>
              <Tooltip text="Adjunte archivos como evidencia de la denuncia. Se aceptan imágenes (jpg, png), videos (mp4, mov) y audios (mp3, wav). Máximo 10MB por archivo." />
            </div>
            
            <div 
              className={`
                bg-hyc-dark/50 border-2 border-dashed rounded-lg p-6 text-center transition-colors
                ${isDragging ? 'border-hyc-gold border-4' : 'border-hyc-light/30 hover:border-hyc-gold/50'}
              `}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <input
                ref={fileInputRef}
                type="file"
                multiple
                accept="image/*,video/*,audio/*"
                onChange={handleFileSelect}
                className="hidden"
              />
              
              <div 
                onClick={() => fileInputRef.current.click()}
                className="cursor-pointer"
              >
                <FileUploadIcon className={`text-4xl mb-2 ${isDragging ? 'text-hyc-gold' : 'text-hyc-light/50'}`} />
                <p className="text-hyc-light mb-1">
                  {isDragging 
                    ? 'Suelta tus archivos aquí...' 
                    : 'Arrastra y suelta archivos aquí o haz clic para seleccionar'
                  }
                </p>
                <p className="text-hyc-light/50 text-sm">Archivos permitidos: imágenes, videos y audios (máx. 10MB)</p>
              </div>
            </div>
            
            {/* Lista de archivos subidos */}
            {uploadedFiles.length > 0 && (
              <div className="mt-4 space-y-2">
                <h5 className="text-hyc-light font-medium">Archivos seleccionados:</h5>
                <div className="bg-hyc-dark/30 rounded-lg p-2">
                  {uploadedFiles.map((file, index) => (
                    <div key={index} className="flex items-center justify-between bg-hyc-dark/50 p-3 rounded-md mb-2">
                      <div className="flex items-center">
                        {getFileTypeIcon(file)}
                        <div className="ml-3">
                          <p className="text-hyc-light font-medium truncate max-w-xs">{file.name}</p>
                          <p className="text-hyc-light/60 text-xs">{getFileSize(file.size)}</p>
                        </div>
                      </div>
                      <Button
                        type="button"
                        variant="outline"
                        className="text-red-400 border-red-400/30 hover:bg-red-900/20"
                        onClick={() => handleRemoveFile(index)}
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            )}
            
            {formik.touched.archivos && formik.errors.archivos ? (
              <p className="text-hyc-accent text-sm">{formik.errors.archivos}</p>
            ) : null}
          </div>
          
          {/* SUBMIT BUTTON - FULL WIDTH */}
          <div className="pt-6 border-t border-hyc-light/10">
            <Button
              type="submit"
              size="lg"
              fullWidth
              className="py-4 text-lg font-medium"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Enviando...' : 'Enviar Denuncia'}
            </Button>
            
            <p className="text-center text-hyc-light/70 text-sm mt-4">
              Al enviar esta denuncia, confirmo que toda la información proporcionada es verídica. HonoryCoima.org elimina denuncias spam automáticamente.
            </p>
          </div>
        </form>
      </Card>

      {/* MAP MODAL */}
      <MapModal
        isOpen={isMapModalOpen}
        onRequestClose={() => setIsMapModalOpen(false)}
        onLocationSelect={handleLocationSelect}
        initialCenter={locationCenter}
        ubigeoData={{
          distrito: formik.values.distrito_ocurrencia,
          provincia: formik.values.provincia_ocurrencia,
          departamento: formik.values.departamento_ocurrencia
        }}
      />
    </Layout>
  );
};

export default DenunciaForm;