import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate, Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';

// Import components directly to avoid circular dependencies
import Button from './Button';
import Card from './Card';
import FormInput from './FormInput';
import { InfoCircleIcon, UserIcon, LockIcon, SwitchIcon } from './Icons';
import UbigeoDropdowns from './UbigeoDropdowns';
import Subtitle from './Subtitle';
import Lightbox from './Lightbox';
import Layout from './Layout';
import config from '../config';

const validationSchema = Yup.object({
  apodo: Yup.string().required('Apodo es requerido'),
  email: Yup.string().email('Correo inválido').test('emailOrMobile', 'Correo es requerido', function (value) {
    const { isMobileLogin, telefono } = this.parent;
    return isMobileLogin || !!value;
  }),
  telefono: Yup.string().matches(/^[0-9]{9}$/, 'Número de teléfono inválido').test('mobileOrEmail', 'El número de teléfono es requerido', function (value) {
    const { isMobileLogin, email } = this.parent;
    return !isMobileLogin || !!value;
  }),
  departamento: Yup.string().required('Departamento es requerido'),
  provincia: Yup.string().required('Provincia es requerida'),
  distrito: Yup.string().required('Distrito es requerido'),
  aceptar_terminos: Yup.boolean().oneOf([true], 'Debes aceptar los términos y condiciones')
});

const UserRegistrationForm = () => {
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false);
  const [isMobileLogin, setIsMobileLogin] = useState(false);
  const [apodoError, setApodoError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [apodoAvailable, setApodoAvailable] = useState(false);
  const [emailAvailable, setEmailAvailable] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      apodo: '',
      email: '',
      telefono: '',
      isMobileLogin: false,
      departamento: 'Lima',  // Default value
      provincia: 'Lima',     // Default value  
      distrito: 'Miraflores', // Default value
      aceptar_terminos: false
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log('Submitting form data:', values);
      setErrorMessage('');

      // Additional validation before submit
      if (!values.apodo || (!values.email && !values.telefono) || 
          !values.departamento || !values.provincia || !values.distrito ||
          !values.aceptar_terminos) {
        console.error('Missing fields validation check:', {
          apodo: !!values.apodo,
          email: !!values.email,
          telefono: !!values.telefono,
          departamento: !!values.departamento,
          provincia: !!values.provincia,
          distrito: !!values.distrito,
          aceptar_terminos: !!values.aceptar_terminos
        });
        setErrorMessage('Por favor complete todos los campos requeridos');
        return;
      }

      // Prepare data for API
      const userData = {
        apodo: values.apodo.trim(),
        departamento: values.departamento.trim(),
        provincia: values.provincia.trim(),
        distrito: values.distrito.trim(),
        aceptar_terminos: !!values.aceptar_terminos // Ensure it's a boolean
      };

      // For now we only support email registration
      userData.email = values.email.trim();
      // Mobile registration is disabled for now
      
      // Validate all required fields have values
      const missingFields = [];
      if (!userData.apodo) missingFields.push('apodo');
      if (values.isMobileLogin && !userData.telefono) missingFields.push('telefono');
      if (!values.isMobileLogin && !userData.email) missingFields.push('email');
      if (!userData.departamento) missingFields.push('departamento');
      if (!userData.provincia) missingFields.push('provincia');
      if (!userData.distrito) missingFields.push('distrito');
      if (!userData.aceptar_terminos) missingFields.push('aceptar_terminos');
      
      if (missingFields.length > 0) {
        console.error('Missing fields:', missingFields);
        setErrorMessage(`Campos requeridos faltantes: ${missingFields.join(', ')}`);
        return;
      }
      
      try {
        console.log('Sending registration data to API:', userData);
        // Use OTP API endpoint for registration
        const otpApiUrl = `${config.apiUrl}/auth/otp/signup`;
        console.log('Sending request to:', otpApiUrl);
        const response = await axios.post(otpApiUrl, userData);
        
        // Store user information for verification step
        localStorage.setItem('registrationUserId', response.data.userId);
        localStorage.setItem('pendingRegistration', 'true');
        localStorage.setItem('pendingVerification', 'true');
        
        // Store username (email) for verification
        localStorage.setItem('authUsername', response.data.username);
        
        // Always email for now
        localStorage.setItem('contactMethod', 'email');
        localStorage.setItem('userEmail', values.email);
        
        // Store contact information regardless of auth method
        // This ensures we have both values available if needed
        if (values.telefono) {
          localStorage.setItem('userTelefono', values.telefono);
        }
        if (values.email) {
          localStorage.setItem('userEmail', values.email);
        }
        
        setRegistrationSuccess(true);
      } catch (error) {
        console.error('Error registering user:', error);
        
        // Log detailed error information
        if (error.response) {
          // The server responded with a status code outside the 2xx range
          console.error('Response error details:', {
            status: error.response.status,
            data: error.response.data,
            headers: error.response.headers
          });
          
          // Expanded error message with more detail
          console.error('Full error response data:', JSON.stringify(error.response.data, null, 2));
          
          // Handle different error status codes
          if (error.response.status === 400) {
            if (error.response.data?.message) {
              setErrorMessage(`Error: ${error.response.data.message}`);
            } else {
              setErrorMessage('Error 400: La solicitud no pudo ser procesada. Revisa todos los campos.');
            }
          } else if (error.response.status === 500) {
            if (error.response.data?.message) {
              // Show the server error message
              setErrorMessage(`Error del servidor: ${error.response.data.message}`);
              
              // Log additional error details if available
              if (error.response.data?.errorType) {
                console.error(`Server error type: ${error.response.data.errorType}`);
              }
            } else {
              setErrorMessage('Error 500: Error interno del servidor. Por favor intente más tarde.');
            }
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error('Request was made but no response received:', error.request);
          setErrorMessage('Error de conexión: No se recibió respuesta del servidor.');
        } else {
          // Something happened in setting up the request
          console.error('Error setting up request:', error.message);
          setErrorMessage(`Error: ${error.message}`);
        }
        
        // This line is removed as we're already setting the error message above
      }
    },
  });

  // Local validation function as fallback for when API is unavailable
  const validateApodoLocally = (apodo) => {
    // Check minimum length
    if (!apodo || apodo.length < 3) {
      return {
        valid: false,
        message: 'El apodo debe tener al menos 3 caracteres'
      };
    }
    
    // Check maximum length
    if (apodo.length > 20) {
      return {
        valid: false,
        message: 'El apodo no puede exceder 20 caracteres'
      };
    }
    
    // Check for invalid characters
    if (!/^[a-zA-Z0-9_-]+$/.test(apodo)) {
      return {
        valid: false,
        message: 'El apodo solo puede contener letras, números, guiones y guiones bajos'
      };
    }

    // Known test user
    if (apodo === 'tortuga_azul_420') {
      return {
        valid: false,
        message: 'Este apodo ya está en uso'
      };
    }
    
    // For any other valid username (in production, this would check the database)
    return {
      valid: true,
      message: 'Apodo disponible'
    };
  };

  const handleApodoBlur = async () => {
    const apodo = formik.values.apodo.trim();
    
    // Reset states when input changes
    setApodoAvailable(false);
    if (!apodo) {
      setApodoError('');
      return;
    }

    try {
      // Run local validation first for immediate feedback
      const localValidation = validateApodoLocally(apodo);
      if (!localValidation.valid) {
        setApodoError(localValidation.message);
        setApodoAvailable(false);
        return;
      }

      // Add a small delay to prevent excessive API calls when typing
      await new Promise(resolve => setTimeout(resolve, 300));
      
      try {
        // Try to check with the server if available
        const response = await axios.get(`${config.apiUrl}/auth/check-apodo`, { 
          params: { apodo },
          timeout: 3000 // Add timeout to prevent long waits
        });
        
        if (!response.data.valid) {
          setApodoError(response.data.message);
          setApodoAvailable(false);
        } else {
          setApodoError('');
          setApodoAvailable(true);
        }
      } catch (apiError) {
        // If API call fails, fall back to local validation
        console.warn('API check failed, using local validation:', apiError);
        
        // If API is down but local validation passed, show available
        if (localValidation.valid) {
          setApodoError('');
          setApodoAvailable(true);
        }
      }
    } catch (error) {
      console.error('Error in apodo validation:', error);
      setApodoError('Error verificando disponibilidad. Intenta con otro apodo.');
      setApodoAvailable(false);
    }
  };

  // Local validation function for email as fallback
  const validateEmailLocally = (email) => {
    // Basic format check
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return {
        valid: false,
        message: 'Formato de correo inválido'
      };
    }

    // Known test email
    if (email === 'tortuga@example.com') {
      return {
        valid: false,
        message: 'Este correo electrónico ya está registrado'
      };
    }
    
    // For any other valid email (in production, this would check the database)
    return {
      valid: true,
      message: 'Correo electrónico disponible'
    };
  };

  const handleEmailBlur = async () => {
    const email = formik.values.email.trim();
    
    // Reset states when input changes
    setEmailAvailable(false);
    if (!email) {
      setEmailError('');
      return;
    }

    try {
      // Local validation first for immediate feedback
      const localValidation = validateEmailLocally(email);
      if (!localValidation.valid) {
        setEmailError(localValidation.message);
        setEmailAvailable(false);
        return;
      }

      // Add a small delay to prevent excessive API calls when typing
      await new Promise(resolve => setTimeout(resolve, 300));
      
      try {
        // Try to check with the server if available
        const response = await axios.get(`${config.apiUrl}/auth/check-email`, { 
          params: { 
            email,
            metodo: 'email' // Explicitly set the method for validation
          },
          timeout: 3000 // Add timeout to prevent long waits
        });
        
        if (!response.data.valid) {
          setEmailError(response.data.message);
          setEmailAvailable(false);
        } else {
          setEmailError('');
          setEmailAvailable(true);
        }
      } catch (apiError) {
        // If API call fails, fall back to local validation
        console.warn('API check failed, using local validation:', apiError);
        
        // If API is down but local validation passed, show available
        if (localValidation.valid) {
          setEmailError('');
          setEmailAvailable(true);
        }
      }
    } catch (error) {
      console.error('Error checking email:', error);
      setEmailError('Error verificando disponibilidad. Intenta con otro correo.');
      setEmailAvailable(false);
    }
  };

  // If registration is successful, redirect immediately to verification page
  useEffect(() => {
    if (registrationSuccess) {
      // Store a flag to indicate that we've just come from registration
      // This will prevent the verification page from requesting a new OTP
      localStorage.setItem('justRegistered', 'true');
      
      // Use React Router navigation instead of window.location to avoid a full page reload
      // This prevents duplicate requests
      navigate('/verify');
    }
  }, [registrationSuccess, navigate]);

  return (
    <Layout>
      <div className="text-center mb-8">
        <h2 className="text-5xl font-mono text-hyc-light">Únete a Honor y Coima</h2>
        <p className="text-hyc-light/80 mt-2">Crea tu cuenta gratis y combate la cutra.</p>
      </div>

      <div className="flex flex-col md:flex-row gap-8">
        {/* Side Card */}
        <Card 
          className="md:w-1/3 bg-gradient-to-br from-hyc-secondary to-hyc-dark border border-hyc-gold/30 rounded-xl shadow-xl"
        >
          <div className="p-2">
            <h3 className="font-mono text-xl text-hyc-gold border-b border-hyc-gold/20 pb-3 mb-6">✅ Beneficios de unirte</h3>
            <ul className="space-y-4">
              <li className="flex items-start">
                <span className="bg-hyc-gold/20 rounded-full p-1 mr-3 mt-1">
                  <svg className="w-4 h-4 text-hyc-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </span>
                <span className="text-hyc-light">Accede a toda la base de datos de denuncias verificadas</span>
              </li>
              <li className="flex items-start">
                <span className="bg-hyc-gold/20 rounded-full p-1 mr-3 mt-1">
                  <svg className="w-4 h-4 text-hyc-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </span>
                <span className="text-hyc-light">Contribuye a verificar denuncias de otros ciudadanos</span>
              </li>
              <li className="flex items-start">
                <span className="bg-hyc-gold/20 rounded-full p-1 mr-3 mt-1">
                  <svg className="w-4 h-4 text-hyc-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </span>
                <span className="text-hyc-light">Crea y gestiona tus propias denuncias</span>
              </li>
              <li className="flex items-start">
                <span className="bg-hyc-gold/20 rounded-full p-1 mr-3 mt-1">
                  <svg className="w-4 h-4 text-hyc-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </span>
                <span className="text-hyc-light">Recibe alertas sobre nuevas denuncias en tu área</span>
              </li>
            </ul>
          
            <div className="flex items-center p-4 bg-hyc-dark/50 rounded-lg mt-8 border border-hyc-light/10">
              <div className="mr-3 bg-hyc-secondary p-2 rounded-full">
                <svg className="w-5 h-5 text-hyc-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                </svg>
              </div>
              <div>
                <p className="text-hyc-light text-sm">Tus datos están seguros y son alojados en servidores fuera del Perú. <a href="#" onClick={(e) => {e.preventDefault(); setIsLightboxOpen(true);}} className="text-hyc-gold hover:underline">Saber más</a></p>
              </div>
            </div>
          </div>
        </Card>

        {/* Form Section */}
        <Card className="md:w-2/3 border border-hyc-light/10 rounded-xl shadow-xl">
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            <div className="flex items-center mb-6 pb-4 border-b border-hyc-light/10">
              <UserIcon className="text-hyc-gold mr-3 w-5 h-5" />
              <span className="text-lg font-mono text-hyc-light">Tus Datos</span>
            </div>
            
            {/* Apodo Input */}
            <div className="relative">
              <FormInput
                name="apodo"
                label="Apodo"
                placeholder=""
                value={formik.values.apodo}
                onChange={(e) => {
                  formik.handleChange(e);
                  setApodoAvailable(false);
                  if (apodoError) setApodoError('');
                }}
                onBlur={handleApodoBlur}
                error={apodoError || (formik.touched.apodo && formik.errors.apodo)}
                required
              />
              {apodoAvailable && (
                <div className="absolute right-3 top-9">
                  <svg className="w-5 h-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </div>
              )}
            </div>

            {/* Email/Mobile Toggle Inputs */}
            <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
              <div className="md:col-span-4">
                {!isMobileLogin ? (
                  <div className="relative">
                    <FormInput
                      name="email"
                      label="Correo Electrónico"
                      type="email"
                      placeholder=""
                      value={formik.values.email}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setEmailAvailable(false);
                        if (emailError) setEmailError('');
                      }}
                      onBlur={handleEmailBlur}
                      error={emailError || (formik.touched.email && formik.errors.email)}
                      required
                      icon={
                        <InfoCircleIcon 
                          className="text-hyc-gold cursor-pointer" 
                          onClick={() => setShowTooltip(!showTooltip)}
                        />
                      }
                    />
                    {emailAvailable && (
                      <div className="absolute right-10 top-9">
                        <svg className="w-5 h-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                      </div>
                    )}
                    {showTooltip && (
                      <div className="absolute right-0 top-16 bg-hyc-secondary border border-hyc-light/20 p-3 rounded-lg z-10 text-hyc-light text-sm w-64 shadow-lg">
                        <div className="flex">
                          <div className="mr-2 mt-1 text-hyc-gold">
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                          </div>
                          <p>Usamos tu correo para enviarte un código de verificación y alertas importantes.</p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <FormInput
                    name="telefono"
                    label="Celular"
                    type="tel"
                    placeholder=""
                    value={formik.values.telefono}
                    onChange={formik.handleChange}
                    error={formik.touched.telefono && formik.errors.telefono}
                    required
                  />
                )}
              </div>
              
              {/* Mobile registration toggle disabled for now 
              <div className="md:col-span-1 flex flex-col">
                <label className="block mb-2 text-hyc-light invisible">
                  &nbsp;
                </label>
                <Button 
                  type="button" 
                  variant="outline"
                  onClick={() => { 
                    setIsMobileLogin(!isMobileLogin); 
                    formik.setFieldValue('isMobileLogin', !isMobileLogin); 
                  }}
                  className="w-full h-[42px]"
                  icon={<SwitchIcon className="mr-1" />}
                >
                  {isMobileLogin ? 'Usar Correo' : 'Usar Celular'}
                </Button>
              </div>
              */}
            </div>

            {/* Ubigeo Dropdowns Section */}
            <div className="mt-8">
              <UbigeoDropdowns 
                onChange={(departamento, provincia, distrito) => {
                  console.log('UbigeoDropdowns onChange:', {departamento, provincia, distrito});
                  
                  // Make sure we always have values
                  const dep = departamento || 'Lima';
                  const prov = provincia || 'Lima';
                  const dist = distrito || 'Miraflores';
                  
                  formik.setFieldValue('departamento', dep);
                  formik.setFieldValue('provincia', prov);
                  formik.setFieldValue('distrito', dist);
                  
                  console.log('Updated form values:', {
                    departamento: formik.values.departamento,
                    provincia: formik.values.provincia,
                    distrito: formik.values.distrito
                  });
                }} 
              />
              
              {/* Ubigeo Errors */}
              <div className="grid grid-cols-3 gap-4 mt-1">
                <div>
                  {formik.touched.departamento && formik.errors.departamento && (
                    <p className="text-hyc-accent text-sm">{formik.errors.departamento}</p>
                  )}
                </div>
                <div>
                  {formik.touched.provincia && formik.errors.provincia && (
                    <p className="text-hyc-accent text-sm">{formik.errors.provincia}</p>
                  )}
                </div>
                <div>
                  {formik.touched.distrito && formik.errors.distrito && (
                    <p className="text-hyc-accent text-sm">{formik.errors.distrito}</p>
                  )}
                </div>
              </div>
            </div>

            {/* Terms and Conditions */}
            <div className="mt-4 border-t border-hyc-light/10 pt-6">
              <div className="flex items-start mb-4">
                <div className="flex items-center h-5">
                  <input
                    id="aceptar_terminos"
                    name="aceptar_terminos"
                    type="checkbox"
                    className="w-4 h-4 border border-hyc-light rounded bg-hyc-dark focus:ring-hyc-gold"
                    checked={formik.values.aceptar_terminos}
                    onChange={formik.handleChange}
                    required
                  />
                </div>
                <label htmlFor="aceptar_terminos" className="ml-2 text-sm text-hyc-light">
                  Acepto los <Link to="/terminos" target="_blank" rel="noopener noreferrer" className="text-hyc-gold hover:underline">términos y condiciones</Link>, incluyendo el envío de comunicaciones para verificación
                </label>
              </div>
            </div>

            {/* Submit Button */}
            <div className="mt-6">
              <Button 
                type="submit" 
                size="lg" 
                fullWidth
                className="py-4 text-lg font-medium shadow-lg transition-transform transform hover:translate-y-[-2px]"
              >
                Crear Mi Cuenta
              </Button>
              
              <p className="text-center mt-4 text-sm text-hyc-light">
                ¿Ya tienes una cuenta? <Link to="/login" onClick={(e) => { e.preventDefault(); navigate('/login'); }} className="text-hyc-gold hover:underline">Inicia sesión aquí</Link>
              </p>
            </div>
          </form>

          {/* Error Message */}
          {errorMessage && (
            <div className="mt-6 p-4 bg-red-900/30 border border-red-500 rounded-lg text-red-300 text-sm flex items-center">
              <svg className="w-5 h-5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              <span>{errorMessage}</span>
            </div>
          )}
        </Card>
      </div>

      {/* Lightbox for security info */}
      {isLightboxOpen && (
        <Lightbox onClose={() => setIsLightboxOpen(false)}>
          <div className="p-6">
            <div className="flex items-center mb-6">
              <LockIcon className="text-hyc-gold w-6 h-6 mr-3" />
              <h2 className="text-2xl font-mono text-hyc-light">Seguridad de tus datos</h2>
            </div>
            
            <p className="text-hyc-light mb-4">
              En Honor y Coima tomamos muy en serio la seguridad de tus datos personales.
            </p>
            
            <div className="space-y-4 mb-6">
              <div className="flex items-start">
                <div className="bg-hyc-gold/20 rounded-full p-1 mr-3 mt-1">
                  <svg className="w-4 h-4 text-hyc-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </div>
                <p className="text-hyc-light">Todos tus datos son <strong>cifrados</strong> utilizando algoritmos de encriptación avanzados</p>
              </div>
              
              <div className="flex items-start">
                <div className="bg-hyc-gold/20 rounded-full p-1 mr-3 mt-1">
                  <svg className="w-4 h-4 text-hyc-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </div>
                <p className="text-hyc-light">Nuestros servidores están ubicados <strong>fuera del Perú</strong> en jurisdicciones con fuertes leyes de protección de datos</p>
              </div>
              
              <div className="flex items-start">
                <div className="bg-hyc-gold/20 rounded-full p-1 mr-3 mt-1">
                  <svg className="w-4 h-4 text-hyc-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </div>
                <p className="text-hyc-light">Utilizamos <strong>autenticación de dos factores</strong> para proteger tu cuenta</p>
              </div>
              
              <div className="flex items-start">
                <div className="bg-hyc-gold/20 rounded-full p-1 mr-3 mt-1">
                  <svg className="w-4 h-4 text-hyc-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </div>
                <p className="text-hyc-light">No compartimos tus datos con terceros sin tu consentimiento explícito</p>
              </div>
            </div>
            
            <Button onClick={() => setIsLightboxOpen(false)} variant="secondary" className="mt-4">
              Entendido
            </Button>
          </div>
        </Lightbox>
      )}
    </Layout>
  );
};

export default UserRegistrationForm;