import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { 
  UserIcon, 
  MapIcon, 
  SearchIcon, 
  XIcon
} from './Icons';

// Custom Logout Icon
const LogoutIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={props.className} fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
  </svg>
);

// Custom MenuIcon since it's not defined in Icons.js
const HamburgerIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={props.className} fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
  </svg>
);

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userMenu, setUserMenu] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();

  // Check if user is authenticated on component mount and when localStorage changes
  useEffect(() => {
    const checkAuth = () => {
      // Check for auth tokens (multiple formats supported for backward compatibility)
      const authToken = localStorage.getItem('authToken');
      const authTokensStr = localStorage.getItem('authTokens');
      const userJson = localStorage.getItem('user');
      
      let authenticated = false;
      
      // Add immediate debug
      console.log('[AUTH DEBUG] Checking auth status:', { 
        hasAuthToken: !!authToken, 
        hasAuthTokens: !!authTokensStr,
        hasUserJson: !!userJson 
      });
      
      // Check auth tokens and verify they're not expired
      if (authTokensStr) {
        try {
          const authTokens = JSON.parse(authTokensStr);
          console.log('[AUTH DEBUG] Parsed authTokens:', authTokens);
          
          // Check if tokens include expiration time
          if (authTokens.expiresAt) {
            // Compare with current time
            const now = Date.now();
            const expiresAt = authTokens.expiresAt;
            console.log('[AUTH DEBUG] Token expiration check:', { now, expiresAt, valid: now < expiresAt });
            
            if (now < expiresAt) {
              authenticated = true;
            } else {
              // Auth tokens have expired - clear them
              localStorage.removeItem('authTokens');
            }
          } else {
            // Fallback if no expiration is stored
            authenticated = true;
          }
        } catch (e) {
          console.error('Error parsing auth tokens:', e);
        }
      } else if (authToken && userJson) {
        // Fallback to legacy auth token (need both token and user data)
        console.log('[AUTH DEBUG] Using legacy auth token');
        authenticated = true;
      }
      
      setIsAuthenticated(authenticated);
      
      // Try to get user info
      if (authenticated && userJson) {
        try {
          const user = JSON.parse(userJson);
          setUserInfo(user);
        } catch (e) {
          console.error('Error parsing user info:', e);
        }
      } else if (!authenticated) {
        setUserInfo(null);
        setUserMenu(false);
      }
    };
    
    // Initial check
    checkAuth();
    
    // Set up event listeners for auth changes
    // Storage event for cross-tab sync
    const handleStorageChange = () => checkAuth();
    window.addEventListener('storage', handleStorageChange);
    
    // Custom event for explicit auth status changes (logout, etc.)
    const handleAuthChange = () => checkAuth();
    window.addEventListener('auth-status-changed', handleAuthChange);
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('auth-status-changed', handleAuthChange);
    };
  }, []);
  
  const toggleMenu = () => {
    setIsOpen(!isOpen);
    // Close user menu if main menu is toggled
    if (userMenu) setUserMenu(false);
  };
  
  const toggleUserMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setUserMenu(!userMenu);
  };
  
  const handleLogout = async () => {
    try {
      // Try to call the logout API if available
      const authToken = localStorage.getItem('authToken');
      const authTokensStr = localStorage.getItem('authTokens');
      
      if (authTokensStr) {
        try {
          const tokens = JSON.parse(authTokensStr);
          const accessToken = tokens.accessToken || tokens.token || authToken;
          
          if (accessToken) {
            // Call logout endpoint to invalidate the token on the server side
            try {
              await axios.post(`${config.apiUrl}/auth/otp/logout`, {}, {
                headers: { Authorization: `Bearer ${accessToken}` }
              });
              // Server-side logout successful
            } catch (apiError) {
              // Continue with client-side logout even if server logout fails
              // Server-side logout failed, continuing with client-side logout
            }
          }
        } catch (e) {
          console.error('Error parsing tokens:', e);
        }
      } else if (authToken) {
        // Try logout with legacy token
        try {
          await axios.post(`${config.apiUrl}/auth/otp/logout`, {}, {
            headers: { Authorization: `Bearer ${authToken}` }
          });
          // Server-side logout successful with legacy token
        } catch (apiError) {
          // Server-side logout failed with legacy token
        }
      }
    } catch (error) {
      console.error('Logout API call failed:', error);
      // Continue with local logout even if API call fails
    } finally {
      // Clear ALL authentication data from localStorage
      const authKeys = [
        'authToken', 
        'authTokens',
        'user', 
        'contactMethod',
        'pendingVerification',
        'registrationUserId',
        'userEmail',
        'userTelefono',
        'pendingRegistration', 
        'authUsername'
      ];
      
      // Remove all auth-related keys
      authKeys.forEach(key => localStorage.removeItem(key));
      
      // Update component state
      setIsAuthenticated(false);
      setUserInfo(null);
      setUserMenu(false);
      
      // Create event to notify other components about logout
      window.dispatchEvent(new Event('auth-status-changed'));
      
      // Redirect to home page with a full page reload
      window.location.href = '/';
    }
  };

  return (
    <header className="bg-hyc-dark border-b border-hyc-light/10">
      <div className="container mx-auto px-4 py-3 md:py-4">
        <div className="flex justify-between items-center">
          {/* Logo and Site Title */}
          <Link to="/" className="flex items-center">
            <div className="font-mono text-xl md:text-2xl">
              <span className="text-hyc-gold">Honor</span>
              <span className="text-hyc-light">y</span>
              <span className="text-hyc-accent">Coima</span>
              <span className="text-hyc-light">.org</span>
            </div>
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-8">
            <Link to="/explorar" className="text-hyc-light hover:text-hyc-gold transition-colors duration-200 flex items-center">
              <SearchIcon className="mr-2" />
              <span>Explorar Denuncias</span>
            </Link>
            <Link to="/cercademi" className="text-hyc-light hover:text-hyc-gold transition-colors duration-200 flex items-center">
              <MapIcon className="mr-2" />
              <span>Cerca de Mi</span>
            </Link>
            <Link 
              to="/denunciaform" 
              onClick={(e) => {
                // Prevent default and use navigate to ensure proper routing
                e.preventDefault();
                navigate('/denunciaform');
              }}
              className="bg-hyc-accent hover:bg-hyc-accent/80 text-white font-bold py-2 px-4 rounded-lg transition-colors duration-200"
            >
              Enviar Denuncia
            </Link>
            {isAuthenticated ? (
              <div className="relative">
                <button 
                  onClick={toggleUserMenu} 
                  className="text-hyc-light hover:text-hyc-gold transition-colors duration-200 flex items-center"
                >
                  <UserIcon className="mr-2" />
                  <span>{userInfo?.nickname || userInfo?.apodo || 'Usuario'}</span>
                  <svg className="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={userMenu ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"} />
                  </svg>
                </button>
                
                {/* User dropdown menu */}
                {userMenu && (
                  <div className="absolute right-0 mt-2 w-48 bg-hyc-secondary border border-hyc-light/10 rounded-md shadow-lg z-50 py-1">
                    <div className="px-4 py-2 border-b border-hyc-light/10">
                      <p className="text-hyc-gold font-medium truncate">{userInfo?.nickname || userInfo?.apodo || 'Usuario'}</p>
                      <p className="text-hyc-light/70 text-sm truncate">{userInfo?.email || userInfo?.telefono || ''}</p>
                    </div>
                    <Link to="/perfil" className="block px-4 py-2 text-hyc-light hover:bg-hyc-dark/50 hover:text-hyc-gold">
                      Mi Perfil
                    </Link>
                    <Link to="/mis-denuncias" className="block px-4 py-2 text-hyc-light hover:bg-hyc-dark/50 hover:text-hyc-gold">
                      Mis Denuncias
                    </Link>
                    <button 
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleLogout();
                      }}
                      type="button"
                      className="w-full text-left px-4 py-2 text-hyc-accent hover:bg-hyc-dark/50 flex items-center"
                    >
                      <LogoutIcon className="w-4 h-4 mr-2" />
                      Cerrar Sesión
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <Link 
                to="/login" 
                onClick={(e) => {
                  // Prevent default and use navigate to ensure proper routing
                  e.preventDefault();
                  navigate('/login');
                }}
                className="text-hyc-light hover:text-hyc-gold transition-colors duration-200 flex items-center"
              >
                <UserIcon className="mr-2" />
                <span>Iniciar Sesión</span>
              </Link>
            )}
          </nav>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button 
              onClick={toggleMenu}
              className="text-hyc-light hover:text-hyc-gold focus:outline-none"
            >
              {isOpen ? (
                <XIcon className="h-6 w-6" />
              ) : (
                <div className="h-6 w-6">
                  <HamburgerIcon className="h-6 w-6" />
                </div>
              )}
            </button>
          </div>
        </div>

        {/* Mobile Navigation Menu */}
        {isOpen && (
          <div className="md:hidden mt-3 py-3 border-t border-hyc-light/10">
            <div className="flex flex-col space-y-3">
              <Link 
                to="/explorar" 
                className="text-hyc-light hover:text-hyc-gold transition-colors duration-200 flex items-center"
                onClick={() => setIsOpen(false)}
              >
                <SearchIcon className="mr-2" />
                <span>Explorar Denuncias</span>
              </Link>
              <Link 
                to="/cercademi" 
                className="text-hyc-light hover:text-hyc-gold transition-colors duration-200 flex items-center"
                onClick={() => setIsOpen(false)}
              >
                <MapIcon className="mr-2" />
                <span>Cerca de Mi</span>
              </Link>
              <Link 
                to="/denunciaform" 
                className="bg-hyc-accent hover:bg-hyc-accent/80 text-white font-bold py-2 px-4 rounded-lg transition-colors duration-200 inline-block"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(false);
                  navigate('/denunciaform');
                }}
              >
                Enviar Denuncia
              </Link>
              
              {isAuthenticated ? (
                <>
                  <div className="border-t border-hyc-light/10 pt-3 mt-3">
                    <div className="flex items-center text-hyc-gold mb-2">
                      <UserIcon className="mr-2" />
                      <span className="font-medium">{userInfo?.nickname || userInfo?.apodo || 'Usuario'}</span>
                    </div>
                    <div className="text-hyc-light/70 text-sm mb-3 pl-7">
                      {userInfo?.email || userInfo?.telefono || ''}
                    </div>
                    
                    <Link 
                      to="/perfil" 
                      className="text-hyc-light hover:text-hyc-gold transition-colors duration-200 flex items-center pl-7 py-2"
                      onClick={() => setIsOpen(false)}
                    >
                      Mi Perfil
                    </Link>
                    <Link 
                      to="/mis-denuncias" 
                      className="text-hyc-light hover:text-hyc-gold transition-colors duration-200 flex items-center pl-7 py-2"
                      onClick={() => setIsOpen(false)}
                    >
                      Mis Denuncias
                    </Link>
                    <button 
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleLogout();
                        setIsOpen(false);
                      }}
                      type="button"
                      className="w-full text-left pl-7 py-2 text-hyc-accent flex items-center"
                    >
                      <LogoutIcon className="w-4 h-4 mr-2" />
                      Cerrar Sesión
                    </button>
                  </div>
                </>
              ) : (
                <Link 
                  to="/login" 
                  className="text-hyc-light hover:text-hyc-gold transition-colors duration-200 flex items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(false);
                    navigate('/login');
                  }}
                >
                  <UserIcon className="mr-2" />
                  <span>Iniciar Sesión</span>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;