import React from 'react';
import Layout from '../Layout';

const TerminosCondiciones = () => {
  return (
    <Layout
      title="Términos y Condiciones"
      subtitle="Información legal sobre el uso de la plataforma HonoryCoima.org"
      titleSize="text-2xl"
    >
      <div className="max-w-4xl mx-auto bg-hyc-secondary p-6 rounded-lg shadow-md">
        <div className="prose prose-invert max-w-none">
          <h2 className="text-hyc-gold text-xl font-bold mb-4">TÉRMINOS Y CONDICIONES DE USO DE HONORYCOIMA.ORG</h2>
          
          <p className="text-hyc-light mb-4">
            Última actualización: 15 de marzo de 2025
          </p>
          
          <div className="mb-6">
            <h3 className="text-hyc-gold font-bold mb-2">1. ACEPTACIÓN DE LOS TÉRMINOS</h3>
            <p className="text-hyc-light mb-4">
              Al acceder o utilizar la plataforma HonoryCoima.org (en adelante, "la Plataforma"), usted acepta cumplir y quedar obligado por los presentes Términos y Condiciones de Uso. Si no está de acuerdo con estos términos, por favor no utilice la Plataforma.
            </p>
            <p className="text-hyc-light mb-4">
              HonoryCoima.org se reserva el derecho de modificar estos términos en cualquier momento. Las modificaciones entrarán en vigor inmediatamente después de su publicación en la Plataforma. El uso continuado de la Plataforma después de cualquier modificación constituirá su aceptación de los términos modificados.
            </p>
          </div>
          
          <div className="mb-6">
            <h3 className="text-hyc-gold font-bold mb-2">2. DESCRIPCIÓN DEL SERVICIO</h3>
            <p className="text-hyc-light mb-4">
              HonoryCoima.org es una plataforma ciudadana que permite a los usuarios denunciar actos de corrupción y malas prácticas de funcionarios públicos, específicamente de la Policía Nacional del Perú (PNP), con el objetivo de crear conciencia y promover la transparencia.
            </p>
          </div>
          
          <div className="mb-6">
            <h3 className="text-hyc-gold font-bold mb-2">3. REGISTRO Y AUTENTICACIÓN DE USUARIOS</h3>
            <p className="text-hyc-light mb-4">
              Para utilizar ciertas funciones de la Plataforma, es necesario registrarse proporcionando información personal como correo electrónico y/o número de teléfono. Esta información se utiliza exclusivamente para los siguientes fines:
            </p>
            <ul className="list-disc list-inside text-hyc-light mb-4 ml-4">
              <li>Verificar su identidad durante el registro</li>
              <li>Enviar códigos de verificación (OTP) para acceder a su cuenta</li>
              <li>Notificarle sobre actividades relacionadas con sus denuncias</li>
              <li>Comunicarle cambios importantes en la plataforma o en sus términos de uso</li>
            </ul>
            <p className="text-hyc-light mb-4">
              <strong>Consentimiento expreso para comunicaciones:</strong> Al proporcionar su correo electrónico y/o número de teléfono, usted otorga su consentimiento expreso para recibir mensajes de texto (SMS) y/o correos electrónicos con códigos de verificación necesarios para la autenticación de su cuenta. Estos mensajes se enviarán únicamente con fines de seguridad y nunca con propósitos promocionales o comerciales.
            </p>
            <p className="text-hyc-light mb-4">
              Usted puede revocar este consentimiento en cualquier momento solicitando la eliminación de su cuenta, lo que resultará en la eliminación de su información de contacto de nuestros sistemas.
            </p>
          </div>
          
          <div className="mb-6">
            <h3 className="text-hyc-gold font-bold mb-2">4. PRIVACIDAD Y PROTECCIÓN DE DATOS</h3>
            <p className="text-hyc-light mb-4">
              <strong>Confidencialidad:</strong> HonoryCoima.org se compromete a no compartir, vender, intercambiar ni transferir su información personal identificable (como correo electrónico, número de teléfono o datos de localización) a terceros o entidades gubernamentales, salvo que sea requerido por ley mediante una orden judicial.
            </p>
            <p className="text-hyc-light mb-4">
              <strong>Anonimato en denuncias:</strong> Las denuncias publicadas en la plataforma no revelarán su identidad real. Solo se mostrará el apodo o nombre de usuario que usted haya elegido.
            </p>
            <p className="text-hyc-light mb-4">
              <strong>Seguridad de la información:</strong> Implementamos medidas de seguridad técnicas, administrativas y físicas diseñadas para proteger sus datos personales contra el acceso no autorizado, la alteración, la divulgación o la destrucción.
            </p>
            <p className="text-hyc-light mb-4">
              <strong>Almacenamiento de datos:</strong> Sus datos personales se almacenan en servidores seguros ubicados fuera del territorio peruano para garantizar mayor protección contra intentos de acceso no autorizado.
            </p>
          </div>
          
          <div className="mb-6">
            <h3 className="text-hyc-gold font-bold mb-2">5. ENVÍO DE COMUNICACIONES ELECTRÓNICAS</h3>
            <p className="text-hyc-light mb-4">
              <strong>Comunicaciones relacionadas con el servicio:</strong> HonoryCoima.org enviará comunicaciones electrónicas (por correo electrónico o SMS) únicamente para fines esenciales del funcionamiento de la plataforma, que incluyen:
            </p>
            <ul className="list-disc list-inside text-hyc-light mb-4 ml-4">
              <li>Códigos de verificación para inicio de sesión</li>
              <li>Confirmación de registro</li>
              <li>Notificaciones sobre el estado de sus denuncias</li>
              <li>Alertas de seguridad relevantes para su cuenta</li>
              <li>Cambios importantes en nuestros términos y condiciones</li>
            </ul>
            <p className="text-hyc-light mb-4">
              <strong>Frecuencia:</strong> La frecuencia de estas comunicaciones será limitada y proporcional al uso que haga de la plataforma. Los códigos de verificación se enviarán únicamente cuando intente iniciar sesión o realizar acciones que requieran verificación adicional.
            </p>
            <p className="text-hyc-light mb-4">
              <strong>Cancelación:</strong> Cada comunicación electrónica incluirá instrucciones sobre cómo dejar de recibir futuros mensajes. Además, puede solicitar la desactivación de estas notificaciones en cualquier momento contactando a nuestro equipo de soporte a través de hola@honorycoima.org.
            </p>
          </div>
          
          <div className="mb-6">
            <h3 className="text-hyc-gold font-bold mb-2">6. NORMAS DE CONDUCTA DEL USUARIO</h3>
            <p className="text-hyc-light mb-4">
              Al utilizar la Plataforma, usted se compromete a:
            </p>
            <ul className="list-disc list-inside text-hyc-light mb-4 ml-4">
              <li>Proporcionar información verdadera y verificable en sus denuncias</li>
              <li>No utilizar la Plataforma para difamar, acosar, amenazar o intimidar a otros</li>
              <li>No publicar contenido discriminatorio, obsceno, difamatorio o ilegal</li>
              <li>No suplantar la identidad de otra persona o entidad</li>
              <li>No intentar acceder a cuentas o información de otros usuarios</li>
              <li>No utilizar la Plataforma para actividades ilegales o fraudulentas</li>
            </ul>
            <p className="text-hyc-light mb-4">
              HonoryCoima.org se reserva el derecho de eliminar cualquier contenido que viole estas normas y de suspender o terminar cuentas que incumplan repetidamente estos términos.
            </p>
          </div>
          
          <div className="mb-6">
            <h3 className="text-hyc-gold font-bold mb-2">7. LIMITACIÓN DE RESPONSABILIDAD</h3>
            <p className="text-hyc-light mb-4">
              HonoryCoima.org no se hace responsable de la veracidad, exactitud o legalidad del contenido publicado por los usuarios. La plataforma actúa únicamente como medio para compartir información y no verifica ni investiga las denuncias presentadas.
            </p>
            <p className="text-hyc-light mb-4">
              Los usuarios son los únicos responsables de las denuncias que publican y de cualquier consecuencia legal que pueda derivarse de ellas. HonoryCoima.org no ofrece asesoramiento legal y no reemplaza los canales oficiales de denuncia establecidos por las autoridades competentes.
            </p>
          </div>
          
          <div className="mb-6">
            <h3 className="text-hyc-gold font-bold mb-2">8. PROPIEDAD INTELECTUAL</h3>
            <p className="text-hyc-light mb-4">
              Todo el contenido generado por la Plataforma, incluyendo pero no limitado a textos, gráficos, logotipos, iconos, imágenes, clips de audio, descargas digitales y compilaciones de datos, es propiedad de HonoryCoima.org o de sus proveedores de contenido y está protegido por las leyes peruanas e internacionales de propiedad intelectual.
            </p>
            <p className="text-hyc-light mb-4">
              Al publicar contenido en la Plataforma, usted conserva todos los derechos de propiedad sobre dicho contenido, pero otorga a HonoryCoima.org una licencia mundial, no exclusiva, libre de regalías para usar, reproducir, adaptar, publicar, traducir y distribuir dicho contenido en cualquier medio.
            </p>
          </div>
          
          <div className="mb-6">
            <h3 className="text-hyc-gold font-bold mb-2">9. LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h3>
            <p className="text-hyc-light mb-4">
              Estos Términos y Condiciones se regirán e interpretarán de acuerdo con las leyes de la República del Perú. Cualquier disputa que surja en relación con estos términos o con el uso de la Plataforma estará sujeta a la jurisdicción exclusiva de los tribunales de Lima, Perú.
            </p>
          </div>
          
          <div className="mb-6">
            <h3 className="text-hyc-gold font-bold mb-2">10. MODIFICACIONES DEL SERVICIO</h3>
            <p className="text-hyc-light mb-4">
              HonoryCoima.org se reserva el derecho de modificar o discontinuar, temporal o permanentemente, la Plataforma o cualquier servicio relacionado, con o sin previo aviso y sin responsabilidad hacia usted o terceros.
            </p>
          </div>
          
          <div className="mb-6">
            <h3 className="text-hyc-gold font-bold mb-2">11. CONTACTO</h3>
            <p className="text-hyc-light mb-4">
              Si tiene preguntas o inquietudes sobre estos Términos y Condiciones, puede contactarnos a través de:
            </p>
            <p className="text-hyc-light mb-4">
              Correo electrónico: <a href="mailto:hola@honorycoima.org" className="text-hyc-gold">hola@honorycoima.org</a>
            </p>
          </div>
          
          <div className="border-t border-hyc-light/10 pt-4 mt-6">
            <p className="text-hyc-light text-sm">
              Al utilizar HonoryCoima.org, usted reconoce que ha leído, entendido y aceptado estos Términos y Condiciones.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TerminosCondiciones;