// src/components/RouteGuard.js
import React, { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import config from '../config';
import { RefreshIcon } from './Icons';

const RouteGuard = ({ children }) => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  
  // Public routes that should be accessible even when not logged in
  const publicRoutes = [
    '/login',
    '/registro',
    '/verify',
    '/verify-login',
    '/denunciaform',
    '/auth-status',
    '/terminos',
    '/explorar',
    '/cercademi',
    '/'
  ];
  
  // Check if current path is in public routes
  const isPublicRoute = publicRoutes.some(route => 
    location.pathname === route || 
    (route.endsWith('*') && location.pathname.startsWith(route.slice(0, -1)))
  );
  
  // Check authentication status
  useEffect(() => {
    const checkAuth = () => {
      // For public routes, we don't need to delay rendering
      if (isPublicRoute) {
        setIsLoading(false);
        return;
      }
      
      // Check for authTokens in localStorage
      const authTokensStr = localStorage.getItem('authTokens');
      const authToken = localStorage.getItem('authToken');
      const localUser = localStorage.getItem('user');
      
      let authenticated = false;
      
      // Check if we have valid auth tokens
      if (authTokensStr) {
        try {
          const authTokens = JSON.parse(authTokensStr);
          // Check for token expiration if available
          if (authTokens.expiresAt) {
            const now = Date.now();
            if (now < authTokens.expiresAt) {
              authenticated = true;
            }
          } else {
            // No expiration found, assume valid
            authenticated = true;
          }
        } catch (e) {
          console.error('Error parsing auth tokens:', e);
        }
      } 
      // Fallback to legacy authToken
      else if (authToken && localUser) {
        authenticated = true;
      }
      
      setIsAuthenticated(authenticated);
      setIsLoading(false);
    };
    
    checkAuth();
    
    // Listen for auth change events
    const handleAuthChange = () => checkAuth();
    window.addEventListener('auth-status-changed', handleAuthChange);
    
    return () => {
      window.removeEventListener('auth-status-changed', handleAuthChange);
    };
  }, [isPublicRoute, location.pathname]);
  
  // If loading, show loading indicator
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-hyc-dark">
        <div className="text-center">
          <RefreshIcon className="animate-spin text-hyc-gold text-4xl mx-auto mb-4" />
          <p className="text-hyc-light">Verificando acceso...</p>
        </div>
      </div>
    );
  }
  
  // Public routes are always accessible
  if (isPublicRoute) {
    return children;
  }
  
  // For non-public routes, check authentication
  return isAuthenticated ? children : <Navigate to="/login" state={{ from: location }} />;
};

export default RouteGuard;
