import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { RefreshIcon } from './Icons';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8081';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuthStatus = () => {
      try {
        // First check for authTokens in localStorage (primary auth method)
        const authTokensStr = localStorage.getItem('authTokens');
        const authToken = localStorage.getItem('authToken');
        const localUser = localStorage.getItem('user');
        
        // Check if we have valid auth tokens
        if (authTokensStr) {
          try {
            const authTokens = JSON.parse(authTokensStr);
            // Check for token expiration if available
            if (authTokens.expiresAt) {
              const now = Date.now();
              if (now < authTokens.expiresAt) {
                // Valid auth tokens found
                setIsAuthenticated(true);
                setIsLoading(false);
                return;
              } else {
                // Auth tokens expired
              }
            } else {
              // No expiration found, assume valid
              setIsAuthenticated(true);
              setIsLoading(false);
              return;
            }
          } catch (e) {
            console.error('Error parsing auth tokens:', e);
          }
        } 
        // Fallback to legacy authToken
        else if (authToken && localUser) {
          // Legacy auth token found
          setIsAuthenticated(true);
          setIsLoading(false);
          return;
        }
        // User data without token check
        else if (localUser) {
          // User data found, searching for auth tokens
          try {
            // Double check session with backend
            const response = fetch(`${API_URL}/api/auth/otp/verify-session`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${authToken || ''}`
              }
            }).then(res => {
              if (res.ok) {
                // Session verified with backend
                setIsAuthenticated(true);
              } else {
                // Session validation failed - clearing user data
                localStorage.removeItem('user');
                setIsAuthenticated(false);
              }
              setIsLoading(false);
            }).catch(err => {
              console.error('Session verification error:', err);
              setIsAuthenticated(false);
              setIsLoading(false);
            });
            
            return; // Return here as we're handling state in the promises
          } catch (error) {
            console.error('Session verification error:', error);
          }
        }

        // If we get here, no valid auth was found
        setIsAuthenticated(false);
        setIsLoading(false);
      } catch (error) {
        console.error('Auth check error:', error);
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    };

    // Add listener for auth status changes
    const handleAuthChange = () => {
      // Auth status changed event detected
      checkAuthStatus();
    };
    
    window.addEventListener('auth-status-changed', handleAuthChange);
    checkAuthStatus();
    
    return () => {
      window.removeEventListener('auth-status-changed', handleAuthChange);
    };
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-hyc-dark">
        <div className="text-center">
          <RefreshIcon className="animate-spin text-hyc-gold text-4xl mx-auto mb-4" />
          <p className="text-hyc-light">Verificando autenticación...</p>
        </div>
      </div>
    );
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;