import React, { useState } from 'react';

/**
 * Reusable table component with consistent styling and sorting
 * @param {Object} props - Component props
 * @param {Array} props.columns - Column definitions [{key, header, render, sortable}]
 * @param {Array} props.data - Data to display
 * @param {string} [props.className=''] - Additional class names
 * @param {boolean} [props.striped=true] - Whether to use striped rows
 * @param {boolean} [props.bordered=false] - Whether to add borders
 * @param {boolean} [props.hoverable=true] - Whether rows should have hover effect
 * @param {function} [props.onRowClick=null] - Row click handler
 * @param {string} [props.defaultSortKey='created_at'] - Default column to sort by
 * @param {string} [props.defaultSortOrder='desc'] - Default sort order ('asc' or 'desc')
 */
const Table = ({
  columns = [],
  data = [],
  className = '',
  striped = true,
  bordered = false,
  hoverable = true,
  onRowClick = null,
  defaultSortKey = 'created_at',
  defaultSortOrder = 'desc',
  ...rest
}) => {
  // State for sorting
  const [sortConfig, setSortConfig] = useState({
    key: defaultSortKey,
    direction: defaultSortOrder
  });

  // Base styles
  const tableStyles = "min-w-full divide-y divide-hyc-light/10";
  
  // Border styles
  const borderStyles = bordered ? "border border-hyc-light/20" : "";
  
  // Combine styles
  const combinedTableStyles = `${tableStyles} ${borderStyles} ${className}`;
  
  // Row styles
  const getRowStyles = (index) => {
    const baseRowStyles = "transition-colors duration-150";
    const stripedStyles = striped && index % 2 === 1 ? "bg-hyc-light/5" : "";
    const hoverStyles = hoverable ? "hover:bg-hyc-light/10" : "";
    const cursorStyles = onRowClick ? "cursor-pointer" : "";
    
    return `${baseRowStyles} ${stripedStyles} ${hoverStyles} ${cursorStyles}`;
  };

  // Sort data with debugging
  const sortedData = React.useMemo(() => {
    console.log(`Table: Sorting ${data.length} items by ${sortConfig.key} (${sortConfig.direction})`);
    const sortStart = performance.now();
    
    // Create a copy of the data to sort
    let sortableData = [...data];
    
    if (sortConfig.key) {
      try {
        sortableData.sort((a, b) => {
          // Validate inputs
          if (!a || !b) {
            console.warn('Table sort: Received null/undefined item during sort');
            return 0;
          }
          
          // Handle null or undefined values
          if (a[sortConfig.key] == null) return sortConfig.direction === 'asc' ? -1 : 1;
          if (b[sortConfig.key] == null) return sortConfig.direction === 'asc' ? 1 : -1;
          
          // Get values to compare
          const aValue = a[sortConfig.key];
          const bValue = b[sortConfig.key];
          
          // Compare dates
          if (aValue instanceof Date && bValue instanceof Date) {
            return sortConfig.direction === 'asc' 
              ? aValue.getTime() - bValue.getTime()
              : bValue.getTime() - aValue.getTime();
          }
          
          // Try to convert to dates if the values are strings that look like dates
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            const aDate = new Date(aValue);
            const bDate = new Date(bValue);
            if (!isNaN(aDate) && !isNaN(bDate)) {
              return sortConfig.direction === 'asc' 
                ? aDate.getTime() - bDate.getTime()
                : bDate.getTime() - aDate.getTime();
            }
          }
          
          // Handle nested object sorting (for populated fields like denunciado)
          if (typeof aValue === 'object' && aValue !== null && 
              typeof bValue === 'object' && bValue !== null) {
            // Check for denunciados array first (new schema)
            if (Array.isArray(aValue) && Array.isArray(bValue)) {
              // Compare first denunciado in each array if they exist
              const aFirstDenunciado = aValue.length > 0 ? aValue[0] : null;
              const bFirstDenunciado = bValue.length > 0 ? bValue[0] : null;
              
              // Handle empty arrays
              if (!aFirstDenunciado && !bFirstDenunciado) return 0;
              if (!aFirstDenunciado) return sortConfig.direction === 'asc' ? -1 : 1;
              if (!bFirstDenunciado) return sortConfig.direction === 'asc' ? 1 : -1;
              
              // Extract names for comparison
              const aName = typeof aFirstDenunciado === 'object' && aFirstDenunciado.nombre 
                ? aFirstDenunciado.nombre 
                : String(aFirstDenunciado);
              const bName = typeof bFirstDenunciado === 'object' && bFirstDenunciado.nombre 
                ? bFirstDenunciado.nombre 
                : String(bFirstDenunciado);
              
              return sortConfig.direction === 'asc'
                ? aName.localeCompare(bName)
                : bName.localeCompare(aName);
            }
            // Check for denunciado.nombre (legacy schema)
            else if (aValue.nombre && bValue.nombre) {
              return sortConfig.direction === 'asc'
                ? aValue.nombre.localeCompare(bValue.nombre)
                : bValue.nombre.localeCompare(aValue.nombre);
            } else {
              console.warn('Table sort: Unable to compare nested objects:', aValue, bValue);
              return 0;
            }
          }
          
          // Handle numbers
          if (typeof aValue === 'number' && typeof bValue === 'number') {
            return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
          }
          
          // Handle strings
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sortConfig.direction === 'asc'
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          }
          
          // Default comparison
          return sortConfig.direction === 'asc' 
            ? (aValue > bValue ? 1 : -1)
            : (bValue > aValue ? 1 : -1);
        });
      } catch (error) {
        console.error('Error during table sorting:', error);
        console.error('Sort key:', sortConfig.key);
        console.error('Sample data:', data.slice(0, 2));
      }
    }
    
    const sortEnd = performance.now();
    console.log(`Table: Sorting completed in ${(sortEnd - sortStart).toFixed(2)}ms`);
    
    return sortableData;
  }, [data, sortConfig]);
  
  // Handle column header click for sorting
  const handleSort = (key) => {
    // Only sort if column is marked as sortable
    const column = columns.find(col => col.key === key);
    if (!column || column.sortable === false) return;
    
    // Toggle direction if clicking the same column
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    
    setSortConfig({ key, direction });
  };
  
  // Get sort indicator
  const getSortIndicator = (key) => {
    if (sortConfig.key !== key) return null;
    
    return sortConfig.direction === 'asc' 
      ? <span className="ml-1 text-hyc-gold">↑</span> 
      : <span className="ml-1 text-hyc-gold">↓</span>;
  };

  // Memoize the table to prevent unnecessary re-renders
  const renderStart = performance.now();

  // Memoize the table rows
  const TableRow = React.memo(({ row, rowIndex, columns, onRowClick, getRowStyles }) => {
    return (
      <tr 
        className={getRowStyles(rowIndex)}
        onClick={onRowClick ? () => onRowClick(row) : undefined}
        data-id={row._id} // Add data-id for potential highlight features
      >
        {columns.map((column, colIndex) => (
          <td 
            key={colIndex} 
            className="px-4 py-3 text-sm text-hyc-light"
            style={column.width ? { width: column.width } : {}}
          >
            <div className="truncate">
              {column.render 
                ? column.render(row[column.key], row) 
                : row[column.key]}
            </div>
          </td>
        ))}
      </tr>
    );
  });
  
  // Memoize the table
  const tableContent = React.useMemo(() => {
    return (
      <div className="overflow-x-auto rounded-lg">
        <table className={combinedTableStyles} {...rest}>
          <thead className="bg-hyc-secondary sticky top-0 z-10">
            <tr>
              {columns.map((column, index) => (
                <th
                  key={index}
                  className={`px-4 py-3 text-left text-xs font-medium text-hyc-light uppercase tracking-wider ${column.sortable ? 'cursor-pointer hover:bg-hyc-light/10' : ''}`}
                  style={column.width ? { width: column.width } : {}}
                  onClick={column.sortable ? () => handleSort(column.key) : undefined}
                >
                  <div className="flex items-center">
                    {typeof column.header === 'string' 
                      ? <span>{column.header}{getSortIndicator(column.key)}</span>
                      : column.header
                    }
                    {column.sortable && typeof column.header !== 'string' && getSortIndicator(column.key)}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          
          <tbody className="bg-hyc-dark divide-y divide-hyc-light/10">
            {sortedData.map((row, rowIndex) => (
              <TableRow 
                key={row._id || rowIndex}
                row={row}
                rowIndex={rowIndex}
                columns={columns}
                onRowClick={onRowClick}
                getRowStyles={getRowStyles}
              />
            ))}
            
            {/* Fill empty rows to maintain a consistent height with 20 rows */}
            {sortedData.length > 0 && sortedData.length < 20 && Array.from({ length: 20 - sortedData.length }).map((_, i) => (
              <tr key={`empty-${i}`} className="h-[48px]">
                {columns.map((_, colIndex) => (
                  <td key={colIndex} className="px-4 py-3 border-b border-hyc-light/5"></td>
                ))}
              </tr>
            ))}
            
            {data.length === 0 && (
              <tr>
                <td 
                  colSpan={columns.length} 
                  className="px-4 py-8 text-center text-sm text-hyc-light/60"
                >
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }, [sortedData, columns, combinedTableStyles, onRowClick, getSortIndicator, handleSort]);
  
  const renderEnd = performance.now();
  console.log(`Table: Rendering completed in ${(renderEnd - renderStart).toFixed(2)}ms`);
  
  return tableContent;
};

export default Table;