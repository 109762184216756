import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Layout from '../Layout';
import axios from 'axios';
import config from '../../config';

// Import components
import Table from '../Table';
import Tag from '../Tag';
import Card from '../Card';
import Button from '../Button';
import Subtitle from '../Subtitle';

// Import icons
import { 
  SearchIcon, 
  FilterIcon, 
  RefreshIcon, 
  LocationIcon, 
  CalendarIcon, 
  InfoCircleIcon, 
  TagIcon,
  UserIcon
} from '../Icons';

// Type options with HYC-themed colors and tooltips
const tipoOptions = [
  { 
    value: 'Corrupción', 
    label: 'Corrupción', 
    color: 'border-hyc-accent bg-hyc-accent/20 text-hyc-accent',
    description: 'Uso del cargo para beneficio propio. Incluye coimas, favores o desvío de recursos.'
  },
  { 
    value: 'Abuso de Autoridad', 
    label: 'Abuso de autoridad', 
    color: 'border-hyc-orange bg-hyc-orange/20 text-hyc-orange',
    description: 'Cuando un policía se pasa de la raya. Detenciones injustas, amenazas o uso indebido del poder.'
  },
  { 
    value: 'Extorsión', 
    label: 'Extorsión', 
    color: 'border-hyc-gold bg-hyc-gold/20 text-hyc-gold',
    description: 'Pedir dinero o favores bajo presión o amenaza. También incluye chantajes.'
  },
  { 
    value: 'Negligencia', 
    label: 'Negligencia', 
    color: 'border-hyc-highlight bg-hyc-highlight/20 text-hyc-highlight',
    description: 'No hacer su trabajo cuando debe. Ignorar denuncias, no actuar ante emergencias, etc.'
  },
  { 
    value: 'Discriminación', 
    label: 'Discriminación', 
    color: 'border-hyc-light bg-hyc-light/20 text-hyc-light',
    description: 'Trato injusto por raza, género, origen, orientación o condición social.'
  },
  { 
    value: 'Violencia/Acoso', 
    label: 'Violencia/Acoso', 
    color: 'border-hyc-accent bg-hyc-accent/20 text-hyc-accent',
    description: 'Golpes, insultos, tocamientos, acoso verbal o físico, amenazas.'
  },
];

const ExplorePage = () => {
  const navigate = useNavigate();
  const [denuncias, setDenuncias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [rateLimited, setRateLimited] = useState(false);
  const [nextAllowedRequest, setNextAllowedRequest] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  // Check if user is authenticated
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const authTokens = localStorage.getItem('authTokens');
    const userInfo = localStorage.getItem('user');
    
    // Consider authenticated if we have token and user info
    const authenticated = !!(authToken || authTokens) && !!userInfo;
    setIsAuthenticated(authenticated);
  }, []);
  const [selectedType, setSelectedType] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showFilters, setShowFilters] = useState(false);

  // Add debounce state for search
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  
  // Debounce search term
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);
    
    return () => clearTimeout(timer);
  }, [searchTerm]);
  
  // Combined useEffect for all fetch triggers
  const [shouldFetch, setShouldFetch] = useState(false);
  
  // Track filter changes to reset page
  useEffect(() => {
    // Don't do anything on initial mount
    if (!shouldFetch) {
      setShouldFetch(true);
      return;
    }
    
    // Reset page when filters change
    setPage(1);
    // The actual fetch will happen in the next useEffect
  }, [selectedType, debouncedSearchTerm, startDate, endDate]);
  
  // Handle the actual data fetching, triggered by any change that should cause a refresh
  useEffect(() => {
    // Skip the initial render 
    if (!shouldFetch) return;
    
    // Use a requestId to avoid race conditions
    const requestId = Date.now();
    fetchDenuncias(requestId);
  }, [page, selectedType, debouncedSearchTerm, startDate, endDate, shouldFetch]);

  // Track the latest request to avoid race conditions
  const latestRequestRef = React.useRef(0);
  
  const fetchDenuncias = async (requestId = Date.now()) => {
    // Store the current request ID
    latestRequestRef.current = requestId;
    
    
    const fetchStart = Date.now();
    
    setLoading(true);
    setError(null);
    setIsSearching(!!debouncedSearchTerm);

    try {
      // Build query parameters
      const params = {
        page,
        limit: 20,
        ...(selectedType && { tipo: selectedType }),
        ...(debouncedSearchTerm && { search: debouncedSearchTerm }),
        ...(startDate && { startDate }),
        ...(endDate && { endDate })
      };
      
      
      // Don't use console.time as it has issues with React's double-render in dev mode
      const apiCallStart = Date.now();
      
      // Try with a shorter timeout first to avoid UI hanging
      const shortTimeout = 6000; // 6 seconds
      let response;
      
      // Add authorization header if authenticated
      const headers = {};
      if (isAuthenticated) {
        const authToken = localStorage.getItem('authToken');
        const authTokensStr = localStorage.getItem('authTokens');
        
        if (authToken) {
          headers.Authorization = `Bearer ${authToken}`;
        } else if (authTokensStr) {
          try {
            const tokens = JSON.parse(authTokensStr);
            if (tokens.accessToken) {
              headers.Authorization = `Bearer ${tokens.accessToken}`;
            } else if (tokens.idToken) {
              headers.Authorization = `Bearer ${tokens.idToken}`;
            }
          } catch (e) {
            
          }
        }
      }
      
      try {
        
        response = await axios.get(`${config.apiUrl}/denuncias`, { 
          headers,
          params,
          timeout: shortTimeout // Short timeout to prevent hanging
        });
        
        // Reset rate limit status if successful
        setRateLimited(false);
        setNextAllowedRequest(null);
        
        
      } catch (error) {
        // Check for rate limiting error (429 Too Many Requests)
        if (error.response && error.response.status === 429) {
          
          setRateLimited(true);
          
          // Set the next allowed request time if provided
          if (error.response.data.nextAllowedRequest) {
            setNextAllowedRequest(new Date(error.response.data.nextAllowedRequest));
          }
          
          throw error; // Re-throw to be caught by the outer catch block
        }
        
        // Handle timeout error
        if (error.code === 'ECONNABORTED') {
          
          

          // If short timeout fails, try once more with a longer timeout
          response = await axios.get(`${config.apiUrl}/denuncias`, { 
            headers,
            params,
            timeout: 30000 // 30 second timeout as backup
          });
          
        } else {
          // Re-throw other errors
          throw error;
        }
      }
      
      const apiCallEnd = Date.now();
      
      
      // Debug response data
      
      
      
      
      
      
      // Performance debugging
      if (response.data.debug) {
        
        
        
        
      }
      
      if (response.data.note) {
        
      }
      
      if (response.data.error) {
        
      }
      
      // Data structure debugging
      if (response.data.data && response.data.data.length > 0) {
        const firstItem = response.data.data[0];
        

        // Check for denunciado population
        if (firstItem.denunciado) {
          
        } else {
          
        }
        
        // Check for missing expected fields
        const expectedFields = ['_id', 'tipo', 'descripcion', 'denunciado', 'fecha_ocurrencia', 'distrito_ocurrencia'];
        const missingFields = expectedFields.filter(field => !firstItem[field]);
        
        if (missingFields.length > 0) {
          
        }
      }
      
      // Process response data to make it more resilient
      const responseData = response.data.data || [];
      
      // UPDATED 2025-03-20: Added enhanced logging to debug denunciados display issues
      
      
      
      
      if (responseData.length > 0) {
        
        // Log the type of each item in the denunciados array for the first item
        if (Array.isArray(responseData[0].denunciados)) {
          responseData[0].denunciados.forEach((d, idx) => {
            
            
            if (typeof d === 'object' && d !== null) {
              
              
            }
          });
        }
      }
      
      // Create complete objects that won't cause rendering issues in the table
      const processedDenuncias = responseData.map(denuncia => {
        // Log raw denuncia data to see what's coming from the API
        console.log('Raw denuncia data:', { 
          _id: denuncia._id,
          denunciado: denuncia.denunciado, 
          denunciados: denuncia.denunciados,
          title: denuncia.titulo,
          tipo: denuncia.tipo
        });
          
        // Create a new object with all required fields
        const processed = {
          // Use default values for anything missing
          _id: denuncia._id || `temp-${Math.random().toString(36).substr(2, 9)}`,
          tipo: denuncia.tipo || 'No especificado',
          descripcion: denuncia.descripcion || 'Sin descripción',
          fecha_ocurrencia: denuncia.fecha_ocurrencia || new Date().toISOString(),
          distrito_ocurrencia: denuncia.distrito_ocurrencia || 'No especificado',
          provincia_ocurrencia: denuncia.provincia_ocurrencia || 'No especificado',
          departamento_ocurrencia: denuncia.departamento_ocurrencia || 'No especificado',
          numero_denuncia: denuncia.numero_denuncia || 0,
          
          // Ensure denunciados array is properly populated and has content
          denunciados: Array.isArray(denuncia.denunciados) && denuncia.denunciados.length > 0
            ? (denuncia.denunciados.map(d => {
                // Add detailed console logging to debug the data
                
                
                // UPDATED: Since the backend now properly returns populated objects,
                // we don't need the HOTFIX code to convert IDs to objects
                
                // Simply pass through the object, just ensure it has a nombre property
                if (typeof d === 'object' && d !== null) {
                  return d; // Use the properly populated object from API
                } else if (typeof d === 'string') {
                  // For backwards compatibility, convert IDs to objects
                  return {
                    _id: d,
                    nombre: "Denunciado " + d.substring(d.length - 5)
                  };
                } else {
                  // Fallback for unexpected values
                  return { 
                    nombre: 'No especificado', 
                    _id: d || Math.random().toString()
                  };
                }
              }))
            : denuncia.denunciado
              ? [typeof denuncia.denunciado === 'object' && denuncia.denunciado !== null
                  ? denuncia.denunciado
                  : { nombre: denuncia.denunciado_nombre || "Denunciado " + denuncia.denunciado.substring(denuncia.denunciado.length - 5) || 'No especificado', _id: denuncia.denunciado }]
              : [{ nombre: 'No especificado' }]
        };
        
        // Add any other original fields
        return { ...denuncia, ...processed };
      });
      
      // Only update state if this is still the latest request
      if (latestRequestRef.current === requestId) {
        
        setDenuncias(processedDenuncias);
        setTotalPages(response.data.totalPages || 1);
      } else {
        
      }
      
      
    } catch (err) {
      
      
      
      if (err.response) {
        
      } else if (err.request) {
        
      }
      
      setError('Error al cargar denuncias. Por favor intente nuevamente.');
    } finally {
      // Only update state if this is still the latest request
      if (latestRequestRef.current === requestId) {
        setLoading(false);
        setIsSearching(false);
        
      } else {
        
      }
    }
  };
  
  // Handle date range filter
  const handleDateFilter = () => {
    // Date filter is now handled by the useEffect
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // Search is now handled by the debounced useEffect
  };

  const handleTypeFilter = (type) => {
    setSelectedType(type === selectedType ? null : type);
    // Page reset and fetching handled by useEffect
  };

  const handleRowClick = (denuncia) => {
    navigate(`/denuncia/${denuncia._id}`);
  };

  const handleRefresh = () => {
    setSearchTerm('');
    setDebouncedSearchTerm('');
    setSelectedType(null);
    setStartDate('');
    setEndDate('');
    setPage(1);
    
    // Minor delay to ensure state is updated
    setTimeout(() => {
      fetchDenuncias();
    }, 10);
  };
  
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  // Format date to locale string
  const formatDate = (dateString) => {
    if (!dateString) return 'Sin fecha';
    
    const date = new Date(dateString);
    return date.toLocaleDateString('es-PE', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  // Get tag color for denuncia type
  const getTypeColor = (type) => {
    const typeOption = tipoOptions.find(option => option.value === type);
    return typeOption ? typeOption.color : 'bg-gray-600';
  };

  // Generate excerpt from description
  const getExcerpt = (text, maxLength = 100) => {
    if (!text) return 'Sin descripción';
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };


  // Table column definitions
  const columns = [
    {
      key: 'numero_denuncia',
      header: (
        <span className="flex items-center text-left font-medium text-hyc-light/80">
          #
        </span>
      ),
      render: (numero) => (
        <span className="font-mono text-hyc-light">
          {numero ? numero.toString().padStart(5, '0') : '00000'}
        </span>
      ),
      sortable: true,
      width: "8%",
    },
    {
      key: 'tipo',
      header: (
        <span className="flex items-center text-left font-medium text-hyc-light/80">
          Tipo
        </span>
      ),
      render: (tipo) => (
        <span 
          className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium border ${getTypeColor(tipo) || 'border-gray-500 bg-gray-500/20 text-gray-400'}`}
        >
          {tipo || 'No especificado'}
        </span>
      ),
      sortable: false,
      width: "15%",
    },
    {
      key: 'denunciados',
      header: (
        <span className="flex items-center text-left font-medium text-hyc-light/80">
          Denunciados
        </span>
      ),
      render: (denunciados, row) => (
        <div className="flex flex-col">
          {Array.isArray(denunciados) && denunciados.length > 0 ? (
            <>
              {denunciados.map((denunciado, index) => {
                // Enhanced debugging for denunciado rendering - UPDATED 2025-03-20
                
                
                
                
                const nombreToRender = denunciado?.nombre || 
                  (typeof denunciado === 'string' ? denunciado : 'Anónimo');
                  
                
                
                return (
                  <div key={index} className={`flex items-center ${index > 0 ? 'mt-2' : 'font-medium'}`}>
                    <UserIcon className="mr-1 text-hyc-light/60" />
                    <span className="truncate">{nombreToRender}</span>
                  </div>
                );
              })}
              {row.rango && (
                <div className="text-xs text-hyc-light/70 mt-1">
                  {row.rango}
                </div>
              )}
            </>
          ) : (
            <div className="flex items-center font-medium">
              <UserIcon className="mr-1 text-hyc-light/60" />
              <span className="truncate">No especificado</span>
            </div>
          )}
        </div>
      ),
      sortable: false,
      width: "25%",
    },
    {
      key: 'descripcion',
      header: (
        <span className="flex items-center text-left font-medium text-hyc-light/80">
          Info
        </span>
      ),
      render: (descripcion) => (
        <div className="relative group cursor-help" title="Ver descripción">
          <InfoCircleIcon className="text-hyc-light/70 hover:text-hyc-gold transition-colors" />
          
          {/* Tooltip with description preview */}
          <div className="absolute left-0 -mt-2 w-64 p-3 bg-hyc-dark border border-hyc-light/20 rounded shadow-lg 
                          opacity-0 group-hover:opacity-100 invisible group-hover:visible transition-all duration-200 z-10">
            <div className="text-xs text-hyc-light/90 line-clamp-3">{descripcion || 'Sin descripción'}</div>
          </div>
        </div>
      ),
      sortable: false,
      width: "5%",
    },
    {
      key: 'ubicacion',
      header: (
        <span className="flex items-center text-left font-medium text-hyc-light/80">
          Ubicación
        </span>
      ),
      render: (_, row) => (
        <div className="flex flex-col">
          {row.distrito_ocurrencia ? (
            <>
              <div className="font-medium flex items-center">
                <LocationIcon className="mr-1 text-hyc-light/60" />
                <span className="truncate">{row.distrito_ocurrencia}</span>
              </div>
              <div className="text-xs text-hyc-light/70 mt-1 truncate">
                {row.provincia_ocurrencia}, {row.departamento_ocurrencia}
              </div>
            </>
          ) : (
            <span className="text-hyc-light/50">No especificado</span>
          )}
        </div>
      ),
      sortable: false,
      width: "25%",
    },
    {
      key: 'fecha_ocurrencia',
      header: (
        <span className="flex items-center text-left font-medium text-hyc-light/80">
          Fecha
        </span>
      ),
      render: (fecha) => (
        <div className="flex flex-col">
          {fecha ? (
            <>
              <div className="font-medium flex items-center">
                <CalendarIcon className="mr-1 text-hyc-light/60" />
                <span>{new Date(fecha).toLocaleDateString('es-PE')}</span>
              </div>
              <div className="text-xs text-hyc-light/70 mt-1">
                {new Date(fecha).toLocaleTimeString('es-PE', { hour: '2-digit', minute: '2-digit' })}
              </div>
            </>
          ) : (
            <span className="text-hyc-light/50">No especificada</span>
          )}
        </div>
      ),
      sortable: true,
      width: "15%",
    },
    {
      key: 'actions',
      header: '',
      render: (_, row) => (
        <Button
          variant="secondary"
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/denuncia/${row._id}`);
          }}
        >
          Ver
        </Button>
      ),
      width: "10%",
    },
  ];

  // Use our new Layout component while keeping the original content
  return (
    <Layout 
      title="Explorar Denuncias" 
      subtitle="Busca denuncias por tipo, ubicación y más"
      fullWidth={true} // Use full width for better space usage
      titleSize="text-2xl" // Smaller title size
    >
      <div className="flex flex-col"> {/* Container without fixed height for better table display */}
        <div className="bg-hyc-accent/20 border border-hyc-accent text-hyc-accent font-medium rounded-lg p-4 mb-6 text-center">
          <span className="font-bold">¡ANUNCIO IMPORTANTE!</span><br/>
          La plataforma estará disponible oficialmente a partir del<br/>
          <span className="text-lg font-bold">Domingo, 30 de Marzo de 2025</span>
        </div>
        
        {/* Rate limiting warning for non-authenticated users */}
        {!isAuthenticated && (
          <div className={`${rateLimited ? 'bg-hyc-accent/20 border-hyc-accent/30' : 'bg-hyc-gold/10 border-hyc-gold/30'} border rounded-lg p-4 mb-4`}>
            <div className="flex items-center">
              <InfoCircleIcon className={`${rateLimited ? 'text-hyc-accent' : 'text-hyc-gold'} text-xl mr-3 flex-shrink-0`} />
              <div>
                <h3 className="text-hyc-light font-medium">
                  {rateLimited ? 'Límite de búsquedas alcanzado' : 'Límite de búsquedas para usuarios no registrados'}
                </h3>
                <p className="text-hyc-light/80 text-sm">
                  {rateLimited 
                    ? `Has alcanzado el límite de búsquedas para usuarios no registrados.${
                        nextAllowedRequest 
                          ? ` Por favor intenta nuevamente después de las ${nextAllowedRequest.toLocaleTimeString()}, o regístrate para acceso completo.` 
                          : ''
                      }`
                    : `Los usuarios no registrados están limitados a 1 búsqueda cada 15 minutos.`
                  }
                </p>
                <div className="mt-3 flex items-center space-x-3">
                  <Link to="/login">
                    <Button variant="outline" size="sm">
                      Iniciar Sesión
                    </Button>
                  </Link>
                  <Link to="/registro">
                    <Button variant="primary" size="sm">
                      Registrarse para acceso completo
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {/* Rate limiting error message - only show if rate limited and user not authenticated */}
        {rateLimited && !isAuthenticated && false && ( /* Disabled - using the warning box above instead */
          <div className="bg-hyc-accent/20 border border-hyc-accent/30 rounded-lg p-4 mb-4">
            <div className="flex items-start">
              <InfoCircleIcon className="text-hyc-accent text-xl mr-3 flex-shrink-0 mt-1" />
              <div>
                <h3 className="text-hyc-light font-medium">Límite de búsquedas alcanzado</h3>
                <p className="text-hyc-light/80">
                  Has alcanzado el límite de búsquedas para usuarios no registrados.
                  {nextAllowedRequest && (
                    <span> Por favor intenta nuevamente después de las {nextAllowedRequest.toLocaleTimeString()}, o regístrate para acceso completo.</span>
                  )}
                </p>
              </div>
            </div>
          </div>
        )}
        
        {/* Filters and search */}
        <Card className="mb-4 p-4">
          <div className="flex flex-col gap-4">
            {/* Top row: Search and filter toggle */}
            <div className="flex flex-col sm:flex-row gap-4 items-center">
              {/* Search form */}
              <form onSubmit={handleSearch} className="flex-grow">
                <div className="relative flex items-center">
                  <input
                    type="text"
                    className={`w-full p-2 pl-10 border rounded-lg bg-hyc-dark text-hyc-light 
                      ${isSearching 
                        ? 'border-hyc-gold focus:border-hyc-gold focus:ring-hyc-gold/50' 
                        : 'border-gray-500 focus:border-hyc-highlight focus:outline-none focus:ring-1 focus:ring-hyc-highlight'
                      } h-[40px]`}
                    placeholder="Buscar por descripción, ubicación o denunciado..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <div className="absolute left-3 text-hyc-light/60">
                    {isSearching ? (
                      <RefreshIcon className="animate-spin text-hyc-gold" />
                    ) : (
                      <SearchIcon />
                    )}
                  </div>
                  <Button
                    type="submit"
                    className="absolute right-2"
                    size="sm"
                    disabled={isSearching}
                  >
                    {isSearching ? 'Buscando...' : 'Buscar'}
                  </Button>
                </div>
              </form>
              
              {/* Filter toggle button */}
              <Button 
                variant="secondary"
                size="sm"
                onClick={toggleFilters}
                className="h-[40px] min-w-[130px]"
              >
                <FilterIcon className="mr-1.5" /> 
                {showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}
              </Button>
            </div>
            
            {/* Filters section - toggleable */}
            {showFilters && (
              <div className="pt-4 border-t border-hyc-light/10">
                {/* Date filters */}
                <div className="mb-4">
                  <div className="flex items-center mb-3">
                    <CalendarIcon className="text-hyc-gold mr-2" />
                    <h3 className="text-lg font-medium text-hyc-light">Filtrar por fecha</h3>
                  </div>
                  
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 items-end">
                    <div className="flex flex-col">
                      <label className="text-xs text-hyc-light/60 mb-1">Desde</label>
                      <div className="relative">
                        <input
                          type="date"
                          className="w-full p-2 pl-3 pr-10 border rounded-lg bg-hyc-dark text-hyc-light border-gray-500 focus:border-hyc-highlight focus:outline-none focus:ring-1 focus:ring-hyc-highlight h-[40px]"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <CalendarIcon className="text-hyc-light/60" />
                        </div>
                      </div>
                    </div>
                    
                    <div className="flex flex-col">
                      <label className="text-xs text-hyc-light/60 mb-1">Hasta</label>
                      <div className="relative">
                        <input
                          type="date"
                          className="w-full p-2 pl-3 pr-10 border rounded-lg bg-hyc-dark text-hyc-light border-gray-500 focus:border-hyc-highlight focus:outline-none focus:ring-1 focus:ring-hyc-highlight h-[40px]"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <CalendarIcon className="text-hyc-light/60" />
                        </div>
                      </div>
                    </div>
                    
                    <div className="flex items-center justify-center sm:justify-start">
                      <Button 
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                          setStartDate('');
                          setEndDate('');
                        }}
                        className="h-[40px] w-full sm:w-auto"
                        disabled={loading || (!startDate && !endDate)}
                      >
                        Limpiar fechas
                      </Button>
                    </div>
                  </div>
                </div>
                
                {/* Tipo de denuncia filters */}
                <div className="mb-2">
                  <div className="flex items-center mb-3">
                    <TagIcon className="text-hyc-gold mr-2" />
                    <h3 className="text-lg font-medium text-hyc-light">Filtrar por tipo</h3>
                  </div>
                  
                  <div className="flex flex-wrap gap-2">
                    {tipoOptions.map((option) => (
                      <div key={option.value} className="relative group">
                        <button
                          className={`px-3 py-1.5 rounded-md text-sm font-medium border transition-colors
                            ${selectedType === option.value 
                              ? option.color
                              : 'border-hyc-light/30 bg-hyc-dark text-hyc-light/70 hover:border-hyc-light/50 hover:text-hyc-light'
                            }`}
                          onClick={() => handleTypeFilter(option.value)}
                        >
                          {option.label}
                        </button>
                        
                        {/* Tooltip */}
                        <div className="absolute z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-200 bottom-full left-1/2 transform -translate-x-1/2 mb-2 p-3 bg-hyc-dark border border-hyc-light/20 rounded shadow-lg text-hyc-light text-sm whitespace-nowrap">
                          {option.description}
                          <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 border-l-8 border-r-8 border-l-transparent border-r-transparent border-t-8 border-t-hyc-dark"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                
                {/* Reset all filters */}
                {(selectedType || startDate || endDate || searchTerm) && (
                  <div className="mt-4 flex justify-end">
                    <Button 
                      variant="outline"
                      size="sm"
                      onClick={handleRefresh}
                      className="h-[38px]"
                    >
                      <RefreshIcon className="mr-1.5" /> Limpiar todos los filtros
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </Card>
      
      {/* Table section with fixed height for 20 rows */}
      <div className="bg-hyc-secondary rounded-lg mb-4">
        <Card>
          {/* Table component */}
          <div className="p-4">
            {loading ? (
              <div className="text-center py-10">
                <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-hyc-gold"></div>
                <p className="text-hyc-light mt-4">Cargando denuncias...</p>
              </div>
            ) : error ? (
              <div className="text-center py-10">
                <p className="text-hyc-accent mb-4">{error}</p>
                <button 
                  onClick={() => fetchDenuncias()}
                  className="bg-hyc-gold hover:bg-hyc-gold/80 text-hyc-dark font-bold py-2 px-4 rounded-lg transition-colors"
                >
                  Reintentar
                </button>
              </div>
            ) : denuncias.length === 0 ? (
              <div className="flex flex-col items-center justify-center py-16 bg-hyc-dark/30">
                <InfoCircleIcon className="text-hyc-light/40 text-5xl mb-4" />
                <h3 className="text-xl font-mono text-hyc-light mb-2">No se encontraron denuncias</h3>
                <p className="text-hyc-light/60 mb-4 text-center max-w-md">
                  {selectedType || debouncedSearchTerm || startDate || endDate
                    ? 'Intente cambiar los filtros o términos de búsqueda.' 
                    : 'Aún no hay denuncias registradas en el sistema.'}
                </p>
                <div className="flex flex-col gap-4 w-full max-w-md">
                  {(selectedType || debouncedSearchTerm || startDate || endDate) && (
                    <Button onClick={handleRefresh} className="mb-2">
                      <RefreshIcon className="mr-2" /> Limpiar filtros
                    </Button>
                  )}
                  <Button 
                    variant="secondary" 
                    onClick={() => {
                      // Create seeded data button
                      fetchDenuncias(Date.now());
                    }}
                  >
                    <RefreshIcon className="mr-2" /> Intentar cargar nuevamente
                  </Button>
                </div>
              </div>
            ) : (
              <div className="overflow-hidden">
                <Table
                  columns={columns}
                  data={denuncias}
                  onRowClick={handleRowClick}
                  striped
                  hoverable
                  defaultSortKey="numero_denuncia"
                  defaultSortOrder="desc"
                  className="table-fixed"
                />
              </div>
            )}
          </div>
          
          {/* Pagination */}
          {totalPages > 1 && (
            <div className="flex items-center justify-between bg-hyc-secondary px-4 py-3 border-t border-hyc-light/10">
              <div className="flex-1 flex justify-between sm:hidden">
                <Button
                  variant="outline"
                  onClick={() => setPage(Math.max(1, page - 1))}
                  disabled={page === 1}
                >
                  Anterior
                </Button>
                <Button
                  variant="outline"
                  onClick={() => setPage(Math.min(totalPages, page + 1))}
                  disabled={page === totalPages}
                >
                  Siguiente
                </Button>
              </div>
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-hyc-light">
                    Mostrando página <span className="font-medium">{page}</span> de{' '}
                    <span className="font-medium">{totalPages}</span>
                  </p>
                </div>
                <div>
                  <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <Button
                      variant="outline"
                      className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-hyc-light/20 bg-hyc-dark text-sm font-medium text-hyc-light hover:bg-hyc-light/10"
                      onClick={() => setPage(Math.max(1, page - 1))}
                      disabled={page === 1}
                    >
                      <span className="sr-only">Previous</span>
                      &larr;
                    </Button>
                    
                    {/* Page numbers */}
                    {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                      let pageNum;
                      
                      // Calculate which page numbers to show
                      if (totalPages <= 5) {
                        // Show all pages if 5 or fewer
                        pageNum = i + 1;
                      } else if (page <= 3) {
                        // Near the start
                        pageNum = i + 1;
                      } else if (page >= totalPages - 2) {
                        // Near the end
                        pageNum = totalPages - 4 + i;
                      } else {
                        // In the middle
                        pageNum = page - 2 + i;
                      }
                      
                      return (
                        <Button
                          key={i}
                          variant={pageNum === page ? 'primary' : 'outline'}
                          className="relative inline-flex items-center px-4 py-2 border border-hyc-light/20 bg-hyc-dark text-sm font-medium text-hyc-light hover:bg-hyc-light/10"
                          onClick={() => setPage(pageNum)}
                        >
                          {pageNum}
                        </Button>
                      );
                    })}
                    
                    <Button
                      variant="outline"
                      className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-hyc-light/20 bg-hyc-dark text-sm font-medium text-hyc-light hover:bg-hyc-light/10"
                      onClick={() => setPage(Math.min(totalPages, page + 1))}
                      disabled={page === totalPages}
                    >
                      <span className="sr-only">Next</span>
                      &rarr;
                    </Button>
                  </nav>
                </div>
              </div>
            </div>
          )}
        </Card>
      </div>
        
        {/* Call to Action */}
        <div className="mt-2 text-center py-4 border-t border-hyc-light/10">
          <h3 className="text-hyc-gold text-xl font-bold mb-2">¿Conoces un caso de corrupción?</h3>
          <Button 
            variant="primary"
            onClick={() => navigate('/denunciaform')}
            className="px-6 py-2"
          >
            Enviar una Denuncia
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default ExplorePage;