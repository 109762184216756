import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Import components
import Button from './Button';
import Card from './Card';
import Subtitle from './Subtitle';
import { LockIcon, RefreshIcon, EmailIcon, MobileIcon } from './Icons';
import config from '../config';

const VerificationForm = () => {
  // State variables for form
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  
  // Navigation
  const location = useLocation();
  const navigate = useNavigate();
  
  // Get values from localStorage or location state
  const userId = localStorage.getItem('registrationUserId') || location.state?.userId;
  const contactMethod = location.state?.contactMethod || 
                        localStorage.getItem('contactMethod') || 
                        'email'; // 'email' or 'celular'
                        
  // Get the correct contact value based on the method
  let contactValue = '';
  if (contactMethod === 'email') {
    contactValue = location.state?.contactValue || localStorage.getItem('userEmail') || '';
  } else {
    contactValue = location.state?.contactValue || localStorage.getItem('userTelefono') || '';
  }
  // Determine if this is a registration or login verification
  // - If path is /verify, it's registration
  // - If path is /verify-login, it's login
  // - Otherwise check state and localStorage values
  const isRegistration = location.pathname === '/verify-login' ? false : 
                        (location.pathname === '/verify' || 
                         location.state?.isRegistration === true || 
                         localStorage.getItem('pendingRegistration') === 'true');
  
  // Check for the "just registered" flag and set initial countdown
  useEffect(() => {
    // If we just came from registration, set the resend button as disabled
    // to prevent users from immediately requesting a new OTP
    const justRegistered = localStorage.getItem('justRegistered') === 'true';
    if (justRegistered) {
      // Clear the flag so it's only used once
      localStorage.removeItem('justRegistered');
      // Disable resend button for 60 seconds after registration
      setResendDisabled(true);
      setCountdown(60);
    }
  }, []);

  // Handle OTP countdown for resend
  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0 && resendDisabled) {
      setResendDisabled(false);
    }
  }, [countdown, resendDisabled]);
  
  // Handle code input 
  const handleCodeChange = (e) => {
    // Only allow numbers and limit to 6 digits
    const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
    setCode(value);
    
    // Auto-submit when code length reaches 6 digits
    if (value.length === 6) {
      handleSubmit(null, value);
    }
  };
  
  // Submit verification code
  const handleSubmit = async (e, autoCode = null) => {
    if (e) e.preventDefault();
    const verificationCode = autoCode || code;
    
    if (!verificationCode || verificationCode.length !== 6) {
      setErrorMessage('El código debe tener 6 dígitos');
      return;
    }
    
    if (!userId && !localStorage.getItem('authUsername')) {
      setErrorMessage('No se ha encontrado información del usuario. Por favor intente registrarse nuevamente.');
      return;
    }
    
    setLoading(true);
    setErrorMessage('');
    
    try {
      // Check if we're using OTP verification
      const isPendingVerification = localStorage.getItem('pendingVerification') === 'true';
      const authUsername = localStorage.getItem('authUsername');
      
      // Authentication data processing
      
      let endpoint, payload;
      
      if (isPendingVerification && authUsername) {
        // Using OTP verification
        const isLogin = location.pathname === '/verify-login';
        console.log('Verification path check:', {
          path: location.pathname,
          isLoginPath: location.pathname === '/verify-login',
          isLogin
        });
        
        // Always use the verify-login endpoint when on the verify-login path
        endpoint = isLogin 
          ? '/auth/otp/verify-login' 
          : '/auth/otp/confirm-signup';
        
        // Format payload - ensure code is a string with no whitespace
        const cleanCode = verificationCode.toString().trim();
        
        payload = {
          username: authUsername,
          code: cleanCode
        };
        
        // Preparing to send verification request
      } else {
        // Using traditional verification
        if (isRegistration) {
          // If this is a registration verification
          endpoint = contactMethod === 'email' ? '/auth/verify-email' : '/auth/verify-telefono';
        } else {
          // If this is a login verification
          endpoint = '/auth/verify-login';
        }
        
        // Setting endpoint based on contact method
        
        // Prepare request payload based on contact method
        payload = {
          codigo: verificationCode
        };
        
        // Add the appropriate contact field based on method
        if (contactMethod === 'email') {
          payload.email = contactValue;
        } else if (contactMethod === 'celular') {
          payload.telefono = contactValue;
        }
        
        // Add userId for registration verification
        if (isRegistration && userId) {
          payload.userId = userId;
        }
      }
      
      const response = await axios.post(`${config.apiUrl}${endpoint}`, payload);
      
      // Handle successful verification
      setSuccess(true);
      
      // Save auth token if provided
      if (response.data.token) {
        localStorage.setItem('authToken', response.data.token);
      }
      
      // Save user info
      if (response.data.user) {
        localStorage.setItem('user', JSON.stringify(response.data.user));
      }
      
      // Save tokens if provided
      if (response.data.tokens) {
        // Store auth tokens for our custom auth system
        const tokens = response.data.tokens;
        
        // Ensure tokens include an expiration time
        if (!tokens.expiresAt && tokens.expiresIn) {
          // Calculate expiration time in milliseconds
          tokens.expiresAt = Date.now() + (tokens.expiresIn * 1000);
        } else if (!tokens.expiresAt) {
          // Default expiration: 24 hours
          tokens.expiresAt = Date.now() + (24 * 60 * 60 * 1000);
        }
        
        // Also store the individual token for legacy compatibility
        if (tokens.accessToken) {
          localStorage.setItem('authToken', tokens.accessToken);
        } else if (tokens.idToken) {
          localStorage.setItem('authToken', tokens.idToken);
        }
        
        // Save formatted tokens
        localStorage.setItem('authTokens', JSON.stringify(tokens));
      }
      
      // Clear verification flags for both registration and login
      localStorage.removeItem('pendingRegistration');
      localStorage.removeItem('registrationUserId');
      localStorage.removeItem('pendingVerification');
      localStorage.removeItem('authUsername');
      localStorage.removeItem('contactMethod');
      
      // Trigger auth event immediately to update UI
      window.dispatchEvent(new Event('auth-status-changed'));
      
      // Add immediate debug logging
      console.log('[AUTH DEBUG] After login/verification - localStorage state:', {
        authTokens: !!localStorage.getItem('authTokens'),
        authToken: !!localStorage.getItem('authToken'),
        user: !!localStorage.getItem('user')
      });
      
      // Verification completed successfully
      
      // Show success UI for at least 1.5 seconds for registration, 1 second for login
      const redirectDelay = isRegistration ? 1500 : 1000;
      
      // Set a message depending on if it's registration or login
      setSuccess(true);
      setErrorMessage('');
      setLoading(false);
      
      // Always show success screen for a moment before redirecting
      setTimeout(() => {
        // Double-check localStorage before redirect
        console.log('[AUTH DEBUG] Before redirect - localStorage state:', {
          authTokens: !!localStorage.getItem('authTokens'),
          authToken: !!localStorage.getItem('authToken'),
          user: !!localStorage.getItem('user')
        });
        
        // Force a window location reload to completely reset the app state
        window.location.href = '/';
      }, redirectDelay);
      
    } catch (error) {
      console.error('Verification error:', error);
      
      if (error.response) {
        setErrorMessage(error.response.data.message || 'Error al verificar el código. Por favor intente nuevamente.');
      } else {
        setErrorMessage('Error de conexión. Por favor revise su conexión a internet.');
      }
      
      setLoading(false);
    }
  };
  
  // Resend verification code
  const handleResendCode = async () => {
    if (resendDisabled) return;
    
    setResendDisabled(true);
    setCountdown(60); // 60 second cooldown
    setErrorMessage('');
    
    try {
      // Check if we're using OTP verification
      const isPendingVerification = localStorage.getItem('pendingVerification') === 'true';
      const authUsername = localStorage.getItem('authUsername');
      
      // Preparing to resend verification code
      
      let endpoint, payload;
      
      if (isPendingVerification && authUsername) {
        // Using OTP resend code endpoint
        endpoint = '/auth/otp/resend-code';
        payload = {
          username: authUsername
        };
      } else {
        // Using traditional resend endpoint
        endpoint = contactMethod === 'email' 
          ? '/auth/resend-email-code'
          : '/auth/resend-sms-code';
        
        // Prepare request payload based on contact method
        payload = { userId };
        
        if (contactMethod === 'email') {
          payload.email = contactValue;
        } else if (contactMethod === 'celular') {
          payload.telefono = contactValue;
        }
      }
      
      await axios.post(`${config.apiUrl}${endpoint}`, payload);
      
      // Show success message
      setErrorMessage('');
    } catch (error) {
      console.error('Error resending code:', error);
      setErrorMessage('No se pudo reenviar el código. Por favor intente más tarde.');
    }
  };
  
  // Handle rendering based on verification state
  if (success) {
    return (
      <div className="max-w-6xl mx-auto p-6">
        <Subtitle />
        <div className="text-center mb-8">
          <h2 className="text-4xl font-mono text-hyc-light">¡Verificación Exitosa!</h2>
        </div>
        
        <Card className="max-w-md mx-auto bg-hyc-secondary border-l-4 border-green-500">
          <div className="text-center p-6">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-green-100 mb-6">
              <svg className="w-10 h-10 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
            </div>
            
            <h3 className="text-2xl font-mono text-hyc-light mb-4">
              {isRegistration ? '¡Cuenta Verificada!' : '¡Inicio de Sesión Exitoso!'}
            </h3>
            
            <p className="text-hyc-light mb-3">
              {isRegistration 
                ? 'Tu cuenta ha sido verificada exitosamente. Ahora puedes acceder a todas las funciones de Honor y Coima.'
                : 'Has iniciado sesión correctamente. Redirigiendo a la página principal...'}
            </p>
            
            {/* Animated dots to show loading status */}
            <div className="flex justify-center space-x-2 my-4">
              <div className="w-3 h-3 bg-hyc-gold rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
              <div className="w-3 h-3 bg-hyc-gold rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
              <div className="w-3 h-3 bg-hyc-gold rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
            </div>
            
            <Button onClick={() => navigate('/')} size="lg" className="mt-4">
              Ir a la Página Principal
            </Button>
          </div>
        </Card>
      </div>
    );
  }
  
  return (
    <div className="max-w-6xl mx-auto p-6">
      <Subtitle />
      <div className="text-center mb-8">
        <h2 className="text-4xl font-mono text-hyc-light">Verificación</h2>
        <p className="text-hyc-light/80 mt-2">
          {isRegistration 
            ? 'Verifica tu cuenta para continuar'
            : 'Ingresa el código de verificación para acceder'}
        </p>
      </div>
      
      <Card className="max-w-md mx-auto">
        <div className="p-6">
          <div className="flex items-center mb-6 pb-4 border-b border-hyc-light/10">
            <LockIcon className="text-hyc-gold mr-3 w-5 h-5" />
            <span className="text-lg font-mono text-hyc-light">Código de Verificación</span>
          </div>
          
          <div className="bg-hyc-dark/50 rounded-lg p-4 mb-6 border border-hyc-light/10">
            <p className="text-hyc-light mb-2">
              Hemos enviado un código de verificación a:
            </p>
            <div className="flex items-center justify-center">
              {contactMethod === 'email' ? (
                <EmailIcon className="text-hyc-gold mr-2" />
              ) : (
                <MobileIcon className="text-hyc-gold mr-2" />
              )}
              <p className="text-hyc-gold font-mono">
                {contactMethod === 'email'
                  ? (contactValue || 'tu correo electrónico')
                  : (contactValue || 'tu teléfono')}
              </p>
            </div>
          </div>
          
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label className="block mb-2 text-hyc-light text-sm">
                Ingresa el código de 6 dígitos:
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={code}
                  onChange={handleCodeChange}
                  className="w-full p-3 bg-hyc-dark text-hyc-light border border-hyc-light/20 rounded-lg
                            focus:border-hyc-gold focus:outline-none focus:ring-1 focus:ring-hyc-gold
                            text-center text-2xl tracking-widest font-mono"
                  inputMode="numeric"
                  autoFocus
                  placeholder="000000"
                  maxLength={6}
                />
              </div>
              {errorMessage && (
                <p className="text-hyc-accent text-sm mt-2">{errorMessage}</p>
              )}
            </div>
            
            <div className="flex flex-col space-y-3">
              <Button
                type="submit"
                fullWidth
                size="lg"
                disabled={loading || code.length !== 6}
              >
                {loading ? (
                  <span className="flex items-center justify-center">
                    Verificando
                    <span className="ml-1 inline-flex">
                      <span className="animate-bounce mx-px">.</span>
                      <span className="animate-bounce mx-px" style={{ animationDelay: '150ms' }}>.</span>
                      <span className="animate-bounce mx-px" style={{ animationDelay: '300ms' }}>.</span>
                    </span>
                  </span>
                ) : 'Verificar'}
              </Button>
              
              <Button
                type="button"
                variant="outline"
                fullWidth
                onClick={handleResendCode}
                disabled={resendDisabled}
                icon={<RefreshIcon className="mr-2" />}
              >
                {resendDisabled 
                  ? `Reenviar código (${countdown}s)`
                  : 'Reenviar código'}
              </Button>
            </div>
          </form>
          
          <div className="mt-6 text-center">
            <p className="text-sm text-hyc-light/70">
              Si no recibiste el código, revisa tu carpeta de correo no deseado o solicita un nuevo código.
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default VerificationForm;
