import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../Layout';
import { MapIcon, LocationIcon, WarningIcon, CalendarIcon, TagIcon, InfoCircleIcon, UserIcon } from '../Icons';
import SimpleMap from './SimpleMap';
import Table from '../Table';
import Button from '../Button';
import Card from '../Card';
import axios from 'axios';
import config from '../../config';
import 'leaflet/dist/leaflet.css';

const CercaDeMiPage = () => {
  const navigate = useNavigate();
  const [denuncias, setDenuncias] = useState([]); // All denuncias from API
  const [visibleDenuncias, setVisibleDenuncias] = useState([]); // Only denuncias visible in current map view
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [isUsingCurrentLocation, setIsUsingCurrentLocation] = useState(false);
  const [highlightedDenuncia, setHighlightedDenuncia] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  
  // Default location - center of Peru
  const peruCenterLocation = { lat: -9.189967, lng: -75.015152 };
  
  // Filter denuncias based on current map bounds
  useEffect(() => {
    if (!mapBounds) {
      // If no bounds are set yet, show all denuncias
      setVisibleDenuncias(denuncias);
      return;
    }

    // Add debouncing to avoid excessive filtering
    const debounceTimer = setTimeout(() => {
      // Filter denuncias to only those within current map bounds
      const inBoundsDenuncias = denuncias.filter(denuncia => {
        if (!denuncia.ubicacion || !denuncia.ubicacion.lat || !denuncia.ubicacion.lng) {
          return false;
        }
        
        const { lat, lng } = denuncia.ubicacion;
        return (
          lat <= mapBounds.north &&
          lat >= mapBounds.south &&
          lng <= mapBounds.east &&
          lng >= mapBounds.west
        );
      });
      
      
      setVisibleDenuncias(inBoundsDenuncias);
    }, 300); // 300ms debounce
    
    // Clean up timer if dependency changes before timeout
    return () => clearTimeout(debounceTimer);
  }, [denuncias, mapBounds]);

  // Listen for map view change and marker click events
  useEffect(() => {
    // Handle marker clicks
    const handleMarkerClicked = (event) => {
      if (event.detail && event.detail.id) {
        setHighlightedDenuncia(event.detail.id);
        
        // Find the table row and highlight it
        const row = document.querySelector(`[data-id="${event.detail.id}"]`);
        if (row) {
          row.scrollIntoView({ behavior: 'smooth', block: 'center' });
          row.classList.add('bg-hyc-gold/10');
          setTimeout(() => {
            row.classList.remove('bg-hyc-gold/10');
          }, 2000);
        }
        
        // Navigate to denuncia detail page
        navigate(`/denuncia/${event.detail.id}`);
      }
    };
    
    // Handle map view changes
    const handleMapViewChanged = (event) => {
      if (event.detail && event.detail.bounds) {
        setMapBounds(event.detail.bounds);
      }
    };
    
    // Add event listeners
    document.addEventListener('marker-clicked', handleMarkerClicked);
    document.addEventListener('map-view-changed', handleMapViewChanged);
    
    // Clean up event listeners
    return () => {
      document.removeEventListener('marker-clicked', handleMarkerClicked);
      document.removeEventListener('map-view-changed', handleMapViewChanged);
    };
  }, []);

  // Request user's location on button click
  const requestUserLocation = () => {
    setIsUsingCurrentLocation(true);
    setLoading(true);
    
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Get user coordinates
          const userCoords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          
          setUserLocation(userCoords);
          setLocationError(null);
          
          // Notify map to center and zoom in on user location
          const event = new CustomEvent('center-on-user', {
            detail: {
              center: userCoords,
              preserveZoom: false, // Don't preserve zoom - force zoom in
              zoomLevel: 15 // Zoom level for showing local area clearly
            }
          });
          document.dispatchEvent(event);
        },
        (err) => {
          
          setLocationError("No se pudo obtener tu ubicación. Por favor, verifica los permisos de ubicación en tu navegador.");
          setLoading(false);
          setIsUsingCurrentLocation(false);
        }
      );
    } else {
      setLocationError("Tu navegador no soporta geolocalización.");
      setLoading(false);
      setIsUsingCurrentLocation(false);
    }
  };

  // Helper function to format denuncia number
  const formatDenunciaNumber = (numero) => {
    if (numero === undefined || numero === null) return '00000';
    return numero.toString().padStart(5, '0');
  };

  // Load initial denuncias for Peru
  // Check authentication status
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);
  
  // Check if user is authenticated
  useEffect(() => {
    const checkAuth = () => {
      
      const authToken = localStorage.getItem('authToken');
      const authTokensStr = localStorage.getItem('authTokens');
      const user = localStorage.getItem('user');
      
      let authenticated = false;
      
      // Check tokens and verify they're not expired
      if (authTokensStr) {
        try {
          const authTokens = JSON.parse(authTokensStr);
          
          
          // Check if tokens include expiration time
          if (authTokens.expiresAt) {
            // Compare with current time
            const now = Date.now();
            const expiresAt = authTokens.expiresAt;
            
            
            if (now < expiresAt) {
              authenticated = true;
            } else {
              // Clear expired tokens
              
              localStorage.removeItem('authTokens');
            }
          } else {
            // Fallback if no expiration is stored
            
            authenticated = true;
          }
        } catch (e) {
          
        }
      } else if (authToken && user) {
        // Fallback to legacy auth token
        
        authenticated = true;
      }
      
      
      setIsAuthenticated(authenticated);
      setAuthChecked(true);
    };
    
    // Initial check
    checkAuth();
    
    // Listen for auth status changes (like logout from Header)
    const handleAuthChange = () => {
      checkAuth();
    };
    
    window.addEventListener('auth-status-changed', handleAuthChange);
    
    return () => {
      window.removeEventListener('auth-status-changed', handleAuthChange);
    };
  }, []);
  
  useEffect(() => {
    // Don't fetch data if auth check hasn't completed
    if (!authChecked) return;
    
    // If not authenticated, just set error and return
    if (!isAuthenticated) {
      setLoading(false);
      setError('Esta funcionalidad requiere iniciar sesión. Por favor, inicie sesión para acceder a las denuncias cerca de su ubicación.');
      return;
    }
    
    const fetchInitialDenuncias = async () => {
      try {
        // Add authorization header if we have a token
        const headers = {};
        const authToken = localStorage.getItem('authToken');
        const authTokensStr = localStorage.getItem('authTokens');
        
        if (authToken) {
          headers.Authorization = `Bearer ${authToken}`;
        } else if (authTokensStr) {
          try {
            const tokens = JSON.parse(authTokensStr);
            if (tokens.accessToken) {
              headers.Authorization = `Bearer ${tokens.accessToken}`;
            } else if (tokens.idToken) {
              headers.Authorization = `Bearer ${tokens.idToken}`;
            }
          } catch (e) {
            
          }
        }
        
        const response = await axios.get(`${config.apiUrl}/denuncias/nearby`, {
          headers,
          params: {
            lat: peruCenterLocation.lat,
            lng: peruCenterLocation.lng,
            radius: 500 // larger radius to cover more of Peru
          }
        });
        
        if (response.data && response.data.data) {
          
          
          
          // Add mock locations for testing if needed
          const enrichedData = response.data.data.map(item => {
            // Create a processed item with defaults for missing fields
            const processedItem = {
              ...item,
              _id: item._id || `temp-${Math.random().toString(36).substr(2, 9)}`,
              tipo: item.tipo || 'No especificado',
              descripcion: item.descripcion || 'Sin descripción',
              fecha_ocurrencia: item.fecha_ocurrencia || new Date().toISOString(),
              distrito_ocurrencia: item.distrito_ocurrencia || 'No especificado',
              provincia_ocurrencia: item.provincia_ocurrencia || 'No especificado',
              departamento_ocurrencia: item.departamento_ocurrencia || 'No especificado',
              // Format numero_denuncia correctly
              numero_denuncia: item.numero_denuncia || null,
              
              // Properly handle denunciados array with fallback
              denunciados: Array.isArray(item.denunciados) && item.denunciados.length > 0
                ? item.denunciados.map(d => {
                    
                    
                  
                    // UPDATED: Since the backend now properly returns populated objects,
                    // we no longer need the HOTFIX code to convert IDs to objects
                    
                    // Simply pass through the object, just ensure it has a nombre property
                    if (typeof d === 'object' && d !== null) {
                      
                      return d; // Use the properly populated object from API
                    } else if (typeof d === 'string') {
                      // For backwards compatibility, convert IDs to objects
                      const nombre = "Denunciado " + d.substring(d.length - 5);
                      
                      return {
                        _id: d,
                        nombre: nombre,
                        rango: ''
                      };
                    } else {
                      // Fallback for unexpected values
                      
                      return { 
                        nombre: 'No especificado', 
                        _id: d || Math.random().toString(),
                        rango: ''
                      };
                    }
                  })
                : item.denunciado 
                  ? [typeof item.denunciado === 'object' && item.denunciado !== null
                      ? item.denunciado
                      : { _id: item.denunciado, nombre: item.denunciado_nombre || "Denunciado " + item.denunciado.substring(item.denunciado.length - 5) || 'No especificado', rango: '' }]
                  : [{ nombre: 'No especificado' }]
            };
            
            // If the item doesn't have location, add a default one for testing
            if (!processedItem.ubicacion || !processedItem.ubicacion.lat || !processedItem.ubicacion.lng) {
              processedItem.ubicacion = {
                lat: peruCenterLocation.lat + (Math.random() * 2 - 1), // Random offset
                lng: peruCenterLocation.lng + (Math.random() * 2 - 1)  // Random offset
              };
            }
            
            return processedItem;
          });
          
          const data = enrichedData;
          
          setDenuncias(data);
          setVisibleDenuncias(data); // Initially all denuncias are visible
        } else {
          setDenuncias([]);
          setVisibleDenuncias([]);
        }
        setLoading(false);
      } catch (err) {
        
        setError("No se pudieron cargar las denuncias. Por favor, intenta nuevamente.");
        setLoading(false);
      }
    };

    fetchInitialDenuncias();
  }, []);

  // Fetch nearby denuncias when user location is available
  useEffect(() => {
    const fetchNearbyDenuncias = async () => {
      // Check if user is authenticated
      if (!isAuthenticated) {
        setError("Esta funcionalidad requiere iniciar sesión. Por favor, inicie sesión para acceder a las denuncias cerca de su ubicación.");
        setLoading(false);
        return;
      }
    
      if (!userLocation || !isUsingCurrentLocation) return;
      
      try {
        // Add authorization header if we have a token
        const headers = {};
        const authToken = localStorage.getItem('authToken');
        const authTokensStr = localStorage.getItem('authTokens');
        
        if (authToken) {
          headers.Authorization = `Bearer ${authToken}`;
        } else if (authTokensStr) {
          try {
            const tokens = JSON.parse(authTokensStr);
            if (tokens.accessToken) {
              headers.Authorization = `Bearer ${tokens.accessToken}`;
            } else if (tokens.idToken) {
              headers.Authorization = `Bearer ${tokens.idToken}`;
            }
          } catch (e) {
            
          }
        }
        
        const response = await axios.get(`${config.apiUrl}/denuncias/nearby`, {
          headers,
          params: {
            lat: userLocation.lat,
            lng: userLocation.lng,
            radius: 5 // radius in km
          }
        });
        
        if (response.data && response.data.data) {
          // Process the data the same way as in fetchInitialDenuncias
          const enrichedData = response.data.data.map(item => {
            // Create a processed item with defaults for missing fields
            const processedItem = {
              ...item,
              _id: item._id || `temp-${Math.random().toString(36).substr(2, 9)}`,
              tipo: item.tipo || 'No especificado',
              descripcion: item.descripcion || 'Sin descripción',
              fecha_ocurrencia: item.fecha_ocurrencia || new Date().toISOString(),
              distrito_ocurrencia: item.distrito_ocurrencia || 'No especificado',
              provincia_ocurrencia: item.provincia_ocurrencia || 'No especificado',
              departamento_ocurrencia: item.departamento_ocurrencia || 'No especificado',
              
              // Format numero_denuncia correctly
              numero_denuncia: item.numero_denuncia || null,
              
              // Properly handle denunciados array with fallback
              denunciados: Array.isArray(item.denunciados) && item.denunciados.length > 0
                ? item.denunciados.map(d => typeof d === 'object' ? d : { nombre: d || 'No especificado', _id: d })
                : item.denunciado 
                  ? [typeof item.denunciado === 'object' 
                      ? item.denunciado
                      : { _id: item.denunciado, nombre: item.denunciado_nombre || item.denunciado || 'No especificado', rango: '' }]
                  : [{ nombre: 'No especificado' }]
            };
            
            // If the item doesn't have location, add a default one for testing
            if (!processedItem.ubicacion || !processedItem.ubicacion.lat || !processedItem.ubicacion.lng) {
              processedItem.ubicacion = {
                lat: userLocation.lat + (Math.random() * 0.01), // Smaller random offset
                lng: userLocation.lng + (Math.random() * 0.01)  // Smaller random offset
              };
            }
            
            return processedItem;
          });
          
          setDenuncias(enrichedData);
          setVisibleDenuncias(enrichedData);
          
        } else {
          setDenuncias([]);
          setVisibleDenuncias([]);
        }
        setLoading(false);
      } catch (err) {
        
        setError("No se pudieron cargar las denuncias cercanas. Por favor, intenta nuevamente.");
        setLoading(false);
      }
    };

    fetchNearbyDenuncias();
  }, [userLocation, isUsingCurrentLocation]);

  // Calculate distance between two coordinates in km
  const getDistance = (lat1, lon1, lat2, lon2) => {
    if (!lat1 || !lon1 || !lat2 || !lon2) return null;
    
    const R = 6371; // Earth's radius in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c; // Distance in km
    
    return distance.toFixed(1);
  };

  // Format date to a readable format
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };

  return (
    <Layout 
      title="Denuncias Cerca de Mi"
      subtitle="Explora casos reportados en tu área"
      fullWidth={true} // Use full width for better space usage
      titleSize="text-2xl" // Smaller title size
    >
      <div className="flex flex-col"> {/* Container without fixed height for better table display */}
        {!isAuthenticated && (
          <div className="bg-hyc-accent/20 border border-hyc-accent/30 rounded-lg p-4 mb-4">
            <div className="flex items-center">
              <WarningIcon className="text-hyc-accent text-xl mr-3" />
              <div>
                <h3 className="text-hyc-light font-medium">Acceso restringido</h3>
                <p className="text-hyc-light/80 text-sm">
                  Esta función requiere iniciar sesión para proteger la privacidad de la ubicación.
                </p>
                <div className="mt-3">
                  <Link to="/login">
                    <Button variant="outline" size="sm">
                      Iniciar Sesión
                    </Button>
                  </Link>
                  <Link to="/registro" className="ml-3">
                    <Button variant="primary" size="sm">
                      Registrarse
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {/* Location Status */}
        <div className="bg-hyc-secondary rounded-lg p-4 mb-4">
          <div className="flex items-start flex-wrap">
            <MapIcon className="text-hyc-gold text-xl mr-4 mt-1" />
            <div className="flex-grow">
              <h3 className="text-hyc-gold text-lg font-bold mb-2">Ubicación</h3>
              {locationError ? (
                <div>
                  <p className="text-hyc-accent mb-3">{locationError}</p>
                  <p className="text-hyc-light">Mostrando denuncias en todo el Perú.</p>
                </div>
              ) : isUsingCurrentLocation && loading && !userLocation ? (
                <p className="text-hyc-light">Obteniendo tu ubicación...</p>
              ) : isUsingCurrentLocation && userLocation ? (
                <p className="text-hyc-light">
                  Ubicación obtenida correctamente. Mostrando denuncias en un radio de 5km.
                </p>
              ) : (
                <p className="text-hyc-light">
                  Mostrando denuncias en todo el Perú. Para ver denuncias cercanas a ti, haz clic en el botón "Usar Mi Ubicación".
                </p>
              )}
            </div>
            {!isUsingCurrentLocation && (
              <button 
                onClick={requestUserLocation}
                className="mt-4 md:mt-0 bg-hyc-gold hover:bg-hyc-gold/80 text-hyc-dark font-medium py-2 px-4 rounded-lg flex items-center transition-colors"
              >
                <LocationIcon className="mr-2" />
                Usar Mi Ubicación
              </button>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-4 flex-grow overflow-hidden">
          {/* Map Display - Fixed height on all devices */}
          <div className="md:w-2/5 h-[500px] bg-hyc-secondary rounded-lg overflow-hidden shadow-lg">
            <SimpleMap 
              position={isUsingCurrentLocation && userLocation ? userLocation : peruCenterLocation} 
              denuncias={denuncias.filter(d => d.ubicacion && d.ubicacion.lat && d.ubicacion.lng)}
              onMarkerClick={(denunciaId) => {
                navigate(`/denuncia/${denunciaId}`);
              }}
            />
          </div>

          {/* Denuncias List - Takes more width */}
          <div className="md:w-3/5 flex-grow overflow-auto bg-hyc-secondary rounded-lg">
            <div className="p-4">
              <h3 className="text-xl font-mono text-hyc-light mb-4">Denuncias</h3>
              
              {loading ? (
                <div className="text-center py-10">
                  <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-hyc-gold"></div>
                  <p className="text-hyc-light mt-4">Buscando denuncias cercanas...</p>
                </div>
              ) : error ? (
                <div className="text-center py-10">
                  <p className="text-hyc-accent mb-4">{error}</p>
                  <button 
                    onClick={() => window.location.reload()}
                    className="bg-hyc-gold hover:bg-hyc-gold/80 text-hyc-dark font-bold py-2 px-4 rounded-lg transition-colors"
                  >
                    Reintentar
                  </button>
                </div>
              ) : denuncias.length === 0 ? (
                <div className="text-center py-10">
                  <WarningIcon className="text-hyc-accent text-4xl mb-4" />
                  <p className="text-hyc-light mb-4">No se encontraron denuncias cercanas a tu ubicación.</p>
                  <Link
                    to="/explorar"
                    className="bg-hyc-gold hover:bg-hyc-gold/80 text-hyc-dark font-bold py-2 px-4 rounded-lg transition-colors inline-block"
                  >
                    Ver todas las denuncias
                  </Link>
                </div>
              ) : visibleDenuncias.length === 0 && denuncias.length > 0 ? (
                <div className="text-center py-10">
                  <WarningIcon className="text-hyc-accent text-4xl mb-4" />
                  <p className="text-hyc-light mb-4">No hay denuncias visibles en el área del mapa actual.</p>
                  <p className="text-hyc-light mb-4">Hay {denuncias.length} denuncias en total. Prueba a ajustar la vista del mapa.</p>
                </div>
              ) : (
                <Table
                  columns={[
                    {
                      key: 'tipo',
                      header: (
                        <span className="flex items-center text-left font-medium text-hyc-light/80">
                          <TagIcon className="mr-1" />
                          Tipo
                        </span>
                      ),
                      render: (tipo, row) => (
                        <span 
                          className={`inline-block px-2 py-1 rounded-full text-xs font-medium
                            ${tipo === 'Corrupción' ? 'bg-red-500/20 text-red-400' : 
                            tipo === 'Abuso de Autoridad' ? 'bg-orange-500/20 text-orange-400' :
                            tipo === 'Extorsión' ? 'bg-yellow-500/20 text-yellow-400' :
                            tipo === 'Negligencia' ? 'bg-green-500/20 text-green-400' :
                            tipo === 'Discriminación' ? 'bg-blue-500/20 text-blue-400' :
                            tipo === 'Violencia' ? 'bg-purple-500/20 text-purple-400' :
                            'bg-hyc-accent/20 text-hyc-accent'}`}
                        >
                          {tipo || 'Sin categoría'}
                        </span>
                      ),
                      sortable: true,
                      width: "12%",
                    },
                    {
                      key: 'info',
                      header: (
                        <span className="flex items-center text-left font-medium text-hyc-light/80">
                          <InfoCircleIcon className="mr-1" />
                          Info
                        </span>
                      ),
                      render: (_, row) => (
                        <div className="flex flex-col">
                          <div className="flex items-center mb-1">
                            <div className="relative group cursor-help">
                              <InfoCircleIcon className="text-hyc-light/70 hover:text-hyc-gold transition-colors mr-1" />
                              
                              {/* Tooltip with description preview */}
                              <div className="absolute left-0 top-6 w-64 p-3 bg-hyc-dark border border-hyc-light/20 rounded shadow-lg 
                                            opacity-0 group-hover:opacity-100 invisible group-hover:visible transition-opacity duration-150 z-50 pointer-events-none">
                                <div className="text-xs text-hyc-light/90 max-h-[200px] overflow-y-auto">{row.descripcion || 'Sin descripción'}</div>
                              </div>
                            </div>
                            <span className="text-sm font-medium text-hyc-light">
                              #{formatDenunciaNumber(row.numero_denuncia)}
                            </span>
                          </div>
                          {row.fecha_ocurrencia && (
                            <div className="text-xs text-hyc-light/70 flex items-center">
                              <CalendarIcon className="mr-1 text-hyc-light/50 h-3 w-3" />
                              <span>{new Date(row.fecha_ocurrencia).toLocaleDateString('es-PE')}</span>
                            </div>
                          )}
                        </div>
                      ),
                      sortable: true, // Make this column sortable so we can sort by numero_denuncia
                      width: "23%",
                    },
                    {
                      key: 'ubicacion',
                      header: (
                        <span className="flex items-center text-left font-medium text-hyc-light/80">
                          <LocationIcon className="mr-1" />
                          Ubicación
                        </span>
                      ),
                      render: (_, row) => (
                        <div className="flex flex-col">
                          {(row.distrito_ocurrencia || row.location_detalle) ? (
                            <>
                              <div className="font-medium flex items-center">
                                <LocationIcon className="mr-1 text-hyc-light/60" />
                                <span className="truncate">{row.location_detalle || row.distrito_ocurrencia}</span>
                              </div>
                              <div className="text-xs text-hyc-light/70 mt-1 truncate">
                                {row.provincia_ocurrencia}, {row.departamento_ocurrencia}
                              </div>
                              {isUsingCurrentLocation && userLocation && row.ubicacion && (
                                <div className="text-xs text-hyc-gold mt-1">
                                  {getDistance(userLocation.lat, userLocation.lng, row.ubicacion.lat, row.ubicacion.lng)} km
                                </div>
                              )}
                            </>
                          ) : (
                            <span className="text-hyc-light/50">No especificado</span>
                          )}
                        </div>
                      ),
                      sortable: false,
                      width: "35%",
                    },
                    {
                      key: 'denunciados',
                      header: (
                        <span className="flex items-center text-left font-medium text-hyc-light/80">
                          <UserIcon className="mr-1" />
                          Denunciados
                        </span>
                      ),
                      render: (_, row) => (
                        <div className="flex flex-col">
                          {Array.isArray(row.denunciados) && row.denunciados.length > 0 ? (
                            <>
                              {row.denunciados.map((denunciado, index) => {
                                
                                return (
                                  <div key={index} className={`text-hyc-light truncate ${index === 0 ? 'font-medium' : 'text-sm mt-1.5'}`}>
                                    {denunciado?.nombre || (typeof denunciado === 'string' ? denunciado : 'No especificado')}
                                  </div>
                                );
                              })}
                              {row.denunciados[0]?.rango && (
                                <div className="text-xs text-hyc-light/70 truncate mt-1">
                                  {row.denunciados[0].rango}
                                </div>
                              )}
                              {row.denunciados[0]?.entidad && (
                                <div className="text-xs text-hyc-light/70 truncate">
                                  {row.denunciados[0].entidad}
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="font-medium text-hyc-light truncate">
                              No especificado
                            </div>
                          )}
                        </div>
                      ),
                      sortable: true,
                      width: "20%",
                    },
                    {
                      key: 'actions',
                      header: '',
                      render: (_, row) => (
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/denuncia/${row._id}`);
                          }}
                        >
                          Ver
                        </Button>
                      ),
                      width: "10%",
                    },
                  ]}
                  data={visibleDenuncias}
                  onRowClick={(row) => {
                    setHighlightedDenuncia(row._id);
                    
                    // Find and trigger the corresponding map marker
                    const mapContainer = document.getElementById('simple-map');
                    if (mapContainer && row.ubicacion) {
                      const markerId = `marker-${row._id}`;
                      const event = new CustomEvent('highlight-marker', { 
                        detail: { id: row._id, ubicacion: row.ubicacion }
                      });
                      mapContainer.dispatchEvent(event);
                    }
                    
                    // Navigate to denuncia detail page like in ExplorePage
                    navigate(`/denuncia/${row._id}`);
                  }}
                  defaultSortKey="numero_denuncia"
                  defaultSortOrder="desc"
                  striped
                  hoverable
                  className="table-fixed"
                />
              )}
            </div>
          </div>
        </div>
      
        {/* Call to Action */}
        <div className="mt-4 text-center py-4 border-t border-hyc-light/10">
          <h3 className="text-hyc-gold text-xl font-bold mb-2">¿Conoces un caso de corrupción?</h3>
          <Link to="/denunciaform" className="bg-hyc-accent hover:bg-hyc-accent/80 text-white font-bold py-2 px-6 rounded-lg transition-colors inline-block">
            Enviar una Denuncia
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default CercaDeMiPage;