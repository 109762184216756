import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Layout from './Layout';
import Card from './Card';
import Button from './Button';
import { UserIcon, MapIcon, EditIcon, EmailIcon, MobileIcon, CalendarIcon, InfoCircleIcon } from './Icons';

const ProfilePage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Check if user is authenticated and get user data
  useEffect(() => {
    const checkAuth = () => {
      // Try to get auth data from localStorage
      const userJson = localStorage.getItem('user');
      const authToken = localStorage.getItem('authToken');
      const authTokensStr = localStorage.getItem('authTokens');
      
      let authenticated = false;
      
      // Validate tokens
      if (authTokensStr) {
        try {
          const authTokens = JSON.parse(authTokensStr);
          if (authTokens.expiresAt && Date.now() < authTokens.expiresAt) {
            authenticated = true;
          }
        } catch (e) {
          console.error('Error parsing tokens:', e);
        }
      } else if (authToken && userJson) {
        authenticated = true;
      }
      
      // If not authenticated, redirect to login
      if (!authenticated) {
        setError('Necesitas iniciar sesión para ver tu perfil');
        setLoading(false);
        
        // Redirect after a short delay
        setTimeout(() => navigate('/login'), 1500);
        return;
      }
      
      // Get user data from localStorage
      if (userJson) {
        try {
          const userData = JSON.parse(userJson);
          console.log('User data from localStorage:', userData);
          
          // Handle MongoDB date format - convert any special date formats
          if (userData.created_at && typeof userData.created_at === 'object' && userData.created_at.$date) {
            userData.created_at = userData.created_at.$date;
          }
          
          // Handle MongoDB format for cognito dates if present
          if (userData.cognito && userData.cognito.issued_at && 
              typeof userData.cognito.issued_at === 'object' && userData.cognito.issued_at.$date) {
            userData.cognito.issued_at = userData.cognito.issued_at.$date;
          }
          
          // Set a fixed created_at date for testing if missing
          if (!userData.created_at) {
            userData.created_at = "2025-03-11T06:43:30.906Z";
            console.log('Using fixed created_at date for testing');
          }
          
          setUser(userData);
          setLoading(false);
        } catch (e) {
          console.error('Error parsing user data:', e);
          setError('Error al cargar datos de usuario');
          setLoading(false);
        }
      } else {
        setError('No se encontraron datos de usuario');
        setLoading(false);
      }
    };
    
    checkAuth();
  }, [navigate]);
  
  // Format date helper - displays only date (not time)
  const formatDate = (dateString) => {
    if (!dateString) return 'No disponible';
    
    try {
      // Log the original date for debugging
      console.log('Formatting date:', dateString);
      
      // Handle MongoDB date format if present
      let actualDateString = dateString;
      if (typeof dateString === 'object') {
        if (dateString.$date) {
          actualDateString = dateString.$date;
          console.log('Extracted MongoDB date:', actualDateString);
        } else {
          // If it's a Date object, it's already handled correctly
          console.log('Date is already a Date object');
        }
      }
      
      const date = new Date(actualDateString);
      
      // Check if the date is valid
      if (isNaN(date.getTime())) {
        console.warn('Invalid date format:', actualDateString);
        return 'No disponible';
      }
      
      // Check if date appears to be today's date but shouldn't be
      const today = new Date();
      const isFutureDate = date > today;
      
      if (isFutureDate) {
        console.warn('Date appears to be in the future, which is likely incorrect:', actualDateString);
      }
      
      // Format the date in Spanish format: day month year (e.g., "15 de marzo de 2025")
      return date.toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (e) {
      console.error('Error formatting date:', e, dateString);
      return 'Fecha inválida';
    }
  };
  
  if (loading) {
    return (
      <Layout>
        <div className="max-w-4xl mx-auto px-4 py-8">
          <div className="flex justify-center items-center min-h-[200px]">
            <div className="flex space-x-2">
              <div className="w-3 h-3 bg-hyc-gold rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
              <div className="w-3 h-3 bg-hyc-gold rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
              <div className="w-3 h-3 bg-hyc-gold rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
  
  if (error) {
    return (
      <Layout>
        <div className="max-w-4xl mx-auto px-4 py-8">
          <Card className="p-6 border-l-4 border-hyc-accent">
            <div className="flex items-start">
              <InfoCircleIcon className="text-hyc-accent mr-3 flex-shrink-0 mt-1" />
              <div>
                <h3 className="text-xl font-medium text-hyc-light mb-2">Error</h3>
                <p className="text-hyc-light/80">{error}</p>
                <Link to="/login" className="mt-4 inline-block">
                  <Button variant="primary" size="sm">Iniciar Sesión</Button>
                </Link>
              </div>
            </div>
          </Card>
        </div>
      </Layout>
    );
  }
  
  return (
    <Layout>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-mono font-bold text-hyc-light mb-6">Mi Perfil</h1>
        
        {/* User profile card */}
        <Card className="mb-8">
          <div className="relative">
            {/* Cover photo */}
            <div className="h-40 bg-gradient-to-r from-hyc-dark via-hyc-secondary to-hyc-dark rounded-t-lg"></div>
            
            {/* Profile picture and name */}
            <div className="px-6 pb-6">
              <div className="flex flex-col sm:flex-row sm:items-end -mt-12 sm:-mt-16 mb-6">
                <div className="w-24 h-24 sm:w-32 sm:h-32 bg-hyc-secondary rounded-full border-4 border-hyc-dark flex items-center justify-center overflow-hidden">
                  <UserIcon className="w-16 h-16 sm:w-20 sm:h-20 text-hyc-gold" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 sm:mb-2">
                  <h2 className="text-2xl font-mono font-bold text-hyc-light">
                    {user?.nickname || user?.apodo || 'Usuario'}
                  </h2>
                  <p className="text-hyc-light/60 mt-1">
                    Miembro desde {formatDate(user?.created_at || new Date())}
                  </p>
                </div>
              </div>
              
              {/* Contact info */}
              <div className="border-t border-hyc-light/10 pt-6 mt-6">
                <h3 className="text-lg font-medium text-hyc-light mb-4">Información de Contacto</h3>
                
                <div className="space-y-4">
                  {user?.email && (
                    <div className="flex items-start">
                      <EmailIcon className="text-hyc-gold mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <p className="text-sm text-hyc-light/60">Correo Electrónico</p>
                        <p className="text-hyc-light">{user.email}</p>
                      </div>
                    </div>
                  )}
                  
                  {user?.telefono && (
                    <div className="flex items-start">
                      <MobileIcon className="text-hyc-gold mr-3 flex-shrink-0 mt-1" />
                      <div>
                        <p className="text-sm text-hyc-light/60">Teléfono</p>
                        <p className="text-hyc-light">{user.telefono}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              
              {/* Location info */}
              <div className="border-t border-hyc-light/10 pt-6 mt-6">
                <h3 className="text-lg font-medium text-hyc-light mb-4">Ubicación</h3>
                
                <div className="flex items-start">
                  <MapIcon className="text-hyc-gold mr-3 flex-shrink-0 mt-1" />
                  <div>
                    <p className="text-sm text-hyc-light/60">Región</p>
                    <p className="text-hyc-light">
                      {user?.departamento || 'No especificado'}{user?.provincia ? `, ${user.provincia}` : ''}
                      {user?.distrito ? `, ${user.distrito}` : ''}
                    </p>
                  </div>
                </div>
              </div>
              
              {/* Account details */}
              <div className="border-t border-hyc-light/10 pt-6 mt-6">
                <h3 className="text-lg font-medium text-hyc-light mb-4">Detalles de la Cuenta</h3>
                
                <div className="flex items-start">
                  <CalendarIcon className="text-hyc-gold mr-3 flex-shrink-0 mt-1" />
                  <div>
                    <p className="text-sm text-hyc-light/60">Último Acceso</p>
                    <p className="text-hyc-light">
                      {formatDate(user?.lastLogin || user?.cognito?.issued_at || user?.updatedAt || new Date())}
                    </p>
                  </div>
                </div>
              </div>
              
              {/* Actions */}
              <div className="border-t border-hyc-light/10 pt-6 mt-6 flex justify-center">
                <Link to="/mis-denuncias">
                  <Button variant="primary" size="lg">
                    Ver Mis Denuncias
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Card>
        
        {/* Additional info */}
        <Card className="p-6">
          <h3 className="text-lg font-medium text-hyc-light mb-4">Información Adicional</h3>
          
          <p className="text-hyc-light/80 mb-4">
            Tu perfil se utiliza para personalizar tu experiencia y facilitar la gestión de denuncias en HonoryCoima.
          </p>
          
          <div className="bg-hyc-dark/50 p-4 rounded-lg border border-hyc-light/10">
            <h4 className="text-hyc-gold font-medium mb-2">Nota sobre la privacidad</h4>
            <p className="text-hyc-light/80 text-sm">
              Tus datos personales están protegidos y no se comparten con terceros. 
              Tu correo electrónico y teléfono solo se utilizan para verificación y 
              notificaciones relacionadas con tus denuncias.
            </p>
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default ProfilePage;