import React from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';
import config from '../config';

/**
 * Layout component for consistent page structure
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Page content
 * @param {string} [props.title=''] - Page title
 * @param {string} [props.subtitle=''] - Page subtitle
 * @param {boolean} [props.fullWidth=false] - Whether to use full width
 * @param {React.ReactNode} [props.header=null] - Custom header content
 * @param {React.ReactNode} [props.footer=null] - Custom footer content
 * @param {boolean} [props.hideHeader=false] - Whether to hide the header
 * @param {string} [props.titleSize='text-3xl md:text-4xl'] - Title font size class
 */
const Layout = ({
  children,
  title = '',
  subtitle = '',
  fullWidth = false,
  header = null,
  footer = null,
  hideHeader = false,
  titleSize = 'text-3xl md:text-4xl',
}) => {
  return (
    <div className="min-h-screen bg-hyc-dark flex flex-col">
      {/* Header */}
      {!hideHeader && (header || <Header />)}
      
      {/* Page Title (if provided) */}
      {(title || subtitle) && (
        <div className="border-b border-hyc-light/10 py-4 md:py-6">
          <div className={`container mx-auto px-4 ${fullWidth ? 'w-full' : 'max-w-6xl'}`}>
            {title && (
              <h1 className={`font-mono text-hyc-gold ${titleSize} !leading-normal`}>{title}</h1>
            )}
            {subtitle && (
              <h3 className="text-hyc-light text-sm md:text-base mt-1 md:mt-2">{subtitle}</h3>
            )}
          </div>
        </div>
      )}
      
      {/* Main content */}
      <main className="flex-grow py-4 md:py-6">
        <div className={`container mx-auto px-4 ${fullWidth ? 'w-full' : 'max-w-6xl'}`}>
          {children}
        </div>
      </main>
      
      {/* Footer */}
      {footer || (
        <footer className="py-6 md:py-8 border-t border-hyc-light/10">
          <div className={`container mx-auto px-4 ${fullWidth ? 'w-full' : 'max-w-6xl'}`}>
            <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
              <div>
                <Link to="/" className="flex items-center mb-4">
                  <div className="font-mono text-xl">
                    <span className="text-hyc-gold">Honor</span>
                    <span className="text-hyc-light">y</span>
                    <span className="text-hyc-accent">Coima</span>
                    <span className="text-hyc-light">.org</span>
                  </div>
                </Link>
                <p className="text-hyc-light/60 text-sm">
                  Plataforma ciudadana para denunciar actos de corrupción
                </p>
              </div>
              
              <div className="flex flex-col space-y-2">
                <Link to="/explorar" className="text-hyc-light hover:text-hyc-gold transition-colors">
                  Explorar Denuncias
                </Link>
                <Link to="/cercademi" className="text-hyc-light hover:text-hyc-gold transition-colors">
                  Cerca de Mi
                </Link>
                <Link to="/denunciaform" className="text-hyc-light hover:text-hyc-gold transition-colors">
                  Enviar Denuncia
                </Link>
                <Link to="/terminos" className="text-hyc-light hover:text-hyc-gold transition-colors">
                  Términos y Condiciones
                </Link>
              </div>
              
              <div className="flex flex-col space-y-2">
                <a href="mailto:hola@honorycoima.org" className="text-hyc-light hover:text-hyc-gold transition-colors">
                  hola@honorycoima.org
                </a>
                <p className="text-hyc-light/60 text-center text-sm">
                  Honor y Coima © {new Date().getFullYear()}
                </p>
                <p className="text-hyc-light/30 text-center text-xs">
                  v{config.version}
                </p>
              </div>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
};

export default Layout;