import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faLocationArrow,
  faTag,
  faLink,
  faAlignLeft,
  faTrash,
  faPlusCircle,
  faUser,
  faMapMarkerAlt,
  faEdit,
  faEye,
  faInfo,
  faInfoCircle,
  faExclamationTriangle,
  faCheck,
  faTimes,
  faArrowLeft,
  faArrowRight,
  faExchangeAlt,
  faLock,
  faShieldAlt,
  faMobileAlt,
  faEnvelope,
  faQuestionCircle,
  faRedo,
  faSync,
  faFile,
  faImage,
  faVideo,
  faMusic,
  faFileUpload,
  faPaperclip,
  faSearch,
  faFilter,
  faChevronLeft,
  faChevronRight,
  faExpand,
  faCompress,
  faBars,
} from '@fortawesome/free-solid-svg-icons';

// Re-export icons with consistent naming
export const CalendarIcon = (props) => <FontAwesomeIcon icon={faCalendarAlt} {...props} />;
export const LocationIcon = (props) => <FontAwesomeIcon icon={faLocationArrow} {...props} />;
export const TagIcon = (props) => <FontAwesomeIcon icon={faTag} {...props} />;
export const LinkIcon = (props) => <FontAwesomeIcon icon={faLink} {...props} />;
export const TextIcon = (props) => <FontAwesomeIcon icon={faAlignLeft} {...props} />;
export const DeleteIcon = (props) => <FontAwesomeIcon icon={faTrash} {...props} />;
export const AddIcon = (props) => <FontAwesomeIcon icon={faPlusCircle} {...props} />;
export const UserIcon = (props) => <FontAwesomeIcon icon={faUser} {...props} />;
export const MapIcon = (props) => <FontAwesomeIcon icon={faMapMarkerAlt} {...props} />;
export const EditIcon = (props) => <FontAwesomeIcon icon={faEdit} {...props} />;
export const ViewIcon = (props) => <FontAwesomeIcon icon={faEye} {...props} />;
export const InfoIcon = (props) => <FontAwesomeIcon icon={faInfo} {...props} />;
export const InfoCircleIcon = (props) => <FontAwesomeIcon icon={faInfoCircle} {...props} />;
export const WarningIcon = (props) => <FontAwesomeIcon icon={faExclamationTriangle} {...props} />;
export const CheckIcon = (props) => <FontAwesomeIcon icon={faCheck} {...props} />;
export const CloseIcon = (props) => <FontAwesomeIcon icon={faTimes} {...props} />;
export const ArrowLeftIcon = (props) => <FontAwesomeIcon icon={faArrowLeft} {...props} />;
export const ArrowRightIcon = (props) => <FontAwesomeIcon icon={faArrowRight} {...props} />;
export const SwitchIcon = (props) => <FontAwesomeIcon icon={faExchangeAlt} {...props} />;
export const LockIcon = (props) => <FontAwesomeIcon icon={faLock} {...props} />;
export const ShieldIcon = (props) => <FontAwesomeIcon icon={faShieldAlt} {...props} />;
export const MobileIcon = (props) => <FontAwesomeIcon icon={faMobileAlt} {...props} />;
export const EmailIcon = (props) => <FontAwesomeIcon icon={faEnvelope} {...props} />;
export const QuestionCircleIcon = (props) => <FontAwesomeIcon icon={faQuestionCircle} {...props} />;
export const RefreshIcon = (props) => <FontAwesomeIcon icon={faSync} {...props} />;
export const FileIcon = (props) => <FontAwesomeIcon icon={faFile} {...props} />;
export const ImageIcon = (props) => <FontAwesomeIcon icon={faImage} {...props} />;
export const VideoIcon = (props) => <FontAwesomeIcon icon={faVideo} {...props} />;
export const AudioIcon = (props) => <FontAwesomeIcon icon={faMusic} {...props} />;
export const FileUploadIcon = (props) => <FontAwesomeIcon icon={faFileUpload} {...props} />;
export const AttachmentIcon = (props) => <FontAwesomeIcon icon={faPaperclip} {...props} />;
export const SearchIcon = (props) => <FontAwesomeIcon icon={faSearch} {...props} />;
export const FilterIcon = (props) => <FontAwesomeIcon icon={faFilter} {...props} />;
export const ChevronLeftIcon = (props) => <FontAwesomeIcon icon={faChevronLeft} {...props} />;
export const ChevronRightIcon = (props) => <FontAwesomeIcon icon={faChevronRight} {...props} />;
export const XIcon = (props) => <FontAwesomeIcon icon={faTimes} {...props} />;
export const ExpandIcon = (props) => <FontAwesomeIcon icon={faExpand} {...props} />;
export const CompressIcon = (props) => <FontAwesomeIcon icon={faCompress} {...props} />;
export const MenuIcon = (props) => <FontAwesomeIcon icon={faBars} {...props} />;