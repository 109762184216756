import React from 'react';

/**
 * Reusable card component with consistent styling
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Card content
 * @param {string} [props.title=''] - Card title
 * @param {string} [props.variant='default'] - Card variant (default, outline, flat)
 * @param {string} [props.className=''] - Additional class names
 */
const Card = ({
  children,
  title = '',
  variant = 'default',
  className = '',
  ...rest
}) => {
  // Base styles for all cards
  const baseCardStyles = "rounded-lg overflow-hidden";
  
  // Variant-specific styles
  const variantStyles = {
    default: "bg-hyc-secondary border border-hyc-light/20 shadow-md",
    outline: "bg-transparent border border-hyc-light/30",
    flat: "bg-hyc-dark border-b border-hyc-light/10",
  };
  
  // Combine styles
  const cardStyles = `${baseCardStyles} ${variantStyles[variant] || variantStyles.default} ${className}`;

  return (
    <div className={cardStyles} {...rest}>
      {title && (
        <div className="border-b border-hyc-light/10 p-4">
          <h3 className="text-hyc-light font-mono text-xl">{title}</h3>
        </div>
      )}
      <div className="p-4">
        {children}
      </div>
    </div>
  );
};

export default Card;