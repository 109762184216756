// src/components/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import './SplashPage.css'; // Import the CSS for styling

const NotFound = () => {
  return (
    <div className="splash-container">
      <h1>
        <span className="text-honor">Honor</span>
        <span className="text-and">y</span>
        <span className="text-coima">Coima</span>
        <span className="text-org">.org</span>
      </h1>
      <h2>Error 404 - Página no encontrada... &nbsp;</h2>
      <Link to="/" className="home-button">Volver a la página principal</Link>
    </div>
  );
}

export default NotFound;
