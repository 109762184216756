import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

// Import components
import Layout from './Layout';
import Table from './Table';
import Card from './Card';
import Button from './Button';
import { 
  RefreshIcon, 
  LocationIcon, 
  CalendarIcon, 
  InfoCircleIcon, 
  TagIcon,
  UserIcon
} from './Icons';

// Type options with HYC-themed colors (same as ExplorePage)
const tipoOptions = [
  { value: 'Corrupción', label: 'Corrupción', color: 'border-hyc-accent bg-hyc-accent/20 text-hyc-accent' },
  { value: 'Abuso de Autoridad', label: 'Abuso de autoridad', color: 'border-hyc-orange bg-hyc-orange/20 text-hyc-orange' },
  { value: 'Extorsión', label: 'Extorsión', color: 'border-hyc-gold bg-hyc-gold/20 text-hyc-gold' },
  { value: 'Negligencia', label: 'Negligencia', color: 'border-hyc-highlight bg-hyc-highlight/20 text-hyc-highlight' },
  { value: 'Discriminación', label: 'Discriminación', color: 'border-hyc-light bg-hyc-light/20 text-hyc-light' },
  { value: 'Violencia', label: 'Violencia', color: 'border-hyc-accent bg-hyc-accent/20 text-hyc-accent' },
];

const MisDenunciasPage = () => {
  const navigate = useNavigate();
  const [denuncias, setDenuncias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  // Check if user is authenticated
  useEffect(() => {
    const checkAuth = () => {
      const authToken = localStorage.getItem('authToken');
      const authTokensStr = localStorage.getItem('authTokens');
      const userInfo = localStorage.getItem('user');
      
      let authenticated = false;
      
      // Validate tokens
      if (authTokensStr) {
        try {
          const authTokens = JSON.parse(authTokensStr);
          if (authTokens.expiresAt && Date.now() < authTokens.expiresAt) {
            authenticated = true;
          }
        } catch (e) {
          
        }
      } else if (authToken && userInfo) {
        authenticated = true;
      }
      
      setIsAuthenticated(authenticated);
      
      // If not authenticated, redirect to login
      if (!authenticated) {
        setTimeout(() => navigate('/login'), 500);
      }
    };
    
    checkAuth();
  }, [navigate]);
  
  // Fetch denuncias when page changes or on initial load
  useEffect(() => {
    if (!isAuthenticated) return;
    
    fetchDenuncias();
  }, [page, isAuthenticated]);
  
  const fetchDenuncias = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Add authorization header
      const headers = {};
      const authToken = localStorage.getItem('authToken');
      const authTokensStr = localStorage.getItem('authTokens');
      
      if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
      } else if (authTokensStr) {
        try {
          const authTokens = JSON.parse(authTokensStr);
          if (authTokens.accessToken) {
            headers.Authorization = `Bearer ${authTokens.accessToken}`;
          } else if (authTokens.idToken) {
            headers.Authorization = `Bearer ${authTokens.idToken}`;
          }
        } catch (e) {
          
        }
      }
      
      const response = await axios.get(`${config.apiUrl}/denuncias/mis-denuncias`, {
        headers,
        params: { page, limit: 20 }
      });
      
      if (response.data.success) {
        // Process data to ensure it has all required fields
        const processedDenuncias = (response.data.data || []).map(denuncia => {
          // Create a new object with all required fields
          const processed = {
            // Use default values for anything missing
            _id: denuncia._id || `temp-${Math.random().toString(36).substr(2, 9)}`,
            tipo: denuncia.tipo || 'No especificado',
            descripcion: denuncia.descripcion || 'Sin descripción',
            fecha_ocurrencia: denuncia.fecha_ocurrencia || new Date().toISOString(),
            distrito_ocurrencia: denuncia.distrito_ocurrencia || 'No especificado',
            provincia_ocurrencia: denuncia.provincia_ocurrencia || 'No especificado',
            departamento_ocurrencia: denuncia.departamento_ocurrencia || 'No especificado',
            numero_denuncia: denuncia.numero_denuncia || 0,
            
            // Ensure denunciados array is properly populated and has content
            denunciados: Array.isArray(denuncia.denunciados) && denuncia.denunciados.length > 0
              ? denuncia.denunciados.map(d => {
                  // Add detailed console logging to debug the data
                  
                  
                  
                  // UPDATED: Since the backend now properly returns populated objects,
                  // we don't need the HOTFIX code to convert IDs to objects
                  
                  // Simply pass through the object, just ensure it has a nombre property
                  if (typeof d === 'object' && d !== null) {
                    
                    return d; // Use the properly populated object from API
                  } else if (typeof d === 'string') {
                    // For backwards compatibility, convert IDs to objects
                    const nombre = "Denunciado " + d.substring(d.length - 5);
                    
                    return {
                      _id: d,
                      nombre: nombre,
                      rango: ''
                    };
                  } else {
                    // Fallback for unexpected values
                    
                    return { 
                      nombre: 'No especificado', 
                      _id: d || Math.random().toString(),
                      rango: ''
                    };
                  }
                })
              : denuncia.denunciado
                ? [typeof denuncia.denunciado === 'object' && denuncia.denunciado !== null
                    ? denuncia.denunciado
                    : { nombre: denuncia.denunciado_nombre || "Denunciado " + denuncia.denunciado.substring(denuncia.denunciado.length - 5) || 'No especificado', _id: denuncia.denunciado, rango: '' }]
                : [{ nombre: 'No especificado' }]
          };
          
          // Add any other original fields
          return { ...denuncia, ...processed };
        });
        
        setDenuncias(processedDenuncias);
        setTotalPages(response.data.totalPages || 1);
      } else {
        setError(response.data.message || 'Error al cargar denuncias');
      }
    } catch (err) {
      
      
      if (err.response && err.response.status === 401) {
        setError('Sesión expirada. Por favor inicie sesión nuevamente.');
        // Redirect to login after a brief delay
        setTimeout(() => navigate('/login'), 1500);
      } else {
        setError('Error al cargar denuncias. Por favor intente nuevamente.');
      }
    } finally {
      setLoading(false);
    }
  };
  
  const handleRowClick = (denuncia) => {
    navigate(`/denuncia/${denuncia._id}`);
  };
  
  const handleRefresh = () => {
    setPage(1);
    fetchDenuncias();
  };
  
  // Get tag color for denuncia type
  const getTypeColor = (type) => {
    const typeOption = tipoOptions.find(option => option.value === type);
    return typeOption ? typeOption.color : 'bg-gray-600';
  };
  
  // Table column definitions
  const columns = [
    {
      key: 'numero_denuncia',
      header: (
        <span className="flex items-center text-left font-medium text-hyc-light/80">
          #
        </span>
      ),
      render: (numero) => (
        <span className="font-mono text-hyc-light">
          {numero ? numero.toString().padStart(5, '0') : '00000'}
        </span>
      ),
      sortable: true,
      width: "8%",
    },
    {
      key: 'tipo',
      header: (
        <span className="flex items-center text-left font-medium text-hyc-light/80">
          Tipo
        </span>
      ),
      render: (tipo) => (
        <span 
          className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium border ${getTypeColor(tipo) || 'border-gray-500 bg-gray-500/20 text-gray-400'}`}
        >
          {tipo || 'No especificado'}
        </span>
      ),
      sortable: false,
      width: "15%",
    },
    {
      key: 'denunciados',
      header: (
        <span className="flex items-center text-left font-medium text-hyc-light/80">
          Denunciados
        </span>
      ),
      render: (denunciados, row) => (
        <div className="flex flex-col">
          {Array.isArray(denunciados) && denunciados.length > 0 ? (
            <>
              {denunciados.map((denunciado, index) => {
                // Debug each denunciado when rendering
                
                return (
                  <div key={index} className={`flex items-center ${index > 0 ? 'mt-2' : 'font-medium'}`}>
                    <UserIcon className="mr-1 text-hyc-light/60" />
                    <span className="truncate">{denunciado?.nombre || 
                      (typeof denunciado === 'string' ? denunciado : 'Anónimo')}</span>
                  </div>
                );
              })}
              {row.rango && (
                <div className="text-xs text-hyc-light/70 mt-1">
                  {row.rango}
                </div>
              )}
            </>
          ) : (
            <div className="flex items-center font-medium">
              <UserIcon className="mr-1 text-hyc-light/60" />
              <span className="truncate">No especificado</span>
            </div>
          )}
        </div>
      ),
      sortable: false,
      width: "25%",
    },
    {
      key: 'descripcion',
      header: (
        <span className="flex items-center text-left font-medium text-hyc-light/80">
          Info
        </span>
      ),
      render: (descripcion) => (
        <div className="relative group cursor-help" title="Ver descripción">
          <InfoCircleIcon className="text-hyc-light/70 hover:text-hyc-gold transition-colors" />
          
          {/* Tooltip with description preview */}
          <div className="absolute left-0 -mt-2 w-64 p-3 bg-hyc-dark border border-hyc-light/20 rounded shadow-lg 
                          opacity-0 group-hover:opacity-100 invisible group-hover:visible transition-all duration-200 z-10">
            <div className="text-xs text-hyc-light/90 line-clamp-3">{descripcion || 'Sin descripción'}</div>
          </div>
        </div>
      ),
      sortable: false,
      width: "5%",
    },
    {
      key: 'ubicacion',
      header: (
        <span className="flex items-center text-left font-medium text-hyc-light/80">
          Ubicación
        </span>
      ),
      render: (_, row) => (
        <div className="flex flex-col">
          {row.distrito_ocurrencia ? (
            <>
              <div className="font-medium flex items-center">
                <LocationIcon className="mr-1 text-hyc-light/60" />
                <span className="truncate">{row.distrito_ocurrencia}</span>
              </div>
              <div className="text-xs text-hyc-light/70 mt-1 truncate">
                {row.provincia_ocurrencia}, {row.departamento_ocurrencia}
              </div>
            </>
          ) : (
            <span className="text-hyc-light/50">No especificado</span>
          )}
        </div>
      ),
      sortable: false,
      width: "25%",
    },
    {
      key: 'fecha_ocurrencia',
      header: (
        <span className="flex items-center text-left font-medium text-hyc-light/80">
          Fecha
        </span>
      ),
      render: (fecha) => (
        <div className="flex flex-col">
          {fecha ? (
            <>
              <div className="font-medium flex items-center">
                <CalendarIcon className="mr-1 text-hyc-light/60" />
                <span>{new Date(fecha).toLocaleDateString('es-PE')}</span>
              </div>
              <div className="text-xs text-hyc-light/70 mt-1">
                {new Date(fecha).toLocaleTimeString('es-PE', { hour: '2-digit', minute: '2-digit' })}
              </div>
            </>
          ) : (
            <span className="text-hyc-light/50">No especificada</span>
          )}
        </div>
      ),
      sortable: true,
      width: "15%",
    },
    {
      key: 'actions',
      header: '',
      render: (_, row) => (
        <Button
          variant="secondary"
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/denuncia/${row._id}`);
          }}
        >
          Ver
        </Button>
      ),
      width: "10%",
    },
  ];
  
  return (
    <Layout 
      title="Mis Denuncias" 
      subtitle="Historial de denuncias enviadas por ti"
      fullWidth={false}
    >
      {!isAuthenticated ? (
        <Card className="mb-6 p-6 border-l-4 border-hyc-accent">
          <div className="flex items-start">
            <InfoCircleIcon className="text-hyc-accent mr-3 flex-shrink-0 mt-1" />
            <div>
              <h3 className="text-xl font-medium text-hyc-light mb-2">Acceso Restringido</h3>
              <p className="text-hyc-light/80">Necesitas iniciar sesión para ver tus denuncias.</p>
              <Link to="/login" className="mt-4 inline-block">
                <Button variant="primary" size="sm">Iniciar Sesión</Button>
              </Link>
            </div>
          </div>
        </Card>
      ) : (
        <div className="flex flex-col space-y-6">
          {/* Return to profile button */}
          <div className="flex justify-end">
            <Link to="/perfil">
              <Button variant="outline" size="sm">
                Volver al Perfil
              </Button>
            </Link>
          </div>
          
          {/* Table card */}
          <Card>
            <div className="p-4">
              {/* Table component */}
              {loading ? (
                <div className="text-center py-10">
                  <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-hyc-gold"></div>
                  <p className="text-hyc-light mt-4">Cargando tus denuncias...</p>
                </div>
              ) : error ? (
                <div className="text-center py-10">
                  <p className="text-hyc-accent mb-4">{error}</p>
                  <Button 
                    onClick={handleRefresh}
                    variant="primary"
                  >
                    Reintentar
                  </Button>
                </div>
              ) : denuncias.length === 0 ? (
                <div className="flex flex-col items-center justify-center py-16 bg-hyc-dark/30">
                  <InfoCircleIcon className="text-hyc-light/40 text-5xl mb-4" />
                  <h3 className="text-xl font-mono text-hyc-light mb-2">No has enviado denuncias</h3>
                  <p className="text-hyc-light/60 mb-6 text-center max-w-md">
                    Aún no tienes denuncias registradas en el sistema.
                  </p>
                  <Link to="/denunciaform">
                    <Button variant="primary">
                      Enviar una Denuncia
                    </Button>
                  </Link>
                </div>
              ) : (
                <div className="overflow-hidden">
                  <Table
                    columns={columns}
                    data={denuncias}
                    onRowClick={handleRowClick}
                    striped
                    hoverable
                    defaultSortKey="numero_denuncia"
                    defaultSortOrder="desc"
                    className="table-fixed"
                  />
                </div>
              )}
            </div>
            
            {/* Pagination */}
            {totalPages > 1 && (
              <div className="flex items-center justify-between bg-hyc-secondary px-4 py-3 border-t border-hyc-light/10">
                <div className="flex-1 flex justify-between sm:hidden">
                  <Button
                    variant="outline"
                    onClick={() => setPage(Math.max(1, page - 1))}
                    disabled={page === 1}
                  >
                    Anterior
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => setPage(Math.min(totalPages, page + 1))}
                    disabled={page === totalPages}
                  >
                    Siguiente
                  </Button>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-hyc-light">
                      Mostrando página <span className="font-medium">{page}</span> de{' '}
                      <span className="font-medium">{totalPages}</span>
                    </p>
                  </div>
                  <div>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                      <Button
                        variant="outline"
                        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-hyc-light/20 bg-hyc-dark text-sm font-medium text-hyc-light hover:bg-hyc-light/10"
                        onClick={() => setPage(Math.max(1, page - 1))}
                        disabled={page === 1}
                      >
                        <span className="sr-only">Previous</span>
                        &larr;
                      </Button>
                      
                      {/* Page numbers */}
                      {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                        let pageNum;
                        
                        // Calculate which page numbers to show
                        if (totalPages <= 5) {
                          // Show all pages if 5 or fewer
                          pageNum = i + 1;
                        } else if (page <= 3) {
                          // Near the start
                          pageNum = i + 1;
                        } else if (page >= totalPages - 2) {
                          // Near the end
                          pageNum = totalPages - 4 + i;
                        } else {
                          // In the middle
                          pageNum = page - 2 + i;
                        }
                        
                        return (
                          <Button
                            key={i}
                            variant={pageNum === page ? 'primary' : 'outline'}
                            className="relative inline-flex items-center px-4 py-2 border border-hyc-light/20 bg-hyc-dark text-sm font-medium text-hyc-light hover:bg-hyc-light/10"
                            onClick={() => setPage(pageNum)}
                          >
                            {pageNum}
                          </Button>
                        );
                      })}
                      
                      <Button
                        variant="outline"
                        className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-hyc-light/20 bg-hyc-dark text-sm font-medium text-hyc-light hover:bg-hyc-light/10"
                        onClick={() => setPage(Math.min(totalPages, page + 1))}
                        disabled={page === totalPages}
                      >
                        <span className="sr-only">Next</span>
                        &rarr;
                      </Button>
                    </nav>
                  </div>
                </div>
              </div>
            )}
          </Card>
          
          {/* Create new denuncia call to action */}
          <div className="flex flex-col items-center justify-center py-6 border-t border-hyc-light/10">
            <h3 className="text-hyc-gold text-xl font-bold mb-4">¿Conoces otro caso de corrupción?</h3>
            <Link to="/denunciaform">
              <Button variant="primary" className="px-6 py-2">
                Enviar una nueva Denuncia
              </Button>
            </Link>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default MisDenunciasPage;