import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from './Button';
import Card from './Card';
import FormInput from './FormInput';
import Subtitle from './Subtitle';
import Layout from './Layout';
import { UserIcon, EmailIcon } from './Icons';
import config from '../config';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
    
  // Handle login with OTP
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (!email) {
      setError('Por favor ingresa tu correo electrónico');
      return;
    }

    try {
      // Processing login request
      
      const response = await axios.post(`${config.apiUrl}/auth/otp/signin`, {
        username: email
      });

      // Login response received

      if (response.data.success) {
        // Display message to user
        setMessage(`Código de verificación enviado a tu correo.`);
        
        // Debug code handling removed for security
        
        // Store necessary information for verification
        localStorage.setItem('authUsername', email);
        localStorage.setItem('contactMethod', 'email');
        localStorage.setItem('pendingVerification', 'true');
        
        // Store userId if provided by the backend
        if (response.data.userId) {
          localStorage.setItem('registrationUserId', response.data.userId);
        }
        
        // Redirect to the verification page with context information
        navigate('/verify-login', { 
          state: { 
            contactMethod: 'email',
            contactValue: email,
            isRegistration: false
          } 
        });
      } else {
        setError(response.data.message || 'Error al iniciar sesión');
      }
    } catch (error) {
      console.error('Error:', error);
      
      // Enhanced error handling with more detailed logging
      let errorMessage = 'Error al enviar el código de verificación.';
      
      if (error.response) {
        console.error('Error response status:', error.response.status);
        console.error('Error response data:', error.response.data);
        
        // Handle specific error cases
        if (error.response.status === 401 && 
            error.response.data?.message?.includes('Usuario no encontrado')) {
          setMessage('');
          // Special case: User not found - offer registration
          errorMessage = (
            <div>
              <p className="mb-2">Este correo no está registrado.</p>
              <p className="mb-2">¿Quieres crear una cuenta nueva?</p>
              <button 
                onClick={() => navigate('/registro')} 
                className="text-hyc-gold hover:text-hyc-highlight underline"
              >
                Registrarme ahora
              </button>
            </div>
          );
        } 
        // Other error messages from backend
        else if (error.response.data && error.response.data.message) {
          errorMessage = `${error.response.data.message}`;
        } else {
          errorMessage = `Error ${error.response.status}: ${errorMessage}`;
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received:', error.request);
        errorMessage = 'No se pudo conectar con el servidor. Verifica tu conexión a internet.';
      } else {
        // Something happened in setting up the request
        console.error('Request setup error:', error.message);
        errorMessage = `Error de conexión: ${error.message}`;
      }
      
      setError(errorMessage);
    }
  };

  return (
    <Layout>
      <div className="max-w-md mx-auto">
        <div className="bg-hyc-accent/20 border border-hyc-accent text-hyc-accent font-medium rounded-lg p-4 mb-6 text-center">
          <span className="font-bold">¡ANUNCIO IMPORTANTE!</span><br/>
          La plataforma estará disponible oficialmente a partir del<br/>
          <span className="text-lg font-bold">Domingo, 23 de Marzo de 2025</span>
        </div>
        
        <div className="text-center mb-8">
          <h2 className="text-4xl font-mono text-hyc-light">Iniciar Sesión</h2>
          <p className="text-hyc-light/80 mt-2">Accede a tu cuenta para gestionar tus denuncias</p>
        </div>
        
        <Card className="border border-hyc-light/10 rounded-xl shadow-xl">
          <div className="p-6">
            <div className="flex items-center mb-6 pb-4 border-b border-hyc-light/10">
              <UserIcon className="text-hyc-gold mr-3 w-5 h-5" />
              <span className="text-lg font-mono text-hyc-light">Inicio de Sesión</span>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <FormInput
                name="email"
                label="Correo Electrónico"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              
              <Button 
                type="submit" 
                fullWidth 
                size="lg"
                className="py-4 text-lg font-medium shadow-lg transition-transform transform hover:translate-y-[-2px]"
              >
                Continuar
              </Button>
              
              {message && (
                <div className="mt-4 p-4 bg-green-900/30 border border-green-500 rounded-lg text-green-300 flex items-center">
                  <svg className="w-5 h-5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span>{message}</span>
                </div>
              )}
              
              {error && (
                <div className="mt-4 p-4 bg-red-900/30 border border-red-500 rounded-lg text-red-300 flex items-center">
                  <svg className="w-5 h-5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  <span>{error}</span>
                </div>
              )}
            </form>
            
            <div className="mt-6 text-center">
              <p className="text-hyc-light">
                ¿No tienes una cuenta?{' '}
                <a 
                  href="/registro" 
                  className="text-hyc-gold hover:underline"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/registro');
                  }}
                >
                  Regístrate aquí
                </a>
              </p>
            </div>
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default LoginForm;
