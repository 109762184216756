// API configuration
const determineApiUrl = () => {
  // Production mode always uses relative API path to avoid CORS issues
  if (process.env.NODE_ENV === 'production') {
    console.log('Production mode detected - using relative API path');
    return '/api';
  }
  
  // Check if we have an environment variable set (highest priority)
  if (process.env.REACT_APP_API_URL) {
    return `${process.env.REACT_APP_API_URL}/api`;
  }
  
  // For local development - determine API URL based on port
  if (window.location.hostname === 'localhost') {
    console.log('Localhost detected, port:', window.location.port);
    if (window.location.port === '3030') {
      // Docker setup
      return 'http://localhost:8081/api';
    } else {
      // Development direct setup, default to port 8080 where the server is listening
      return 'http://localhost:8080/api';
    }
  }
  
  // Default fallback - always use relative path for any other environment
  // This avoids any cross-origin issues when accessing the API
  console.log('Unknown environment - defaulting to relative API path');
  return '/api';
};

// Determine environment based on hostname, not just NODE_ENV
const determineEnvironment = () => {
  // Force production mode on the production domain
  if (window.location.hostname === 'honorycoima.org' || 
      window.location.hostname === 'www.honorycoima.org') {
    return 'production';
  }
  
  // For localhost, check if we're in a production build
  if (window.location.hostname === 'localhost' && process.env.NODE_ENV === 'production') {
    return 'production';
  }
  
  // Default to development only for local development
  return window.location.hostname === 'localhost' ? 'development' : 'production';
};

const environment = determineEnvironment();

const config = {
  isProduction: environment === 'production',
  environment: environment,
  // Set URL based on environment and hostname
  apiUrl: determineApiUrl(),
  // Disable WebSocket connections for hot reload when running in Docker
  webSocketEnabled: false,
  // Configuration version
  version: '0.1-d17b4f07-250327'
};

// Force production mode based on hostname one last time
if (window.location.hostname === 'honorycoima.org' || 
    window.location.hostname === 'www.honorycoima.org') {
  config.environment = 'production';
  config.isProduction = true;
}

// No console logs in production

export default config;