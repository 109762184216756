import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Button from './Button';
import Card from './Card';
import FormInput from './FormInput';
import Layout from './Layout';
import { UserIcon, EmailIcon, LockIcon } from './Icons';

import config from '../config';

const RegistrationForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    apodo: '',
    email: '',
    password: '',
    confirmPassword: '',
    departamento: 'Lima',
    provincia: 'Lima',
    distrito: 'Miraflores'
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // For confirmation code step
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [username, setUsername] = useState('');
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    
    // Basic validation
    if (formData.password !== formData.confirmPassword) {
      setError('Las contraseñas no coinciden');
      setLoading(false);
      return;
    }
    
    if (formData.password.length < 8) {
      setError('La contraseña debe tener al menos 8 caracteres');
      setLoading(false);
      return;
    }
    
    if (!formData.email) {
      setError('El correo electrónico es requerido');
      setLoading(false);
      return;
    }
    
    try {
      const response = await fetch(`${config.apiUrl}/auth/otp/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          apodo: formData.apodo,
          email: formData.email,
          password: formData.password,
          departamento: formData.departamento,
          provincia: formData.provincia,
          distrito: formData.distrito
        })
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setSuccess(data.message || 'Registro exitoso. Por favor verifica tu cuenta.');
        setShowConfirmation(true);
        setUsername(formData.email);
      } else {
        setError(data.message || 'Error en el registro');
      }
    } catch (error) {
      console.error('Registration error:', error);
      setError('Error de conexión. Por favor intente nuevamente.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleConfirmation = async (e) => {
    e.preventDefault();
    
    if (!confirmationCode) {
      setError('El código de confirmación es requerido');
      return;
    }
    
    setLoading(true);
    setError('');
    
    try {
      const response = await fetch(`${config.apiUrl}/auth/otp/confirm-signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username,
          code: confirmationCode
        })
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setSuccess('Cuenta verificada exitosamente. Ahora puede iniciar sesión.');
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        setError(data.message || 'Error en la verificación');
      }
    } catch (error) {
      console.error('Confirmation error:', error);
      setError('Error de conexión. Por favor intente nuevamente.');
    } finally {
      setLoading(false);
    }
  };
  
  const resendCode = async () => {
    if (!username) {
      setError('No se puede reenviar el código. Usuario no disponible.');
      return;
    }
    
    setLoading(true);
    setError('');
    
    try {
      const response = await fetch(`${config.apiUrl}/auth/otp/resend-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username
        })
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setSuccess('Código reenviado exitosamente');
      } else {
        setError(data.message || 'Error al reenviar el código');
      }
    } catch (error) {
      console.error('Resend code error:', error);
      setError('Error de conexión. Por favor intente nuevamente.');
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Layout>
      <div className="max-w-md mx-auto">
        <div className="bg-hyc-accent/20 border border-hyc-accent text-hyc-accent font-medium rounded-lg p-4 mb-6 text-center">
          <span className="font-bold">¡ANUNCIO IMPORTANTE!</span><br/>
          La plataforma estará disponible oficialmente a partir del<br/>
          <span className="text-lg font-bold">Domingo, 23 de Marzo de 2025</span>
        </div>
        
        <div className="text-center mb-8">
          <h2 className="text-4xl font-mono text-hyc-light">
            {showConfirmation ? 'Verificar Cuenta' : 'Registro con Contraseña'}
          </h2>
          <p className="text-hyc-light/80 mt-2">
            {showConfirmation 
              ? 'Ingresa el código de verificación que recibiste' 
              : 'Crea una cuenta con tu correo electrónico y contraseña'}
          </p>
        </div>
        
        <Card className="border border-hyc-light/10 rounded-xl shadow-xl">
          <div className="p-6">
            <div className="flex items-center mb-6 pb-4 border-b border-hyc-light/10">
              <UserIcon className="text-hyc-gold mr-3 w-5 h-5" />
              <span className="text-lg font-mono text-hyc-light">
                {showConfirmation ? 'Verificación' : 'Registro'}
              </span>
            </div>
            
            {!showConfirmation ? (
              <form onSubmit={handleSubmit} className="space-y-4">
                <FormInput
                  name="apodo"
                  label="Apodo"
                  value={formData.apodo}
                  onChange={handleInputChange}
                  required
                />
                
                <FormInput
                  name="email"
                  label="Correo Electrónico"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                
                <FormInput
                  name="password"
                  label="Contraseña"
                  type="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
                
                <FormInput
                  name="confirmPassword"
                  label="Confirmar Contraseña"
                  type="password"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  required
                />
                
                <div className="mb-6">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="accept_terms"
                      required
                      className="w-4 h-4 text-hyc-gold bg-hyc-dark border-gray-600 rounded focus:ring-hyc-gold"
                    />
                    <span className="ml-2 text-sm text-hyc-light">
                      He leído y acepto los{' '}
                      <Link 
                        to="/terminos" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-hyc-gold hover:underline"
                      >
                        Términos y Condiciones
                      </Link>
                      , incluyendo el envío de comunicaciones para verificación.
                    </span>
                  </label>
                </div>

                <Button 
                  type="submit" 
                  fullWidth 
                  size="lg"
                  disabled={loading}
                  className="py-4 text-lg font-medium shadow-lg"
                >
                  {loading ? 'Registrando...' : 'Registrarse'}
                </Button>
                
                <div className="text-center mt-4">
                  <p className="text-hyc-light">
                    ¿Ya tienes una cuenta?{' '}
                    <button 
                      type="button"
                      onClick={() => navigate('/login')} 
                      className="text-hyc-gold hover:underline"
                    >
                      Iniciar sesión
                    </button>
                  </p>
                </div>
              </form>
            ) : (
              <form onSubmit={handleConfirmation} className="space-y-4">
                <div className="text-center mb-4">
                  <p className="text-hyc-light">
                    Se ha enviado un código de verificación a:
                  </p>
                  <p className="text-hyc-gold font-medium my-2">{username}</p>
                </div>
                
                <FormInput
                  name="confirmationCode"
                  label="Código de Verificación"
                  value={confirmationCode}
                  onChange={(e) => setConfirmationCode(e.target.value)}
                  required
                />
                
                <Button 
                  type="submit" 
                  fullWidth 
                  size="lg"
                  disabled={loading}
                  className="py-4 text-lg font-medium shadow-lg"
                >
                  {loading ? 'Verificando...' : 'Verificar Cuenta'}
                </Button>
                
                <div className="text-center mt-4">
                  <button
                    type="button"
                    onClick={resendCode}
                    className="text-hyc-gold hover:underline"
                    disabled={loading}
                  >
                    Reenviar código
                  </button>
                </div>
              </form>
            )}
            
            {error && (
              <div className="mt-4 p-4 bg-red-900/30 border border-red-500 rounded-lg text-red-300 flex items-center">
                <svg className="w-5 h-5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <span>{error}</span>
              </div>
            )}
            
            {success && (
              <div className="mt-4 p-4 bg-green-900/30 border border-green-500 rounded-lg text-green-300 flex items-center">
                <svg className="w-5 h-5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
                <span>{success}</span>
              </div>
            )}
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default RegistrationForm;