import React from 'react';
import { Link } from 'react-router-dom';
import './SplashPage.css'; // Import the CSS for styling

const SplashPage = () => {
  return (
    <div className="splash-container">
      <h1>
        <span className="text-honor">Honor</span>
        <span className="text-and">y</span>
        <span className="text-coima">Coima</span>
        <span className="text-org">.org</span>
      </h1>
      <h2>
        Muy pronto vamos a&nbsp;
        <span className="text-underscore">alzar la voz</span>
        &nbsp;y&nbsp;
        <span className="text-underscore">no olvidar</span>
        .
      </h2>
      <div className="content">
        <div className="section">
          <p><span className="logo-text"><span className="text-honor">Honor</span><span className="text-and">y</span><span className="text-coima">Coima</span><span className="text-org">.org</span></span> es un espacio donde todos podremos <u>denunciar</u> de manera anónima los malos actos de la Policía Nacional del Perú (PNP). <br/><br/><b>Sabemos que tú también estás harto de la situación de impunidad en que vivimos</b>.</p>
        </div>
        <div className="section">
          <p>
            <strong className="subtitle">Nuestro objetivo:</strong>
          </p>
          <p>Crear conciencia ciudadana, centralizando y difundiendo denuncias sobre la PNP y exigir una reforma de la institución.</p>
        </div>
        <div className="section">
          <p>
            <strong className="subtitle">¿Qué podrás denunciar?</strong>
          </p>
          <p>Corrupción, abuso de autoridad, mala conducta, extorsión, negligencia, discriminación, violencia, encubrimiento, y más.</p>
        </div>
        <div className="section">
          <p>
            <strong className="subtitle">¿Por qué lo hacemos?</strong>
          </p>
          <p>Los miembros de la PNP son <u>servidores públicos</u> . Las entidades que fiscalizan denuncias no están haciendo su trabajo, ya que pertenecen a la misma institución. Muchas veces, malos policías retornan a trabajar impunemente. 
          <br/><br/><b>Queremos darte una plataforma sencilla y segura para enviar tus denuncias, compartirlas y que <u>nunca sean olvidadas</u>.</b></p>
        </div>

        <div className="section">
          <p>
            <strong className="subtitle">¿Por qué denunciar en <span className="text-honor">Honor</span><span className="text-and">y</span><span className="text-coima">Coima</span><span className="text-org">.org</span>?</strong>
          </p>
          <ul>
            <li>Denuncias anónimas, seguras y públicas para crear presión social</li>
            <li>Acceso fácil y sin filtros a la información confirmada sobre denuncias y denunciados</li>
            <li>Difusión de hechos que deberìan ser de conocimiento público</li>
            <li>Almacenamiento seguro de todos los datos: Toda nuestra plataforma está alojada fuera del país por motivos de seguridad</li>
          </ul>
        </div>
        <div className="section">
          <div className="flex flex-col sm:flex-row justify-center gap-4 mt-8 mb-8">
            <Link to="/explorar" className="bg-hyc-gold hover:bg-hyc-gold/80 text-hyc-dark font-bold py-3 px-6 rounded-lg text-center transition-colors">
              Explorar Denuncias
            </Link>
            <Link to="/denunciaform" className="bg-hyc-accent hover:bg-hyc-accent/80 text-white font-bold py-3 px-6 rounded-lg text-center transition-colors">
              Enviar Denuncia
            </Link>
          </div>
        </div>
        <div className="section">
          <p className="bottom-text">¿Preguntas? Escríbenos a <a href="mailto:hola@honorycoima.org" className="contact-email">hola@honorycoima.org</a></p>
        </div>
      </div>
    </div>
  );
};

export default SplashPage;
