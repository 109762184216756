import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Import components
import Card from '../Card';
import Button from '../Button';
import Tag from '../Tag';
import Layout from '../Layout';
import Lightbox from '../Lightbox';
import SimpleMap from './SimpleMap';
import config from '../../config';

// Import icons
import {
  ArrowLeftIcon,
  CalendarIcon,
  LocationIcon,
  TagIcon,
  UserIcon,
  LinkIcon,
  RefreshIcon,
  InfoCircleIcon,
  FileIcon,
  ImageIcon,
  VideoIcon,
  AudioIcon,
  AttachmentIcon,
  WarningIcon,
  ExpandIcon,
  MapIcon
} from '../Icons';

// Type options with HYC-themed colors (matching ExplorePage)
const tipoOptions = [
  { value: 'Corrupción', label: 'Corrupción', color: 'border-hyc-accent bg-hyc-accent/20 text-hyc-accent' },
  { value: 'Abuso de Autoridad', label: 'Abuso de autoridad', color: 'border-hyc-orange bg-hyc-orange/20 text-hyc-orange' },
  { value: 'Extorsión', label: 'Extorsión', color: 'border-hyc-gold bg-hyc-gold/20 text-hyc-gold' },
  { value: 'Negligencia', label: 'Negligencia', color: 'border-hyc-highlight bg-hyc-highlight/20 text-hyc-highlight' },
  { value: 'Discriminación', label: 'Discriminación', color: 'border-hyc-light bg-hyc-light/20 text-hyc-light' },
  { value: 'Violencia', label: 'Violencia', color: 'border-hyc-accent bg-hyc-accent/20 text-hyc-accent' },
];

const DenunciaDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [denuncia, setDenuncia] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const fetchDenuncia = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${config.apiUrl}/denuncias/${id}`);
      setDenuncia(response.data);
    } catch (err) {
      console.error('Error fetching denuncia:', err);
      setError('Error al cargar la denuncia. Por favor intente nuevamente.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDenuncia();
  }, [id]);
  
  // Debug function to check denuncia data
  useEffect(() => {
    if (denuncia) {
      console.log('DEBUG - Denuncia data:', {
        id: denuncia._id,
        denunciado: denuncia.denunciado,
        denunciados: denuncia.denunciados,
        titulo: denuncia.titulo,
        tipo: denuncia.tipo
      });
    }
  }, [denuncia]);

  // Format date and time separately 
  const formatDate = (dateString, dateOnly = false) => {
    if (!dateString) return 'Sin fecha';
    
    try {
      const date = new Date(dateString);
      // Check if date is valid
      if (isNaN(date.getTime())) return 'Fecha inválida';
      
      // If dateOnly is true, only show the date without time in a compact format
      if (dateOnly) {
        return date.toLocaleDateString('es-PE', {
          year: 'numeric', 
          month: 'numeric', 
          day: 'numeric'
        });
      }
      
      // Otherwise show date and time
      return date.toLocaleDateString('es-PE', {
        year: 'numeric', 
        month: 'long', 
        day: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Error en formato de fecha';
    }
  };
  
  // Format time only
  const formatTime = (dateString) => {
    if (!dateString) return '';
    
    try {
      const date = new Date(dateString);
      // Check if date is valid
      if (isNaN(date.getTime())) return '';
      
      return date.toLocaleTimeString('es-PE', {
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      console.error('Error formatting time:', error);
      return '';
    }
  };

  // Get tag color for denuncia type
  const getTypeColor = (type) => {
    const typeOption = tipoOptions.find(option => option.value === type);
    return typeOption ? typeOption.color : 'border-gray-500 bg-gray-500/20 text-gray-400';
  };

  // Determine file type icon
  const getFileTypeIcon = (file) => {
    if (!file || !file.mimetype) return <FileIcon />;
    
    if (file.mimetype.startsWith('image/')) return <ImageIcon className="text-blue-400" />;
    if (file.mimetype.startsWith('video/')) return <VideoIcon className="text-red-400" />;
    if (file.mimetype.startsWith('audio/')) return <AudioIcon className="text-green-400" />;
    return <FileIcon className="text-gray-400" />;
  };

  // Get file size display
  const getFileSize = (size) => {
    if (!size) return '';
    if (size < 1024) return `${size} B`;
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;
    return `${(size / (1024 * 1024)).toFixed(1)} MB`;
  };

  // Open lightbox for image preview
  const openLightbox = (index) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };

  // Get evidence media files for display and lightbox
  const getEvidenceMedia = () => {
    if (!denuncia) return [];
    
    const media = [];
    
    // Add file attachments
    if (denuncia.archivos && denuncia.archivos.length > 0) {
      denuncia.archivos.forEach(file => {
        if (file.mimetype && file.mimetype.startsWith('image/')) {
          media.push({ 
            type: 'image', 
            url: `${config.apiUrl}/uploads/${file.filename}`,
            thumbnailUrl: `${config.apiUrl}/uploads/${file.filename}`,
            title: file.originalname || 'Imagen adjunta',
            fileType: 'image'
          });
        } else if (file.mimetype && file.mimetype.startsWith('video/')) {
          media.push({ 
            type: 'video', 
            url: `${config.apiUrl}/uploads/${file.filename}`,
            thumbnailUrl: '', // We'll use a placeholder
            title: file.originalname || 'Video adjunto',
            fileType: 'video'
          });
        } else if (file.mimetype && file.mimetype.startsWith('audio/')) {
          media.push({ 
            type: 'audio', 
            url: `${config.apiUrl}/uploads/${file.filename}`,
            thumbnailUrl: '', // We'll use a placeholder
            title: file.originalname || 'Audio adjunto',
            fileType: 'audio'
          });
        } else {
          media.push({ 
            type: 'file', 
            url: `${config.apiUrl}/uploads/${file.filename}`,
            thumbnailUrl: '', // We'll use a placeholder
            title: file.originalname || 'Archivo adjunto',
            fileType: 'file'
          });
        }
      });
    }
    
    // Add URLs that seem to be images or videos
    if (denuncia.url_evidencia && denuncia.url_evidencia.length > 0) {
      denuncia.url_evidencia.forEach((url, index) => {
        if (!url || url.trim() === '') return;
        
        // Image URL detection
        if (url.match(/\.(jpeg|jpg|gif|png)$/i)) {
          media.push({ 
            type: 'image', 
            url,
            thumbnailUrl: url,
            title: `Imagen ${index + 1}`,
            fileType: 'image'
          });
        }
        // Video URL detection including YouTube
        else if (url.match(/\.(mp4|webm|ogg)$/i) || url.includes('youtube.com') || url.includes('youtu.be')) {
          // Convert YouTube URLs to embed format
          let embedUrl = url;
          let thumbnailUrl = '';
          
          // YouTube handling
          if (url.includes('youtube.com/watch?v=')) {
            const videoId = url.split('v=')[1].split('&')[0];
            embedUrl = `https://www.youtube.com/embed/${videoId}`;
            thumbnailUrl = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
          } else if (url.includes('youtu.be/')) {
            const videoId = url.split('youtu.be/')[1];
            embedUrl = `https://www.youtube.com/embed/${videoId}`;
            thumbnailUrl = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
          }
          
          media.push({ 
            type: 'video', 
            url: embedUrl,
            thumbnailUrl,
            title: url.includes('youtube.com') || url.includes('youtu.be') 
              ? 'Video de YouTube' 
              : `Video ${index + 1}`,
            fileType: 'video',
            isYoutube: url.includes('youtube.com') || url.includes('youtu.be')
          });
        } else {
          // Other URLs
          media.push({ 
            type: 'link', 
            url,
            thumbnailUrl: '',
            title: `Enlace ${index + 1}`,
            fileType: 'link'
          });
        }
      });
    }
    
    return media;
  };

  if (loading && !denuncia) {
    return (
      <Layout 
        title="Cargando denuncia..."
        subtitle="Obteniendo información del servidor"
      >
        <div className="flex justify-center items-center py-20">
          <RefreshIcon className="animate-spin text-hyc-gold text-4xl" />
          <span className="ml-4 text-hyc-light">Cargando detalles...</span>
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout 
        title="Error"
        subtitle="No se pudo cargar la denuncia"
      >
        <div className="flex justify-center mb-4">
          <Button
            variant="outline"
            className="mr-4"
            onClick={() => navigate(-1)}
          >
            <ArrowLeftIcon className="mr-2" /> Volver
          </Button>
        </div>
        <Card className="bg-red-900/20 border-l-4 border-red-500 p-6 text-center">
          <WarningIcon className="text-red-400 text-3xl mb-2" />
          <p className="text-hyc-light text-lg">{error}</p>
          <Button 
            variant="primary" 
            className="mt-4"
            onClick={fetchDenuncia}
          >
            <RefreshIcon className="mr-2" /> Intentar nuevamente
          </Button>
        </Card>
      </Layout>
    );
  }

  if (!denuncia) {
    return (
      <Layout 
        title="Denuncia no encontrada"
        subtitle="No se encontró la información solicitada"
      >
        <div className="flex justify-center mb-4">
          <Button
            variant="outline"
            className="mr-4"
            onClick={() => navigate(-1)}
          >
            <ArrowLeftIcon className="mr-2" /> Volver
          </Button>
        </div>
        <Card className="bg-hyc-secondary p-6 text-center">
          <InfoCircleIcon className="text-hyc-light/60 text-3xl mb-2" />
          <p className="text-hyc-light text-lg">La denuncia solicitada no existe o ha sido eliminada.</p>
          <Button 
            variant="primary" 
            className="mt-4"
            onClick={() => navigate('/explorar')}
          >
            Explorar denuncias
          </Button>
        </Card>
      </Layout>
    );
  }

  return (
    <Layout 
      title="Detalles de la Denuncia"
      subtitle="Información detallada sobre la denuncia"
      fullWidth={false}
    >
      {/* Top navigation and heading */}
      <div className="flex items-center justify-between mb-5">
        <div className="flex items-center">
          <Button
            variant="outline"
            size="sm"
            className="mr-3"
            onClick={() => navigate(-1)}
          >
            <ArrowLeftIcon className="mr-1.5" /> Volver
          </Button>
          <div>
            <h2 className="text-xl font-semibold text-hyc-light flex items-center">
              Denuncia #{denuncia.numero_denuncia ? denuncia.numero_denuncia.toString().padStart(5, '0') : '00000'}
              {Array.isArray(denuncia.denunciados) && denuncia.denunciados.length > 1 && (
                <span className="ml-3 text-xs bg-hyc-gold/10 text-hyc-gold px-2 py-0.5 rounded-full">
                  {denuncia.denunciados.length} denunciados
                </span>
              )}
            </h2>
          </div>
        </div>
        <span 
          className={`inline-flex items-center px-2 py-1 rounded-md text-xs font-medium border ${getTypeColor(denuncia.tipo) || 'border-gray-500 bg-gray-500/20 text-gray-400'}`}
        >
          {denuncia.tipo || 'No especificado'}
        </span>
      </div>
      
      {/* Main content in a new layout */}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
        {/* Left column (4 cols) - Denunciado */}
        <div className="md:col-span-4">
          {/* Denunciados with thumbnails */}
          <Card className="p-4 mb-4">
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center">
                <UserIcon className="text-hyc-gold mr-2 text-base" />
                <p className="text-sm text-hyc-light/60 font-medium">
                  {Array.isArray(denuncia.denunciados) && denuncia.denunciados.length > 1 ? 'Denunciados' : 'Denunciado'}
                </p>
              </div>
              {denuncia.rango && (
                <span className="bg-hyc-dark/30 text-xs text-hyc-light/80 px-2 py-1 rounded">
                  {denuncia.rango}
                </span>
              )}
            </div>
            
            {/* Handle denunciados array or denunciado object */}
            {(Array.isArray(denuncia.denunciados) && denuncia.denunciados.length > 0) || denuncia.denunciado ? (
              <>
                <h2 className="text-2xl font-semibold text-hyc-light mb-4">
                  {(Array.isArray(denuncia.denunciados) && denuncia.denunciados[0]?.nombre) || 
                   (typeof denuncia.denunciado === 'object' ? denuncia.denunciado?.nombre : denuncia.denunciado) || 
                   'No especificado'}
                </h2>
                
                <div className="p-5 bg-hyc-dark/30 rounded-lg mt-3">
                  {/* Main Denunciado */}
                  <div className="flex justify-center mb-5">
                    <div className="w-28 h-28 rounded-full overflow-hidden bg-hyc-secondary flex items-center justify-center border border-hyc-light/20">
                      {(denuncia.denunciados?.[0]?.foto_url || denuncia.denunciado?.foto_url) ? (
                        <img 
                          src={denuncia.denunciados?.[0]?.foto_url || denuncia.denunciado?.foto_url} 
                          alt={denuncia.denunciados?.[0]?.nombre || denuncia.denunciado?.nombre || 'No especificado'} 
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <UserIcon className="text-hyc-light/60 text-4xl" />
                      )}
                    </div>
                  </div>

                  <div className="flex-grow">
                    {(denuncia.denunciados?.[0]?.cargo || denuncia.denunciado?.cargo) && (
                      <div className="text-hyc-light mb-4">
                        <span className="text-hyc-light/60 text-sm block mb-1">Cargo:</span>
                        <span className="font-medium text-lg">{denuncia.denunciados?.[0]?.cargo || denuncia.denunciado?.cargo}</span>
                      </div>
                    )}
                    {(denuncia.denunciados?.[0]?.entidad || denuncia.denunciado?.entidad) && (
                      <div className="text-hyc-light mb-2">
                        <span className="text-hyc-light/60 text-sm block mb-1">Entidad:</span>
                        <span className="font-medium text-lg">{denuncia.denunciados?.[0]?.entidad || denuncia.denunciado?.entidad}</span>
                      </div>
                    )}
                  </div>
                  
                  {/* Additional Denunciados */}
                  {denuncia.denunciados.length > 1 && (
                    <div className="mt-5 pt-5 border-t border-hyc-light/10">
                      <div className="flex items-center justify-between mb-3">
                        <h3 className="text-lg font-semibold text-hyc-light">Denunciados adicionales</h3>
                        <span className="text-xs text-hyc-gold bg-hyc-gold/10 px-2 py-1 rounded-full">
                          {denuncia.denunciados.length - 1} denunciado{denuncia.denunciados.length > 2 ? 's' : ''} más
                        </span>
                      </div>
                      <div className="space-y-4">
                        {denuncia.denunciados.slice(1).map((denunciado, index) => (
                          <div key={index} className="flex items-center p-3 bg-hyc-dark/20 rounded-lg hover:bg-hyc-dark/30 transition-colors">
                            <div className="w-10 h-10 rounded-full bg-hyc-secondary flex items-center justify-center border border-hyc-light/20 mr-3">
                              {denunciado.foto_url ? (
                                <img src={denunciado.foto_url} alt={denunciado.nombre} className="w-full h-full object-cover rounded-full" />
                              ) : (
                                <UserIcon className="text-hyc-light/60" />
                              )}
                            </div>
                            <div className="flex-grow">
                              <div className="font-medium text-hyc-light">{denunciado.nombre}</div>
                              {denunciado.cargo && (
                                <div className="text-xs text-hyc-light/70">{denunciado.cargo}</div>
                              )}
                              {denunciado.entidad && (
                                <div className="text-xs text-hyc-light/70">{denunciado.entidad}</div>
                              )}
                            </div>
                            {index === 0 && denuncia.denunciados.length > 3 && (
                              <div className="text-xs text-hyc-gold/80 bg-hyc-dark/30 px-2 py-1 rounded">
                                Ver todos
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <h2 className="text-2xl font-semibold text-hyc-light mb-4">No especificado</h2>
                <div className="p-5 bg-hyc-dark/30 rounded-lg mt-3 flex justify-center">
                  <div className="w-28 h-28 rounded-full overflow-hidden bg-hyc-secondary flex items-center justify-center border border-hyc-light/20">
                    <UserIcon className="text-hyc-light/60 text-4xl" />
                  </div>
                </div>
              </>
            )}
          </Card>
          
          {/* Date */}
          <Card className="p-4 mt-4">
            <div className="flex items-center mb-3">
              <CalendarIcon className="text-hyc-gold mr-2 text-base" />
              <h2 className="text-lg font-semibold text-hyc-light">Fecha del incidente</h2>
            </div>
            
            <div className="bg-hyc-dark/30 p-5 rounded-lg">
              <p className="text-hyc-light font-medium text-xl">
                {denuncia.fecha_ocurrencia ? formatDate(denuncia.fecha_ocurrencia) : 'No especificada'}
              </p>
              {denuncia.fecha_ocurrencia && (
                <p className="text-hyc-light/90 text-base mt-2">
                  {formatTime(denuncia.fecha_ocurrencia)}
                </p>
              )}
              <div className="mt-6 pt-4 border-t border-hyc-light/10 flex items-center justify-between">
                <p className="text-hyc-light/50 text-sm">
                  Denuncia #{denuncia.numero_denuncia ? denuncia.numero_denuncia.toString().padStart(5, '0') : '00000'}
                </p>
                {denuncia.estado && (
                  <span className={`text-sm px-2 py-1 rounded ${
                    denuncia.estado === 'Abierta' ? 'bg-green-900/30 text-green-400' : 
                    denuncia.estado === 'En proceso' ? 'bg-yellow-900/30 text-yellow-400' :
                    denuncia.estado === 'Cerrada' ? 'bg-red-900/30 text-red-400' :
                    'bg-hyc-dark/50 text-hyc-light/70'
                  }`}>
                    {denuncia.estado}
                  </span>
                )}
              </div>
            </div>
          </Card>
        </div>
        
        {/* Middle column (8 cols) - Map and description */}
        <div className="md:col-span-8">
          {/* Map full width */}
          {denuncia.ubicacion && denuncia.ubicacion.lat && denuncia.ubicacion.lng && (
            <Card className="p-3 mb-4">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center">
                  <LocationIcon className="text-hyc-gold mr-2 text-sm" />
                  <h2 className="text-base font-semibold text-hyc-light">Ubicación del incidente</h2>
                </div>
                <span className="text-xs text-hyc-light/80">
                  {denuncia.location_detalle || 
                  (denuncia.distrito_ocurrencia ? 
                    `${denuncia.distrito_ocurrencia}, ${denuncia.provincia_ocurrencia}` : 
                    '')}
                </span>
              </div>
              
              <div className="h-56 rounded-md overflow-hidden">
                <SimpleMap 
                  position={denuncia.ubicacion}
                  denuncias={[denuncia]} 
                  zoomToMarker={true}
                  onMarkerClick={() => {}} 
                />
              </div>
              
              <div className="mt-2 grid grid-cols-3 gap-2 text-sm">
                {denuncia.distrito_ocurrencia && (
                  <div className="bg-hyc-dark/30 p-2 rounded-md">
                    <span className="text-hyc-light/60 text-xs block">Distrito:</span>
                    <span className="text-hyc-light font-medium">{denuncia.distrito_ocurrencia}</span>
                  </div>
                )}
                
                {denuncia.provincia_ocurrencia && (
                  <div className="bg-hyc-dark/30 p-2 rounded-md">
                    <span className="text-hyc-light/60 text-xs block">Provincia:</span>
                    <span className="text-hyc-light font-medium">{denuncia.provincia_ocurrencia}</span>
                  </div>
                )}
                
                {denuncia.departamento_ocurrencia && (
                  <div className="bg-hyc-dark/30 p-2 rounded-md">
                    <span className="text-hyc-light/60 text-xs block">Departamento:</span>
                    <span className="text-hyc-light font-medium">{denuncia.departamento_ocurrencia}</span>
                  </div>
                )}
              </div>
            </Card>
          )}
          
          {/* Description */}
          <Card className="p-3">
            <div className="flex items-center mb-2">
              <InfoCircleIcon className="text-hyc-gold mr-2 text-sm" />
              <h2 className="text-base font-semibold text-hyc-light">Descripción de la denuncia</h2>
            </div>
            
            <div className="bg-hyc-dark/30 p-3 rounded-md h-[calc(100%-40px)] overflow-auto">
              <p className="whitespace-pre-wrap text-sm text-hyc-light leading-relaxed">
                {denuncia.descripcion || 'Sin descripción'}
              </p>
            </div>
          </Card>
        </div>
        
        {/* Media section - full width if present */}
        {(() => {
          const mediaItems = getEvidenceMedia();
          if (mediaItems.length === 0) return null;
          
          return (
            <div className="md:col-span-12 mt-4">
              <Card className="p-3">
                <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center">
                    <AttachmentIcon className="text-hyc-gold mr-2 text-sm" />
                    <h2 className="text-base font-semibold text-hyc-light">Evidencia</h2>
                  </div>
                  <span className="text-xs text-hyc-light/60 bg-hyc-dark/30 px-2 py-0.5 rounded">
                    {mediaItems.length} {mediaItems.length === 1 ? 'item' : 'items'}
                  </span>
                </div>
                
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3 overflow-y-auto" style={{maxHeight: "calc(100vh - 400px)"}}>
                  {/* Grid of thumbnails */}
                  {mediaItems.map((media, index) => (
                    <div 
                      key={index}
                      className="relative cursor-pointer group"
                      onClick={() => openLightbox(index)}
                    >
                      {/* Thumbnail display */}
                      <div className="aspect-square bg-hyc-dark/50 rounded-md overflow-hidden border border-hyc-light/10">
                        {/* Content based on file type */}
                        {media.fileType === 'image' && (
                          <img 
                            src={media.thumbnailUrl}
                            alt={media.title}
                            className="w-full h-full object-cover"
                          />
                        )}
                        
                        {media.fileType === 'video' && media.isYoutube && media.thumbnailUrl && (
                          <div className="relative w-full h-full">
                            <img 
                              src={media.thumbnailUrl}
                              alt={media.title}
                              className="w-full h-full object-cover"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                              <div className="w-8 h-8 bg-red-600 rounded-full flex items-center justify-center">
                                <VideoIcon className="text-white text-xs" />
                              </div>
                            </div>
                          </div>
                        )}
                        
                        {media.fileType === 'video' && !media.isYoutube && (
                          <div className="w-full h-full bg-hyc-dark flex items-center justify-center">
                            <VideoIcon className="text-hyc-light/40 text-xl" />
                          </div>
                        )}
                        
                        {media.fileType === 'audio' && (
                          <div className="w-full h-full bg-hyc-dark flex items-center justify-center">
                            <AudioIcon className="text-hyc-light/40 text-xl" />
                          </div>
                        )}
                        
                        {media.fileType === 'file' && (
                          <div className="w-full h-full bg-hyc-dark flex items-center justify-center">
                            <FileIcon className="text-hyc-light/40 text-xl" />
                          </div>
                        )}
                        
                        {media.fileType === 'link' && (
                          <div className="w-full h-full bg-hyc-dark flex items-center justify-center">
                            <LinkIcon className="text-hyc-light/40 text-xl" />
                          </div>
                        )}
                      </div>
                      
                      {/* Hover overlay */}
                      <div className="absolute inset-0 bg-black/60 opacity-0 group-hover:opacity-100 rounded-md flex items-center justify-center transition-opacity duration-200">
                        <ExpandIcon className="text-white" />
                      </div>
                      
                      {/* File type indicator */}
                      <div className="absolute top-0.5 right-0.5 w-4 h-4 rounded-full bg-black/50 flex items-center justify-center">
                        {media.fileType === 'image' && <ImageIcon className="text-blue-300 text-[8px]" />}
                        {media.fileType === 'video' && <VideoIcon className="text-red-300 text-[8px]" />}
                        {media.fileType === 'audio' && <AudioIcon className="text-green-300 text-[8px]" />}
                        {media.fileType === 'file' && <FileIcon className="text-gray-300 text-[8px]" />}
                        {media.fileType === 'link' && <LinkIcon className="text-purple-300 text-[8px]" />}
                      </div>
                    </div>
                  ))}
                </div>
                
                {/* View all button */}
                <div className="mt-3 text-center">
                  <Button 
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      setLightboxIndex(0);
                      setLightboxOpen(true);
                    }}
                  >
                    <ExpandIcon className="mr-1.5" /> Ver todo en galería
                  </Button>
                </div>
              </Card>
            </div>
          );
            })()}
          </div>
      
      {/* Enhanced Lightbox */}
      {lightboxOpen && (
        <Lightbox
          media={getEvidenceMedia()}
          startIndex={lightboxIndex}
          onClose={() => setLightboxOpen(false)}
        />
      )}
    </Layout>
  );
};

export default DenunciaDetail;