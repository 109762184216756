import React from 'react';

/**
 * Button component with consistent styling
 * @param {Object} props - Component props
 * @param {string} [props.variant='primary'] - Button variant (primary, secondary, outline)
 * @param {string} [props.size='md'] - Button size (sm, md, lg)
 * @param {boolean} [props.fullWidth=false] - Whether the button should take full width
 * @param {React.ReactNode} props.children - Button content
 * @param {Object} props.rest - Other props passed to the button element
 */
const Button = ({ 
  variant = 'primary', 
  size = 'md', 
  fullWidth = false, 
  children, 
  className = '',
  ...rest 
}) => {
  // Base styles for all buttons
  const baseStyles = "font-sans rounded focus:outline-none focus:ring-2 focus:ring-hyc-highlight transition-colors";
  
  // Variant-specific styles
  const variantStyles = {
    primary: "bg-hyc-orange text-white hover:bg-hyc-orange-dark",
    secondary: "bg-hyc-gold text-hyc-dark hover:bg-hyc-light",
    outline: "bg-transparent border border-hyc-light text-hyc-light hover:bg-hyc-secondary",
    danger: "bg-red-600 text-white hover:bg-red-700",
  };
  
  // Size-specific styles
  const sizeStyles = {
    sm: "py-1 px-3 text-sm",
    md: "py-2 px-4 text-base",
    lg: "py-3 px-6 text-lg font-medium",
  };
  
  // Width styles
  const widthStyles = fullWidth ? "w-full" : "";

  // Combine all styles
  const buttonStyles = `${baseStyles} ${variantStyles[variant] || variantStyles.primary} ${sizeStyles[size] || sizeStyles.md} ${widthStyles} ${className}`;

  return (
    <button className={buttonStyles} {...rest}>
      {children}
    </button>
  );
};

export default Button;
