import './index.css'; // Importing global styles first
import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated to use the new createRoot API
import App from './App';
import 'react-datepicker/dist/react-datepicker.css';

// Disable webpack-dev-server hot reload WebSocket
if (process.env.NODE_ENV !== 'production') {
  // Hack to disable WebSocket connection in webpack-dev-server
  // This prevents the console errors when running in Docker
  const originalWebSocket = window.WebSocket;
  window.WebSocket = function(url, protocols) {
    if (url && url.includes('localhost:3000/ws')) {
      console.log('[HYC Debug] WebSocket connection blocked:', url);
      return {
        addEventListener: () => {},
        removeEventListener: () => {},
        send: () => {},
        close: () => {},
        onopen: null,
        onclose: null,
        onmessage: null,
        onerror: null
      };
    }
    return new originalWebSocket(url, protocols);
  };
  
  // Keep the prototype and constants
  window.WebSocket.prototype = originalWebSocket.prototype;
  window.WebSocket.CONNECTING = originalWebSocket.CONNECTING;
  window.WebSocket.OPEN = originalWebSocket.OPEN;
  window.WebSocket.CLOSING = originalWebSocket.CLOSING;
  window.WebSocket.CLOSED = originalWebSocket.CLOSED;
  
  // Also disable the socket client initialization
  window.__WDS_SOCKET_PORT = 0;
  window.__WDS_SOCKET_HOST = null;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
