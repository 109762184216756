import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, useMapEvents, Popup, useMap } from 'react-leaflet';
import Modal from 'react-modal';
import axios from 'axios';

// Import icons
import { LocationIcon, SearchIcon, InfoCircleIcon } from './Icons';

// Ensure the default marker icon images are set correctly
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

Modal.setAppElement('#root'); // Set this to the id of your root element

// Setting default icon options manually
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

// Component to fly to new location when map center changes
const ChangeMapView = ({ center, zoom }) => {
  const map = useMap();
  useEffect(() => {
    if (center) {
      map.flyTo(center, zoom);
    }
  }, [center, zoom, map]);
  return null;
};

const MapModal = ({ 
  isOpen, 
  onRequestClose, 
  onLocationSelect, 
  initialCenter,
  ubigeoData = null // new prop to receive district, province, department
}) => {
  const mapRef = useRef();
  const hasSearchedRef = useRef(false); // Track if we've already done an auto-search
  const [markerPosition, setMarkerPosition] = useState(null); // Start with no marker position
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchError, setSearchError] = useState('');
  const [mapCenter, setMapCenter] = useState(initialCenter);
  const [zoomLevel, setZoomLevel] = useState(13);
  const [locationName, setLocationName] = useState('');
  
  // Reset the search state and marker position when the modal is closed
  useEffect(() => {
    if (!isOpen) {
      // Reset the search state
      hasSearchedRef.current = false;
      
      // Clear marker position so it doesn't appear on next open
      setMarkerPosition(null);
      
      // Clear location name
      setLocationName('');
    }
  }, [isOpen]);
  
  // When the modal opens, automatically search for the district/province/department
  useEffect(() => {
    // Only perform the search if we haven't done it yet for this modal opening
    if (isOpen && ubigeoData && ubigeoData.distrito && !hasSearchedRef.current) {
      // Set the search term based on ubigeo data
      const searchQuery = `${ubigeoData.distrito}, ${ubigeoData.provincia}, ${ubigeoData.departamento}, Peru`;
      setSearchTerm(searchQuery);
      
      // Auto-search with a slight delay to ensure the modal is properly rendered
      setTimeout(() => {
        performSearch(searchQuery);
        hasSearchedRef.current = true; // Mark that we've done the auto-search
      }, 300);
    }
  }, [isOpen, ubigeoData]);

  // Only update center based on initialCenter prop when the modal first opens
  useEffect(() => {
    if (mapRef.current && isOpen && !hasSearchedRef.current) {
      setMapCenter(initialCenter);
      // Don't set marker position automatically - let the user or search do that
    }
  }, [initialCenter, isOpen]);
  
  // Reverse geocode only when a user explicitly clicks - don't do it for default positions
  // This will be called directly by the click handler instead of through the effect

  // Function to perform search without requiring a form event
  const performSearch = async (searchText) => {
    if (!searchText || !searchText.trim()) return;
    
    console.log(`Searching for location: "${searchText}"`);
    
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search`, {
        params: {
          q: searchText.includes('Peru') ? searchText : searchText + ', Peru', // Append Peru if not already included
          format: 'json',
          limit: 5,
          addressdetails: 1
        }
      });
      
      setSearchResults(response.data);
      setSearchError('');
      
      // If results found, focus the map on the first result
      if (response.data && response.data.length > 0) {
        console.log(`Found ${response.data.length} results, focusing on first one`);
        const firstResult = response.data[0];
        const newCenter = {
          lat: parseFloat(firstResult.lat),
          lng: parseFloat(firstResult.lon)
        };
        
        setMapCenter(newCenter);
        setZoomLevel(14); // Zoom in closer when searching
        
        // Never set a marker automatically when searching
        // This ensures the user must explicitly click on the map to place a marker
        
        // Just center the map on the search result, but don't auto-select the location
        // The user must click on the map to place a marker and select the location
        console.log('Map centered on search result - user must click to select location');
      } else {
        console.log('No search results found');
        setSearchError('No se encontraron resultados para la búsqueda.');
      }
    } catch (error) {
      console.error('Error searching location:', error);
      setSearchError('Error al buscar ubicación. Inténtalo de nuevo.');
    }
  };
  
  // Search form handler that wraps performSearch
  const searchLocation = async (e) => {
    e.preventDefault();
    await performSearch(searchTerm);
  };
  
  // Handle selecting a search result
  const selectSearchResult = (result) => {
    const newPosition = {
      lat: parseFloat(result.lat),
      lng: parseFloat(result.lon)
    };
    
    setMapCenter(newPosition);
    setMarkerPosition(newPosition);
    setZoomLevel(16);
    setSearchResults([]);
    
    // Build a structured location name based on address components
    let locationDetail = '';
    
    if (result.address) {
      const { road, house_number, neighbourhood, suburb, city, town, village, district, county, state } = result.address;
      
      // Street address
      if (road) {
        locationDetail = house_number ? `${road} ${house_number}` : road;
      } else if (neighbourhood) {
        locationDetail = neighbourhood;
      } else if (suburb) {
        locationDetail = suburb;
      }
      
      // Set the location name state but don't update the parent component yet - wait for user to click Accept
      setLocationName(locationDetail || result.display_name.split(',')[0]);
    }
  };
  
  // Use reverse geocoding to get location details
  const reverseGeocode = async (position) => {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/reverse`, {
        params: {
          lat: position.lat,
          lon: position.lng,
          format: 'json',
          addressdetails: 1
        }
      });
      
      if (response.data && response.data.address) {
        const { road, house_number, neighbourhood, suburb, district, county, state } = response.data.address;
        
        // Build location detail depending on available data
        let locationDetail = '';
        
        if (road) {
          locationDetail = house_number ? `${road} ${house_number}` : road;
        } else if (neighbourhood) {
          locationDetail = neighbourhood;
        } else if (suburb) {
          locationDetail = suburb;
        }
        
        setLocationName(locationDetail || response.data.display_name.split(',')[0]);
        
        // Only update the local state, don't update the form yet
        // The form will be updated when user clicks Accept
      }
    } catch (error) {
      console.error('Error reverse geocoding:', error);
    }
  };
  
  // Request user's current location
  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const newPosition = { lat: latitude, lng: longitude };
          
          // Center the map on the current location
          setMapCenter(newPosition);
          
          // Also set the marker and reverse geocode to get the address
          setMarkerPosition(newPosition);
          reverseGeocode(newPosition);
          
          // Zoom in more
          setZoomLevel(16);
        },
        (error) => {
          console.error('Error getting current location:', error);
          setSearchError('No se pudo obtener tu ubicación actual.');
        }
      );
    } else {
      setSearchError('La geolocalización no está disponible en tu navegador.');
    }
  };

  const LocationMarker = () => {
    const map = useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        
        // Set the marker position when user clicks
        setMarkerPosition({ lat, lng });
        
        // Immediately reverse geocode the clicked position to get address details
        reverseGeocode({ lat, lng });
        
        // Don't immediately update the form with location info - wait for Aceptar button
        // The parent component will get the final location from the Accept button
      },
    });

    return markerPosition ? (
      <Marker position={markerPosition}>
        <Popup>
          <div>
            <p className="font-medium text-sm">{locationName || 'Ubicación seleccionada'}</p>
          </div>
        </Popup>
      </Marker>
    ) : null;
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onRequestClose={onRequestClose} 
      className="map-modal w-11/12 max-w-4xl mx-auto bg-hyc-dark border border-hyc-light/20 rounded-lg shadow-xl overflow-hidden" 
      overlayClassName="map-modal-overlay fixed inset-0 bg-black/80 flex items-center justify-center z-50"
    >
      <div className="p-4 border-b border-hyc-light/20">
        <h2 className="text-lg font-medium text-hyc-light">Seleccionar ubicación</h2>
      </div>
      
      <div className="p-4">
        {/* Search controls */}
        <div className="mb-4 grid grid-cols-1 sm:grid-cols-7 gap-2">
          <form onSubmit={searchLocation} className="relative col-span-1 sm:col-span-5">
            <input
              type="text"
              placeholder="Buscar ubicación, dirección, distrito..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-2 pl-9 pr-4 border rounded bg-hyc-dark text-hyc-light border-gray-600 focus:border-hyc-highlight focus:outline-none focus:ring-1 focus:ring-hyc-highlight"
            />
            <SearchIcon className="absolute left-3 top-3 text-hyc-light/50" />
          </form>
          
          <button
            type="button"
            onClick={handleGetCurrentLocation}
            className="flex items-center justify-center gap-1 px-3 py-2 bg-hyc-secondary text-hyc-light rounded border border-hyc-light/20 hover:bg-hyc-light/10"
          >
            <LocationIcon />
            <span className="hidden sm:inline">Mi ubicación</span>
          </button>
          
          <button
            type="button"
            onClick={() => {
              // Only when Aceptar is clicked, we update the parent component with location data
              if (markerPosition) {
                // Only include the locationName if we actually have one
                const locationData = {
                  lat: markerPosition.lat,
                  lng: markerPosition.lng
                };
                
                // Only add locationName if we have meaningful text (not just the default placeholder)
                if (locationName && locationName !== 'Ubicación seleccionada') {
                  locationData.locationName = locationName;
                }
                
                onLocationSelect(locationData);
              }
              onRequestClose();
            }}
            className="flex items-center justify-center px-3 py-2 bg-hyc-gold text-hyc-dark font-medium rounded hover:bg-hyc-gold/90"
          >
            Aceptar
          </button>
        </div>
        
        {/* Search results */}
        {searchResults.length > 0 && (
          <div className="mb-4 bg-hyc-secondary border border-hyc-light/20 rounded overflow-hidden">
            <ul className="divide-y divide-hyc-light/10">
              {searchResults.map((result, index) => (
                <li 
                  key={index}
                  className="p-2 hover:bg-hyc-light/10 cursor-pointer"
                  onClick={() => selectSearchResult(result)}
                >
                  <p className="text-hyc-light font-medium">{result.display_name.split(',')[0]}</p>
                  <p className="text-xs text-hyc-light/70 truncate">{result.display_name}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
        
        {/* Search error */}
        {searchError && (
          <div className="mb-4 p-2 bg-red-900/20 border border-red-500/50 rounded flex items-start">
            <InfoCircleIcon className="text-red-400 mr-2 flex-shrink-0 mt-0.5" />
            <p className="text-sm text-hyc-light">{searchError}</p>
          </div>
        )}
        
        {/* Map */}
        <div className="h-[400px] w-full border border-hyc-light/20 rounded overflow-hidden">
          <MapContainer
            center={[mapCenter.lat, mapCenter.lng]}
            zoom={zoomLevel}
            style={{ height: '100%', width: '100%' }}
            whenCreated={(mapInstance) => { mapRef.current = mapInstance }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <LocationMarker />
            <ChangeMapView center={[mapCenter.lat, mapCenter.lng]} zoom={zoomLevel} />
          </MapContainer>
        </div>
        
        {/* Location info */}
        {markerPosition && (
          <div className="mt-3 bg-hyc-secondary p-2 rounded border border-hyc-light/20">
            <p className="text-sm text-hyc-light flex items-center">
              <LocationIcon className="text-hyc-gold mr-1.5" />
              <span>
                {locationName ? locationName : 'Ubicación seleccionada'}
              </span>
            </p>
          </div>
        )}
        
        <div className="mt-3 text-xs text-hyc-light/60">
          Haz clic en el mapa para seleccionar una ubicación exacta o busca una dirección específica.
        </div>
      </div>
    </Modal>
  );
};

export default MapModal;
