import React from 'react';

/**
 * Reusable form input component with consistent styling
 * @param {Object} props - Component props
 * @param {string} props.name - Input name
 * @param {string} props.label - Input label
 * @param {string} [props.type='text'] - Input type
 * @param {string} [props.placeholder=''] - Input placeholder
 * @param {string} [props.value=''] - Input value
 * @param {Function} [props.onChange=() => {}] - Change handler
 * @param {Function} [props.onBlur=() => {}] - Blur handler
 * @param {string} [props.error=''] - Error message
 * @param {boolean} [props.required=false] - Whether the input is required
 * @param {string} [props.className=''] - Additional class names
 * @param {string} [props.icon=''] - Optional icon component
 */
const FormInput = ({
  name,
  label,
  type = 'text',
  placeholder = '',
  value = '',
  onChange = () => {},
  onBlur = () => {},
  error = '',
  required = false,
  className = '',
  icon = null,
  ...rest
}) => {
  // Base styles for all inputs
  const baseInputStyles = 
    "w-full p-2 border rounded bg-hyc-dark text-hyc-light border-gray-500 " +
    "focus:border-hyc-highlight focus:outline-none focus:ring-1 focus:ring-hyc-highlight";
  
  // Apply error styles if there's an error
  const inputStyles = error 
    ? `${baseInputStyles} border-red-500` 
    : baseInputStyles;

  return (
    <div className={`mb-4 ${className}`}>
      {label && (
        <label htmlFor={name} className="block mb-2 text-hyc-light">
          {label}
          {required && <span className="text-hyc-accent ml-1">*</span>}
        </label>
      )}
      
      <div className="relative">
        <input
          id={name}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          className={inputStyles}
          {...rest}
        />
        
        {icon && (
          <span className="absolute right-2 top-2 text-hyc-light">
            {icon}
          </span>
        )}
      </div>
      
      {error && (
        <p className="mt-1 text-hyc-accent text-sm">{error}</p>
      )}
    </div>
  );
};

export default FormInput;