import React, { useState, useRef, useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faUser, faQuestionCircle, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import debounce from 'lodash.debounce';
import './DenunciadoSelect.css'; // Ensure the CSS file is imported
import config from '../config';

// Tooltip component for form fields
const Tooltip = ({ text }) => {
  const [isVisible, setIsVisible] = useState(false);
  
  return (
    <div className="relative inline-block ml-2">
      <FontAwesomeIcon 
        icon={faQuestionCircle} 
        className="text-hyc-light/60 hover:text-hyc-gold cursor-help transition-colors" 
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        onClick={() => setIsVisible(!isVisible)}
      />
      {isVisible && (
        <div className="absolute z-10 p-2 mt-1 text-sm bg-hyc-dark border border-hyc-light/20 rounded shadow-lg left-0 w-64 text-hyc-light">
          {text}
        </div>
      )}
    </div>
  );
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#142723',
    color: '#93c7ab',
    border: '1px solid #425a48',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #93c7ab',
    }
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#142723',
    color: '#93c7ab',
    border: '1px solid #425a48',
    zIndex: 9999,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#93c7ab',
  }),
  input: (provided) => ({
    ...provided,
    color: '#93c7ab',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#93c7ab',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#425a48' : '#142723',
    color: state.isFocused ? '#ffffff' : '#93c7ab',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#214134',
    }
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: '#142723',
    padding: '8px',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    backgroundColor: '#142723',
    color: '#93c7ab',
  }),
  loadingMessage: (provided) => ({
    ...provided,
    backgroundColor: '#142723',
    color: '#93c7ab',
  }),
  // Fix transparent background issues
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: '#142723',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    backgroundColor: '#142723',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    backgroundColor: '#142723',
    color: '#93c7ab',
    '&:hover': {
      color: '#ffffff',
    }
  }),
  clearIndicator: (provided) => ({
    ...provided,
    backgroundColor: '#142723',
    color: '#93c7ab',
    '&:hover': {
      color: '#ffffff',
    }
  }),
  // Ensure the menu options have visible backgrounds
  groupHeading: (provided) => ({
    ...provided,
    backgroundColor: '#142723',
  }),
};

const DenunciadoSelect = ({ formik, newDenunciados, setNewDenunciados, isMulti = true }) => {
  const [inputValue, setInputValue] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchDenunciados = async (inputValue) => {
    if (inputValue.length < 3) return [];
    setLoading(true);
    try {
      // Add authentication token if available
      const headers = {};
      const authToken = localStorage.getItem('authToken');
      const authTokensStr = localStorage.getItem('authTokens');
      
      if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
      } else if (authTokensStr) {
        try {
          const authTokens = JSON.parse(authTokensStr);
          if (authTokens.accessToken) {
            headers.Authorization = `Bearer ${authTokens.accessToken}`;
          } else if (authTokens.idToken) {
            headers.Authorization = `Bearer ${authTokens.idToken}`;
          }
        } catch (e) {
          // Silent failure for production
        }
      }
      
      // Fetch denunciados from the API
      const response = await axios.get(`${config.apiUrl}/denunciados`, {
        params: { search: inputValue },
        headers
      });
      
      // If no results found, add option to create new denunciado
      let options = response.data.map((denunciado) => ({
        value: denunciado._id,
        label: denunciado.nombre,
        rango: denunciado.rango
      }));
      
      // Always add the "create new" option
      if (inputValue.length >= 3) {
        options.push({
          value: 'new',
          label: `Crear nuevo: "${inputValue}"`,
          isNew: true
        });
      }
      
      return options;
    } catch (error) {
      // Even if there's an error, still provide the "create new" option
      return inputValue.length >= 3 ? [{
        value: 'new',
        label: `Crear nuevo: "${inputValue}"`,
        isNew: true
      }] : [];
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchDenunciados = useCallback(debounce(fetchDenunciados, 300), []);

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
    // Don't set newDenunciado on every input change, only when creating a new one
    setShowConfirmation(false); // Reset confirmation message when input changes
    
    // Open menu if input length is sufficient (reduced to 3 characters)
    const minLength = 3;
    setMenuOpen(inputValue.length >= minLength);
    
    if (inputValue.length >= minLength) {
      debouncedFetchDenunciados(inputValue);
    }
  };

  const handleSelectChange = (selectedOptions) => {
    // Handle multi-select vs single-select
    if (isMulti) {
      // For multi-select, selectedOptions will be an array
      if (Array.isArray(selectedOptions)) {
        // Extract any "new" denunciados to add to our newDenunciados state
        const newOptions = selectedOptions.filter(option => option.value === 'new');
        const existingOptions = selectedOptions.filter(option => option.value !== 'new');
        
        // Update formik with all valid denunciado selections
        formik.setFieldValue('denunciados', selectedOptions);
        
        // Process any new denunciados
        if (newOptions.length > 0) {
          // Add each new denunciado to the array
          const updatedNewDenunciados = [...newDenunciados];
          
          newOptions.forEach(option => {
            const cleanedValue = option.originalName || 
                               (option.label && option.label.includes('Crear nuevo:') ? 
                                option.label.match(/Crear nuevo: "([^"]+)"/)[1] : 
                                inputValue.trim());
                                
            if (cleanedValue && !updatedNewDenunciados.some(d => d.nombre === cleanedValue)) {
              updatedNewDenunciados.push({
                nombre: cleanedValue
              });
            }
          });
          
          // Update state with new denunciados
          setNewDenunciados(updatedNewDenunciados);
          
          // Also store the newDenunciados data in formik values
          formik.setFieldValue('newDenunciados', updatedNewDenunciados);
          
          // Show confirmation
          setShowConfirmation(true);
          setTimeout(() => setShowConfirmation(false), 2000);
        }
        
      }
    } else {
      // Single select mode
      if (selectedOptions?.value === 'new') {
        // Clean up input value before using it
        const cleanedValue = inputValue ? inputValue.trim() : '';
        if (cleanedValue) {
          // Create a complete denunciado object with the label containing the name
          const denunciadoObj = { 
            value: 'new', 
            label: `Crear nuevo: "${cleanedValue}"`,
            isNew: true,
            originalName: cleanedValue // Store the original name directly in the object
          };
          
          // Add to denunciados array instead of single denunciado
          const updatedDenunciados = [...(formik.values.denunciados || []), denunciadoObj];
          formik.setFieldValue('denunciados', updatedDenunciados);
          
          // Add to newDenunciados array
          const updatedNewDenunciados = [...newDenunciados, {
            nombre: cleanedValue
          }];
          setNewDenunciados(updatedNewDenunciados);
          
          // Store in formik values
          formik.setFieldValue('newDenunciados', updatedNewDenunciados);
        } else {
          // Show error in the form
          formik.setFieldError('denunciados', 'El nombre del denunciado no puede estar vacío');
        }
      } else {
        // Selected an existing denunciado
        const currentDenunciados = formik.values.denunciados || [];
        const updatedDenunciados = [...currentDenunciados, selectedOptions];
        formik.setFieldValue('denunciados', updatedDenunciados);
        
      }
    }
  };

  const handleCreateOption = () => {
    if (inputValue && inputValue.trim()) {
      // Set the cleaned value
      const cleanedValue = inputValue.trim();
      
      // Create a complete denunciado object with the label containing the name
      const denunciadoObj = { 
        value: 'new', 
        label: `Crear nuevo: "${cleanedValue}"`,
        isNew: true,
        originalName: cleanedValue // Store the original name directly in the object
      };
      
      // Create new denunciado data
      const newDenunciadoData = {
        nombre: cleanedValue
      };
      
      if (isMulti) {
        // Add to existing denunciados array
        const currentDenunciados = formik.values.denunciados || [];
        const updatedDenunciados = [...currentDenunciados, denunciadoObj];
        formik.setFieldValue('denunciados', updatedDenunciados);
        
        // Update new denunciados array
        const updatedNewDenunciados = [...newDenunciados];
        if (!updatedNewDenunciados.some(d => d.nombre === cleanedValue)) {
          updatedNewDenunciados.push(newDenunciadoData);
          setNewDenunciados(updatedNewDenunciados);
          
          // Store in formik
          formik.setFieldValue('newDenunciados', updatedNewDenunciados);
        }
      } else {
        // Single select mode - replace current selection
        formik.setFieldValue('denunciados', [denunciadoObj]);
        
        // Update newDenunciados array
        setNewDenunciados([newDenunciadoData]);
        formik.setFieldValue('newDenunciados', [newDenunciadoData]);
      }
      
      // Show confirmation message
      setShowConfirmation(true); 
      setMenuOpen(false); // Close menu
      setTimeout(() => {
        setShowConfirmation(false);
        // Don't clear input to ensure the name is preserved
      }, 2000); // Hide after 2 seconds
      
      // Clear input after adding
      setInputValue('');
    } else {
      formik.setFieldError('denunciados', 'El nombre del denunciado no puede estar vacío');
      // Show error visually to the user
      formik.setTouched({ denunciados: true });
    }
  };

  const formatCreateLabel = (inputValue) => (
    <div 
      onClick={handleCreateOption} 
      className="flex items-center gap-2 p-1 text-hyc-gold hover:text-hyc-light cursor-pointer transition-colors"
    >
      <FontAwesomeIcon icon={faPlusCircle} /> 
      <span>Agregar Nuevo Denunciado: <span className="font-medium">"{inputValue}"</span></span>
    </div>
  );

  const noOptionsMessage = ({ inputValue }) => {
    const minLength = 3;
    
    if (inputValue && inputValue.length >= minLength) {
      return (
        <div className="text-hyc-light/70 text-center py-1">
          No se encontraron resultados para "{inputValue}". 
          <span className="text-hyc-gold cursor-pointer" onClick={() => handleCreateOption()}>
            Crear nuevo denunciado
          </span>
        </div>
      );
    }
    
    return (
      <div className="text-hyc-light/70 text-center py-1">
        Ingrese al menos {minLength} caracteres para buscar
      </div>
    );
  };

  return (
    <div className="form-group">
      <label className="flex items-center">
        <FontAwesomeIcon icon={faUser} /> 
        <span className="ml-2">Denunciado</span>
        <span className="text-red-500 ml-1">*</span>
        <Tooltip text="Obligatorio. Se puede ingresar un nombre completo o parcial. Si no conoce el nombre completo, ingrese lo que recuerde." />
      </label>
      <AsyncSelect
        cacheOptions
        defaultOptions={true} // Show some default options on first render
        loadOptions={fetchDenunciados}
        onInputChange={handleInputChange}
        onChange={handleSelectChange}
        placeholder={isMulti ? "Buscar o agregar denunciados..." : "Buscar o crear denunciado..."}
        noOptionsMessage={noOptionsMessage}
        isClearable
        isMulti={isMulti}
        value={formik.values.denunciados}
        styles={customStyles}
        menuIsOpen={menuOpen && !showConfirmation}
        isLoading={loading}
        closeMenuOnSelect={!isMulti}
        formatOptionLabel={(option) => (
          <div>
            {option.isNew ? (
              <div className="flex items-center text-hyc-gold">
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                {option.label}
              </div>
            ) : (
              <div>
                <div>{option.label}</div>
                {option.rango && (
                  <div className="text-xs text-hyc-light/70">
                    Rango: {option.rango}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      />
      {showConfirmation && (
        <div className="confirmation-message">
          <FontAwesomeIcon icon={faCheck} className="mr-2" />
          {isMulti 
            ? "Nuevo denunciado agregado a la lista."
            : `Nuevo denunciado "${inputValue}" agregado.`}
        </div>
      )}
      {formik.touched.denunciados && formik.errors.denunciados ? (
        <div className="error">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          {formik.errors.denunciados}
        </div>
      ) : null}
      {/* Display the total count of selected denunciados */}
      {isMulti && formik.values.denunciados && formik.values.denunciados.length > 0 && (
        <div className="text-xs text-hyc-light/70 mt-1">
          {formik.values.denunciados.length} denunciado{formik.values.denunciados.length !== 1 ? 's' : ''} seleccionado{formik.values.denunciados.length !== 1 ? 's' : ''}
        </div>
      )}
    </div>
  );
};

export default DenunciadoSelect;
