// src/App.js or wherever your router is configured
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import SplashPage from './components/SplashPage';
import UserRegistrationForm from './components/UserRegistrationForm';
import LoginForm from './components/LoginForm';
import LoginCallback from './components/LoginCallback';
import VerificationForm from './components/VerificationForm';
import Dashboard from './components/Dashboard';
import ProfilePage from './components/ProfilePage';
import MisDenunciasPage from './components/MisDenunciasPage';
import ProtectedRoute from './components/ProtectedRoute';
import DenunciaForm from './components/DenunciaForm';
import RouteGuard from './components/RouteGuard';
import NotFound from './components/NotFound'; // Import the NotFound component
import RegistrationForm from './components/RegistrationForm';
import ExplorePage from './components/explore/ExplorePage';
import DenunciaDetail from './components/explore/DenunciaDetail';
import CercaDeMiPage from './components/explore/CercaDeMiPage';
import TerminosCondiciones from './components/pages/TerminosCondiciones';
import AuthStatus from './components/AuthStatus';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/splash" element={<SplashPage />} />
          <Route 
            path="/registro" 
            element={
              <RouteGuard>
                <UserRegistrationForm />
              </RouteGuard>
            } 
          />
          <Route 
            path="/registro-confirm" 
            element={
              <RouteGuard>
                <RegistrationForm />
              </RouteGuard>
            } 
          />
          <Route 
            path="/login" 
            element={
              <RouteGuard>
                <LoginForm />
              </RouteGuard>
            } 
          />
          <Route 
            path="/verify" 
            element={
              <RouteGuard>
                <VerificationForm />
              </RouteGuard>
            } 
          />
          <Route 
            path="/verify-login" 
            element={
              <RouteGuard>
                <VerificationForm />
              </RouteGuard>
            } 
          />
          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/perfil" 
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/mis-denuncias" 
            element={
              <ProtectedRoute>
                <MisDenunciasPage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/denunciaform" 
            element={
              <RouteGuard>
                <DenunciaForm />
              </RouteGuard>
            } 
          />
          <Route path="/explorar" element={<ExplorePage />} />
          <Route path="/cercademi" element={
            <ProtectedRoute>
              <CercaDeMiPage />
            </ProtectedRoute>
          } />
          <Route path="/denuncia/:id" element={<DenunciaDetail />} />
          <Route path="/terminos" element={<TerminosCondiciones />} />
          <Route path="/auth/callback" element={<LoginCallback />} />
          <Route path="/login-success" element={<LoginCallback />} />
          <Route path="/login-error" element={<LoginCallback />} />
          <Route path="/auth-status" element={<AuthStatus />} />
          <Route path="*" element={<NotFound/>} /> {/* Catch-all route for 404 errors */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
