import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LocationIcon, QuestionCircleIcon } from './Icons';
import config from '../config';

// Create custom axios instance with better error handling
const api = axios.create();
api.interceptors.request.use(request => {
  console.log('Starting API Request:', request.url);
  return request;
});

// Tooltip component
const Tooltip = ({ text }) => {
  const [isVisible, setIsVisible] = useState(false);
  
  return (
    <div className="relative inline-block ml-2">
      <QuestionCircleIcon 
        className="text-hyc-light/60 hover:text-hyc-gold cursor-help transition-colors" 
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        onClick={() => setIsVisible(!isVisible)}
      />
      {isVisible && (
        <div className="absolute z-10 p-2 mt-1 text-sm bg-hyc-dark border border-hyc-light/20 rounded shadow-lg left-0 w-64 text-hyc-light">
          {text}
        </div>
      )}
    </div>
  );
};

const UbigeoDropdowns = ({ onChange = () => {}, title = "¿De Dónde Nos Visitas?", tooltipText }) => {
    const [departamentos, setDepartamentos] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [distritos, setDistritos] = useState([]);

    const [selectedDepartamento, setSelectedDepartamento] = useState('');
    const [selectedProvincia, setSelectedProvincia] = useState('');
    const [selectedDistrito, setSelectedDistrito] = useState('');
    
    // For names
    const [selectedDepartamentoName, setSelectedDepartamentoName] = useState('');
    const [selectedProvinciaName, setSelectedProvinciaName] = useState('');
    const [selectedDistritoName, setSelectedDistritoName] = useState('');

    useEffect(() => {
        const fetchDepartamentos = async () => {
            try {
                console.log('Using API URL:', config.apiUrl);
                const response = await api.get(`${config.apiUrl}/ubigeo/departamentos`);
                setDepartamentos(response.data);
                console.log('Loaded departamentos:', response.data);
            } catch (error) {
                console.error('Error fetching departamentos:', error);
                // Provide fallback data if API fails
                setDepartamentos([
                    { _id: 'dep1', id_ubigeo: 'dep1', nombre_ubigeo: 'Lima' },
                    { _id: 'dep2', id_ubigeo: 'dep2', nombre_ubigeo: 'Arequipa' },
                    { _id: 'dep3', id_ubigeo: 'dep3', nombre_ubigeo: 'Cusco' }
                ]);
            }
        };
        fetchDepartamentos();
    }, []);

    const handleDepartamentoChange = async (e) => {
        const departamentoId = e.target.value;
        const departamentoName = e.target.options[e.target.selectedIndex].text;
        
        setSelectedDepartamento(departamentoId);
        setSelectedDepartamentoName(departamentoName);
        setSelectedProvincia('');
        setSelectedProvinciaName('');
        setSelectedDistrito('');
        setSelectedDistritoName('');
        setDistritos([]);

        // Make sure we pass a non-empty string
        const nameToUse = departamentoName || 'Sin seleccionar';
        console.log('Selected departamento:', nameToUse);
        onChange(nameToUse, '', '');

        if (departamentoId) {
            try {
                const response = await api.get(`${config.apiUrl}/ubigeo/provincias/${departamentoId}`);
                setProvincias(response.data);
                console.log('Loaded provincias:', response.data);
            } catch (error) {
                console.error('Error fetching provincias:', error);
                // Provide fallback data if API fails
                setProvincias([
                    { _id: 'prov1', id_ubigeo: 'prov1', nombre_ubigeo: 'Lima' },
                    { _id: 'prov2', id_ubigeo: 'prov2', nombre_ubigeo: 'Callao' },
                    { _id: 'prov3', id_ubigeo: 'prov3', nombre_ubigeo: 'Cañete' }
                ]);
            }
        } else {
            setProvincias([]);
        }
    };

    const handleProvinciaChange = async (e) => {
        const provinciaId = e.target.value;
        const provinciaName = e.target.options[e.target.selectedIndex].text;
        
        setSelectedProvincia(provinciaId);
        setSelectedProvinciaName(provinciaName);
        setSelectedDistrito('');
        setSelectedDistritoName('');

        // Make sure we pass a non-empty string for both
        const depNameToUse = selectedDepartamentoName || 'Sin seleccionar';
        const provNameToUse = provinciaName || 'Sin seleccionar';
        
        console.log('Selected provincia:', provNameToUse);
        onChange(depNameToUse, provNameToUse, '');

        if (provinciaId) {
            try {
                const response = await api.get(`${config.apiUrl}/ubigeo/distritos/${provinciaId}`);
                setDistritos(response.data);
                console.log('Loaded distritos:', response.data);
            } catch (error) {
                console.error('Error fetching distritos:', error);
                // Provide fallback data if API fails
                setDistritos([
                    { _id: 'dist1', id_ubigeo: 'dist1', nombre_ubigeo: 'Miraflores' },
                    { _id: 'dist2', id_ubigeo: 'dist2', nombre_ubigeo: 'San Isidro' },
                    { _id: 'dist3', id_ubigeo: 'dist3', nombre_ubigeo: 'Barranco' }
                ]);
            }
        } else {
            setDistritos([]);
        }
    };

    const handleDistritoChange = (e) => {
        const distritoId = e.target.value;
        const distritoName = e.target.options[e.target.selectedIndex].text;
        
        setSelectedDistrito(distritoId);
        setSelectedDistritoName(distritoName);
        
        // Make sure we pass non-empty strings for all fields
        const depNameToUse = selectedDepartamentoName || 'Sin seleccionar';
        const provNameToUse = selectedProvinciaName || 'Sin seleccionar';
        const distNameToUse = distritoName || 'Sin seleccionar';
        
        console.log('Selected distrito:', distNameToUse);
        console.log('Final ubigeo values:', { 
            departamento: depNameToUse, 
            provincia: provNameToUse, 
            distrito: distNameToUse 
        });
        
        onChange(depNameToUse, provNameToUse, distNameToUse);
    };

    // Common select styles
    const selectBaseStyles = `
        w-full p-2 pl-3 pr-10 border rounded bg-hyc-dark text-hyc-light border-gray-500
        focus:border-hyc-highlight focus:outline-none focus:ring-1 focus:ring-hyc-highlight
        disabled:opacity-50 disabled:cursor-not-allowed appearance-none
    `;

    return (
        <div className="space-y-4">
            <div className="flex flex-row gap-3 items-center mb-1">
                <LocationIcon className="text-hyc-gold" />
                <span className="text-lg text-hyc-light">{title}</span>
                {tooltipText && <Tooltip text={tooltipText} />}
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {/* Departamento Dropdown */}
                <div>
                    <label className="block mb-2 text-hyc-light text-sm">
                        Departamento
                    </label>
                    <div className="relative">
                        <select
                            className={selectBaseStyles}
                            value={selectedDepartamento}
                            onChange={handleDepartamentoChange}
                        >
                            <option key="default-dpto" value="">Seleccione</option>
                            {departamentos.map((d, index) => (
                                <option key={d._id || `dep-${index}`} value={d.id_ubigeo}>
                                    {d.nombre_ubigeo}
                                </option>
                            ))}
                        </select>
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <svg className="w-4 h-4 text-hyc-light" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </div>
                    </div>
                </div>

                {/* Provincia Dropdown */}
                <div>
                    <label className="block mb-2 text-hyc-light text-sm">
                        Provincia
                    </label>
                    <div className="relative">
                        <select
                            className={selectBaseStyles}
                            value={selectedProvincia}
                            onChange={handleProvinciaChange}
                            disabled={!selectedDepartamento}
                        >
                            <option key="default-prov" value="">Seleccione</option>
                            {provincias.map((p, index) => (
                                <option key={p._id || `prov-${index}`} value={p.id_ubigeo}>
                                    {p.nombre_ubigeo}
                                </option>
                            ))}
                        </select>
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <svg className="w-4 h-4 text-hyc-light" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </div>
                    </div>
                </div>

                {/* Distrito Dropdown */}
                <div>
                    <label className="block mb-2 text-hyc-light text-sm">
                        Distrito
                    </label>
                    <div className="relative">
                        <select
                            className={selectBaseStyles}
                            value={selectedDistrito}
                            onChange={handleDistritoChange}
                            disabled={!selectedProvincia}
                        >
                            <option key="default-dist" value="">Seleccione</option>
                            {distritos.map((d, index) => (
                                <option key={d._id || `dist-${index}`} value={d.id_ubigeo}>
                                    {d.nombre_ubigeo}
                                </option>
                            ))}
                        </select>
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <svg className="w-4 h-4 text-hyc-light" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UbigeoDropdowns;